define("a24-ember-lib/components/card-heading-and-content-component", ["exports", "a24-ember-lib/templates/components/card-heading-and-content-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardHeadingAndContentComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardHeadingAndContentComponent.default,
        bApplyDefaultPadding: true,
        bHideOverFlow: false,
        sHeading: "",
        sHeaderSizeClass: "",
        sHeaderClass: "",
        sSubHeading: "",
        sSubHeaderSizeClass: "",
        sSubHeaderClass: "",
        bHasIcon: false,
        sRoundButtonSize: "",
        sLetter: "",
        sIcon: "",
        sIconColor: "",
        sImageUrl: "",
        sIconClass: "",
        sIconSizeClass: "",
        bLoading: true,
        // Action items
        arrActionItems: null,
        objSpecialAction: null,
        init: function () {
            this._super(...arguments);

            if (this.get("arrActionItems") === null) {
                this.set("arrActionItems", []);
            }
        }
    });
});