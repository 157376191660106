define("a24-ember-lib/components/card-style-component", ["exports", "a24-ember-lib/templates/components/card-style-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardStyleComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardStyleComponent.default,
        classNames: ["a24-card-style-component"],
        classNameBindings: ["bHideOverFlow:ovf-hidden"],
        bApplyDefaultPadding: true,
        bHideOverFlow: false,
        init: function () {
            this._super(...arguments);
        }
    });
});