define("a24-ember-menu/components/left-menu-with-title-bar-wrapper", ["exports", "a24-ember-menu/templates/components/left-menu-with-title-bar-wrapper", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _leftMenuWithTitleBarWrapper, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Services
        */
        sLeftMenuService: "left-menu-service",
        objLeftMenuService: Ember.computed("sLeftMenuService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sLeftMenuService"));
        }),

        /*
         * Default values
        */
        layout: _leftMenuWithTitleBarWrapper.default,
        classNames: ["left-menu-with-title-bar-wrapper"],

        /*
         * @property sNavbarImageUrl
         * The navbar image url
        */
        sNavbarImageUrl: null,

        /*
         * @property sNavbarRightComponent
         * The component to render on the right of the navbar
        */
        sNavbarRightComponent: null,

        /*
         * @property sLeftMenuLoaderComponent
         * The component to render when the left menu is loading
        */
        sLeftMenuLoaderComponent: "loading-component",

        /*
         * @private @property _bIsLeftMenuOpen
         * True if the left menu is opened
        */
        _bIsLeftMenuOpen: false,

        /*
         * Computed properties
        */
        _bIsDesktop: Ember.computed.or("media.isDesktop", "media.isJumbo"),
        sTopBarComponent: Ember.computed("objLeftMenuService.sTopBarComponent", function () {
            return Ember.get(this, "objLeftMenuService.sTopBarComponent");
        }),
        sTopStyleSafe: Ember.computed("objLeftMenuService.iTopBarComponentHeight", function () {
            return Ember.String.htmlSafe("top: " + Ember.get(this, "objLeftMenuService.iTopBarComponentHeight") + "px");
        }),

        /*
         * Observers
        */
        _onMediaChange: Ember.observer("media.isDesktop", "media.isJumbo", function () {
            Ember.run.debounce(this, this._setDefaultLayout, 100);
        }),

        /*
         * Lifecycle
        */
        didInsertElement() {
            this._super(...arguments);

            Ember.run.schedule("afterRender", () => {
                this._setDefaultLayout();
            });
        },

        /*
         * Actions
        */
        actions: {
            toggleMenu() {
                let [objMain, objSidebar] = [$("#main"), $("#sidebar")];

                if (Ember.get(this, "_bIsLeftMenuOpen")) {

                    this._animate(objMain, "marginLeft", 0, "fast");
                    let iLeftMenuMargin = 240 - parseInt(objSidebar.css("marginLeft"));
                    this._animate(objSidebar, "left", 0 - iLeftMenuMargin, "fast", Ember.get(this, "objLeftMenuService.afterMenuAnimation"));
                    if (Ember.get(this, "_bIsDesktop")) {
                        objSidebar.removeClass("light-box-shadow");
                    }
                } else {
                    if (Ember.get(this, "_bIsDesktop")) {
                        this._animate(objMain, "marginLeft", 240, "fast");
                        objSidebar.addClass("light-box-shadow");
                    }
                    this._animate(objSidebar, "left", 0, "fast", Ember.get(this, "objLeftMenuService.afterMenuAnimation"));
                }

                this.toggleProperty("_bIsLeftMenuOpen");
            },
            onMenuItemClick() {
                if (Ember.get(this, "_bIsLeftMenuOpen") && !Ember.get(this, "_bIsDesktop")) {
                    let [objMain, objSidebar] = [$("#main"), $("#sidebar")];

                    this._animate(objMain, "marginLeft", 0, "fast");
                    let iLeftMenuMargin = 240 - parseInt(objSidebar.css("marginLeft"));
                    this._animate(objSidebar, "left", 0 - iLeftMenuMargin, "fast", Ember.get(this, "objLeftMenuService.afterMenuAnimation"));

                    this.toggleProperty("_bIsLeftMenuOpen");
                }
            }
        },

        /*
         * Private functions
        */
        _animate(objElement, sProp, iValue, sDuration, funcComplete) {
            objElement.animate({ [sProp]: iValue }, {
                duration: sDuration,
                complete: funcComplete
            });
        },

        _setDefaultLayout() {
            let [objMain, objSidebar] = [$("#main"), $("#sidebar")];
            let bIsDesktop = Ember.get(this, "_bIsDesktop");

            if (bIsDesktop) {
                objMain.css({ "marginLeft": 240 });
                objSidebar.css({ "left": 0 });
                objSidebar.addClass("light-box-shadow");
            } else {
                objMain.css({ "marginLeft": 0 });
                objSidebar.css({ "left": -240 });
            }

            Ember.set(this, "_bIsLeftMenuOpen", bIsDesktop);
        }
    });
});