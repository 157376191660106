define("a24-ember-lib/components/card-datagrid-container", ["exports", "a24-ember-lib/templates/components/card-datagrid-container", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridContainer, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridContainer.default,
        sUrlBase: "",
        objUrlConstantPart: null,
        objDatagridConfig: null,
        objQueryParams: null,
        bShowShareButton: false,
        bShareableLink: false,
        bSwaggerServiceCalls: true,
        _arrSelectedItems: null,
        _arrActionItems: null,
        _bDatagridLoading: false,
        _arrTableData: null,
        _objFilterParamObject: null,
        _objTableParamObject: null,
        _objPageParamObject: null,
        _objUrlCall: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _bDidInsertElement: false,
        _sPageParam: "",
        _sSortParam: "",
        bPostTypeDatagrid: false,

        bAllowHiddenColumns: false,
        arrAllowedHeaders: null,

        _funcQueryParamsObserverCallback: function () {
            var objThis = this;
            // We do this purely because we need certain variables to bind back to the parent for testing
            Ember.run.schedule("actions", function () {
                objThis.getQueryParams();
            });
        },
        _funcPageParamChangeCallback: function () {
            this.doServiceCall(false);
        },
        _funcSortParamChangeCallback: function () {
            this.doServiceCall(true);
        },
        init: function () {
            this._super(...arguments);
            var objThis = this;
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            //Since paging is not something we want to control from outside it is highly likely that these values will
            //not be set, so we set them here to default on the objDatagridConfig so that they also go into the gwt history
            if (a24Core.isEmpty(this.get("objDatagridConfig.iPerPage"))) {
                this.set("objDatagridConfig.iPerPage", 25);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.iResultCount"))) {
                this.set("objDatagridConfig.iResultCount", 0);
            }
            if (a24Core.isEmpty(this.get("objDatagridConfig.iPage"))) {
                this.set("objDatagridConfig.iPage", 1);
            }
            if (a24Core.isEmpty(this.get("_arrTableData"))) {
                this.set("_arrTableData", []);
            }

            //Add query params observer if we have any
            if (!a24Core.isEmpty(this.get("objQueryParams"))) {
                this.set("_sPageParam", this.get("objDatagridConfig.iPage").toString());
                this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
                this.addObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));

                for (var i = 0; i < arrHeaders.length; i++) {
                    //Set the sort if the column has it applied
                    if (!a24Core.isEmpty(arrHeaders[i].sAsc)) {
                        this.set("_sSortParam", arrHeaders[i].sProperty + "::" + arrHeaders[i].sAsc);
                        break;
                    }
                }
                this.addObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));

                this.getQueryParams();
            } else {
                $.each(arrHeaders, function (sKey, objItem) {
                    Ember.set(objItem, "bShowHeader", !objItem.bInitialyHidden);
                });
            }

            this.set("arrAllowedHeaders", arrHeaders);

            Ember.run.scheduleOnce("afterRender", this, function () {
                objThis.set("_bDidInsertElement", true);
                //We call the service call here to give the child components the chance to initialize and set the initial
                //query objects.
                objThis.doServiceCall(false);
            });
        },
        getQueryParams: function () {
            //This function will get the query params that are in the url and apply them to the datagrid
            var objQueryParams = this.get("objQueryParams");
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            var arrVisibleColumns = [];
            if (!a24Core.isEmpty(objQueryParams.vis_cols.sValue)) {
                arrVisibleColumns = objQueryParams.vis_cols.sValue.split(",");
            }

            var arrSortDetails = null;
            if (!a24Core.isEmpty(objQueryParams.sortBy)) {
                if (!a24Core.isEmpty(objQueryParams.sortBy.sValue)) {
                    arrSortDetails = objQueryParams.sortBy.sValue.split("::");
                }
            }
            var bFoundSortColumn = false;
            var sSortColumn = null;
            var sSortOrder = null;
            if (!a24Core.isEmpty(arrSortDetails)) {
                sSortColumn = arrSortDetails[0];
                sSortOrder = arrSortDetails[1];
            }

            var objFirstVisCol = null;
            var bFoundVisCol = false;

            for (var i = 0; i < arrHeaders.length; i++) {

                var objHeader = arrHeaders[i];
                var sProperty = objHeader.sProperty;
                var sUniqueColNum = objHeader.iUniqueColNum + "";

                if (a24Core.isEmpty(sUniqueColNum)) {
                    console.error( //eslint-disable-line no-console
                    "Column with property " + sProperty + " does not have a iUniqueColNum defined.\n" + "Each column on the datagrid should have a iUniqueColNum defined when using query params.\n" + "The iUniqueColNum should be different for each column and never unpdated in the future since it " + "will break users saved bookmarks or links created with the iUniqueColNum in it.");
                    return;
                }

                Ember.set(objHeader, "bShowHeader", false);

                if (arrVisibleColumns.includes(sUniqueColNum) && !objHeader.bSilent) {
                    bFoundVisCol = true;
                    Ember.set(objHeader, "bShowHeader", true);
                }

                if (a24Core.isEmpty(objFirstVisCol) && !objHeader.bSilent) {
                    objFirstVisCol = objHeader;
                }

                if ("date" === objHeader.sFilterType) {

                    if (a24Core.isEmpty(objQueryParams[sProperty + "From"].sValue) && a24Core.isEmpty(objQueryParams[sProperty + "To"].sValue) && objQueryParams[sProperty].sValue !== "null") {
                        Ember.set(objHeader, "sFilterFromValue", null);
                        Ember.set(objHeader, "sFilterToValue", null);
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sFilterValue", null);
                    } else {
                        Ember.set(objHeader, "sFilterFromValue", objQueryParams[sProperty + "From"].sValue);
                        Ember.set(objHeader, "sFilterToValue", objQueryParams[sProperty + "To"].sValue);
                        if (objQueryParams[sProperty].sValue === "null") {
                            Ember.set(objHeader, "sFilterValue", "null");
                        } else {
                            Ember.set(objHeader, "sFilterValue", "dateFilterCustomRange");
                        }
                    }
                } else {
                    if (a24Core.isEmpty(objQueryParams[sProperty].sValue)) {
                        Ember.set(objHeader, "sTagValue", null);
                        Ember.set(objHeader, "sFilterValue", null);
                    } else {
                        Ember.set(objHeader, "sFilterValue", objQueryParams[sProperty].sValue);
                    }
                }

                if (sProperty === sSortColumn) {
                    bFoundSortColumn = true;
                    Ember.set(objHeader, "sAsc", sSortOrder);
                } else {
                    Ember.set(objHeader, "sAsc", null);
                }
            }

            //We cant hide all the columns, so when the vis_col param is empty we display the first availible column
            if (a24Core.isEmpty(arrVisibleColumns) || !bFoundVisCol) {
                Ember.set(objFirstVisCol, "bShowHeader", true);
            }

            //Add the special cases that are not in the arrHeaderConfig
            if (!a24Core.isEmpty(objQueryParams.page)) {
                this.set("objDatagridConfig.iPage", parseInt(objQueryParams.page.sValue));
                this.set("_sPageParam", objQueryParams.page.sValue);
            }

            if (!bFoundSortColumn || a24Core.isEmpty(arrSortDetails)) {
                this.set("_objTableParamObject", null);
            } else {
                var objSortQuery = {};
                var objQueryItem = {};
                objQueryItem.mValue = sSortOrder;
                objQueryItem.bSortBy = true;
                objSortQuery[sSortColumn] = objQueryItem;
                this.set("_objTableParamObject", objSortQuery);
            }

            if (!a24Core.isEmpty(objQueryParams.sortBy)) {
                this.set("_sSortParam", objQueryParams.sortBy.sValue);
            }
        },
        setQueryParams: function () {
            //This function will get the new query param values from the datagrid and then set them back onto the url

            //If we do not have any query params setup then do not run this function
            if (a24Core.isEmpty(this.get("objQueryParams"))) {
                return;
            }

            //Remove objQueryParams since we are doing an internal change and do not want the getQueryParams firing
            this.removeObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));

            //Update the objQueryParams so that the values for them in the url can update
            var objNewQueryParams = JSON.parse(JSON.stringify(this.get("objQueryParams")));
            var arrHeaders = this.get("objDatagridConfig.arrHeaderConfig");
            var sSortBy = null;

            var arrVisibleHeaders = [];

            for (var i = 0; i < arrHeaders.length; i++) {

                var objHeader = arrHeaders[i];
                var sProperty = objHeader.sProperty;

                // adds the property to the visible columns array if show header is true and silent is
                if (objHeader.bShowHeader && !objHeader.bSilent) {
                    arrVisibleHeaders.push(objHeader.iUniqueColNum);
                }

                if ("date" === objHeader.sFilterType) {
                    if (objHeader.sFilterValue === "null") {
                        objNewQueryParams[sProperty].sValue = "null";
                    } else {
                        objNewQueryParams[sProperty].sValue = "";
                    }
                    if (a24Core.isEmpty(objHeader.sFilterFromValue)) {
                        objNewQueryParams[sProperty + "From"].sValue = "";
                    } else {
                        objNewQueryParams[sProperty + "From"].sValue = objHeader.sFilterFromValue;
                    }
                    if (a24Core.isEmpty(objHeader.sFilterToValue)) {
                        objNewQueryParams[sProperty + "To"].sValue = "";
                    } else {
                        objNewQueryParams[sProperty + "To"].sValue = objHeader.sFilterToValue;
                    }
                } else {
                    if (a24Core.isEmpty(objHeader.sFilterValue)) {
                        objNewQueryParams[sProperty].sValue = "";
                    } else {
                        objNewQueryParams[sProperty].sValue = objHeader.sFilterValue;
                    }
                }

                //Set the sort if the column has it applied
                if (!a24Core.isEmpty(objHeader.sAsc) && !a24Core.isEmpty(objNewQueryParams.sortBy)) {
                    sSortBy = sProperty + "::" + objHeader.sAsc;
                    objNewQueryParams.sortBy.sValue = sSortBy;
                }
            }

            //This is internal change so remove observer from the _sSortParam while changing it
            this.removeObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));
            if (a24Core.isEmpty(sSortBy)) {
                this.set("_sSortParam", "");
            } else {
                this.set("_sSortParam", sSortBy);
            }
            this.addObserver("_sSortParam", this, this.get("_funcSortParamChangeCallback"));

            // Sets the visiable columns query param
            objNewQueryParams.vis_cols.sValue = arrVisibleHeaders.join(",");

            if (!a24Core.isEmpty(objNewQueryParams.page)) {
                objNewQueryParams.page.sValue = this.get("objDatagridConfig.iPage").toString();
            }

            this.set("objQueryParams", objNewQueryParams);

            this.addObserver("objQueryParams", this, this.get("_funcQueryParamsObserverCallback"));
        },
        doServiceCall: function (bFirstPage) {
            if (!this.get("_bDidInsertElement")) {
                return;
            }
            this.set("_bDatagridLoading", true);

            //Always go back to page 1 unless service call event came from the pager.
            if (bFirstPage) {
                this.set("objDatagridConfig.iPage", 1);
            } else {
                this.updatePageParamFilter();
            }

            var objController = this;

            var objSuccessCallback = function (objResponse, sStatus, jqXHR) {

                objController.set("objDatagridConfig.iResultCount", a24RestCallHelper.getXResultCount(jqXHR));
                objController.set("_arrTableData", a24Core.isEmpty(objResponse) ? [] : objResponse);
                if (objController.get("objDatagridConfig.bEmitDidDoServiceCall")) {
                    objController.sendAction("onDidDoServiceCall", objController.get("_arrTableData"), jqXHR);
                }
                objController.set("_bDatagridLoading", false);
            };

            /**
             * This if makes the datagrid either of GET type or of POST type
             */
            if (this.get("bPostTypeDatagrid")) {

                var objPOSTBody = a24RestCallHelper.convertHooksToPostBody(this.get("_objFilterParamObject"), this.get("_objTableParamObject"), this.get("objDatagridConfig.iPage"), this.get("objDatagridConfig.iPerPage"));

                if (this.get("objDatagridConfig.bEmitWillDoServiceCall")) {
                    // Don't send data on as it will be the incorrect data. This is data before the service call
                    // completed, so will be old!
                    if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                        this.get("onWillDoServiceCall")(false);
                    }
                }

                a24RestCallHelper.doRestServiceCall(objController, "_objUrlCall", a24RestCallHelper.makeJsonAjaxCall(objController, objController.get("_objUserSession"), objController.get("_objNavigation"), "POST", this.get("sUrlBase"), objSuccessCallback, null, objPOSTBody));
            } else {
                var objPageQuery = {
                    page: {
                        mValue: this.get("objDatagridConfig.iPage")
                    },
                    per_page: {
                        mValue: this.get("objDatagridConfig.iPerPage")
                    }
                };

                //Build up the complete url using the url param query objects from the filter, pager and table sort
                if (objController.get("bSwaggerServiceCalls")) {
                    a24RestUrlConstruct.setBaseUrlSwagger(this.get("sUrlBase"));
                } else {
                    a24RestUrlConstruct.setBaseUrl(this.get("sUrlBase"));
                }
                a24RestUrlConstruct.addQueryParamObject(this.get("objUrlConstantPart"));
                a24RestUrlConstruct.addQueryParamObject(this.get("_objFilterParamObject"));
                a24RestUrlConstruct.addQueryParamObject(this.get("_objTableParamObject"));
                a24RestUrlConstruct.addQueryParamObject(objPageQuery);
                var sUrl = a24RestUrlConstruct.getConstructedUrl();

                if (this.get("objDatagridConfig.bEmitWillDoServiceCall")) {
                    // Don't send data on as it will be the incorrect data. This is data before the service call
                    // completed, so will be old!
                    if (!a24Core.isEmpty(this.get("onWillDoServiceCall"))) {
                        this.get("onWillDoServiceCall")(false);
                    }
                }

                a24RestCallHelper.doRestServiceCall(objController, "_objUrlCall", a24RestCallHelper.makeJsonAjaxCall(objController, objController.get("_objUserSession"), objController.get("_objNavigation"), "GET", sUrl, objSuccessCallback, null, null, null, null, !objController.get("bSwaggerServiceCalls")));
            }
        },
        updatePageParamFilter: function () {
            //If we do not have any query params setup then do not run this function
            if (a24Core.isEmpty(this.get("objQueryParams")) || a24Core.isEmpty(this.get("objQueryParams.page"))) {
                return;
            }

            //Remove the _sPageParam observer while we change it internally
            this.removeObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));
            this.set("_sPageParam", this.get("objDatagridConfig.iPage").toString());
            //Update the page query param in the url (the query-param-object-builder observes this and set it separately,
            //this is a special case since it it not part of/in the filter params/arrHeaderConfig)
            this.set("objQueryParams.page.sValue", this.get("objDatagridConfig.iPage").toString());
            // Add _sPageParam query params observer
            Ember.run.next(this, function () {
                this.addObserver("_sPageParam", this, this.get("_funcPageParamChangeCallback"));
            });
        },
        cardDatagridFilterInitEventAction: function (objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);
        },
        cardDatagridTableInitSortAction: function (objTableParamObject) {
            this.set("_objTableParamObject", objTableParamObject);
            if (this.get("objDatagridConfig.bEmitInitSortColumnEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableInitSort"))) {
                    this.get("cardDatagridTableInitSort")(objTableParamObject);
                }
            }
        },
        cardDatagridFilterChangeEventAction: function (sProperty, objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);
            this.doServiceCall(true);
            this.setQueryParams();
        },
        cardDatagridFilterParamChangeEventAction: function (objFilterParamObject) {
            this.set("_objFilterParamObject", objFilterParamObject);
            this.doServiceCall(true);
            this.updatePageParamFilter();
        },
        cardDatagridTableSortAction: function (sColumn, objTableParamObject) {
            this.set("_objTableParamObject", objTableParamObject);
            if (this.get("objDatagridConfig.bEmitSortColumnEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableSort"))) {
                    this.get("cardDatagridTableSort")(sColumn, objTableParamObject);
                }
            }
            this.doServiceCall(true);
            this.setQueryParams();
        },
        cardDatagridPagerInitEventAction: function (objPageQuery) {
            if (this.get("objDatagridConfig.bEmitPagerInitEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridPagerInitEvent"))) {
                    this.get("cardDatagridPagerInitEvent")(objPageQuery);
                }
            }
        },
        cardDatagridPagerEventAction: function () {
            this.doServiceCall(false);
        },
        cardDatagridTableNoneSelectedAction: function () {
            this.set("_arrSelectedItems", null);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableNoneSelected"))) {
                    this.get("cardDatagridTableNoneSelected")([]);
                }
            }
        },
        funcSelectedItemActionFilter: function (arrItems) {
            return function (objAction) {
                return a24Core.isEmpty(objAction.funcFilterFunc) || !a24Core.isEmpty(objAction.funcFilterFunc) && objAction.funcFilterFunc(arrItems);
            };
        },
        cardDatagridTableOneSelectedAction: function (arrItems) {
            this.set("_arrSelectedItems", arrItems);
            var arrActions = this.get("objDatagridConfig.arrSingleItemSelectActions");

            if (!a24Core.isEmpty(arrActions)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrItems));
            }

            this.set("_arrActionItems", arrActions);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableOneSelected"))) {
                    this.get("cardDatagridTableOneSelected")(arrItems);
                }
            }
        },
        cardDatagridTableMultiSelectedAction: function (arrItems) {
            this.set("_arrSelectedItems", arrItems);
            var arrActions = this.get("objDatagridConfig.arrMultiItemSelectActions");

            if (!a24Core.isEmpty(arrActions)) {
                arrActions = arrActions.filter(this.funcSelectedItemActionFilter(arrItems));
            }

            this.set("_arrActionItems", arrActions);
            if (this.get("objDatagridConfig.bEmitSelectRowEvent")) {
                if (!a24Core.isEmpty(this.get("cardDatagridTableMultiSelected"))) {
                    this.get("cardDatagridTableMultiSelected")(arrItems);
                }
            }
        },
        cardDatagridTableCellAction: function (sProperty, objRowData) {
            if (!a24Core.isEmpty(this.get("cardDatagridTableCell"))) {
                this.get("cardDatagridTableCell")(sProperty, objRowData);
            }
        },
        actions: {
            cardDatagridFilterInitEvent: function (objFilterParamObject) {
                this.cardDatagridFilterInitEventAction(objFilterParamObject);
            },
            cardDatagridTableInitSort: function (objTableParamObject) {
                this.cardDatagridTableInitSortAction(objTableParamObject);
            },
            cardDatagridFilterChangeEvent: function (sProperty, objFilterParamObject) {
                this.cardDatagridFilterChangeEventAction(sProperty, objFilterParamObject);
            },
            cardDatagridFilterParamChangeEvent: function (objFilterParamObject) {
                this.cardDatagridFilterParamChangeEventAction(objFilterParamObject);
            },
            cardDatagridTableSort: function (sColumn, objTableParamObject) {
                this.cardDatagridTableSortAction(sColumn, objTableParamObject);
            },
            cardDatagridPagerInitEvent: function (objPageQuery) {
                this.cardDatagridPagerInitEventAction(objPageQuery);
            },
            cardDatagridPagerEvent: function () {
                this.cardDatagridPagerEventAction();
            },
            cardDatagridTableNoneSelected: function () {
                this.cardDatagridTableNoneSelectedAction();
            },
            cardDatagridTableOneSelected: function (arrItems) {
                this.cardDatagridTableOneSelectedAction(arrItems);
            },
            cardDatagridTableMultiSelected: function (arrItems) {
                this.cardDatagridTableMultiSelectedAction(arrItems);
            },
            cardDatagridTableCell: function (sProperty, objRowData) {
                this.cardDatagridTableCellAction(sProperty, objRowData);
            },
            cardDatagridColumnToggleChangeEvent: function () {
                this.setQueryParams();
            }
        }
    });
});