define("a24-ember-policy-library/components/policy-versions", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/policy-versions"], function (exports, _didRenderChangesMixin, _policyVersions) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _policyVersions.default,
        arrPolicyVersionData: null,
        sPolicyRestService: "rest-agency-group-policy",

        _arrPolicyData: null,
        _bLoading: false,
        _arrHeaderActions: null,
        _arrItemActions: null, // This is for when we want the versions to also have actions on
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objPolicyRestService: Ember.computed("sPolicyRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyRestService"));
        }),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        init: function () {
            this._super(...arguments);

            this.set("_arrHeaderActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: () => {
                    this.get("_objNavigationService").navigateWithinContext("policy-add", {
                        "policy-add": [this.get("_objPolicyRestService").getPolicyTypeViewId()]
                    });
                }
            }]);

            this.set("_bLoading", true);
        },
        setPolicyVersionData: Ember.observer("arrPolicyVersionData", function () {
            var arrPolicyVersionData = this.get("arrPolicyVersionData");
            this.setPolicyVersions(arrPolicyVersionData);
            this.set("_bLoading", false);
        }),
        setPolicyVersions: function (arrData) {
            var arrDisplay = [];
            if (!a24Core.isEmpty(arrData)) {
                var funcGetDownloadActionCallback = iVersion => {
                    return () => {
                        this.downloadPDFPreview(iVersion);
                    };
                };
                for (var i = 0; i < arrData.length; i++) {
                    var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                    objDate.setDateFromRest(arrData[i].effective_at, true);
                    var iVersion = arrData[i].version;
                    var sExtraInfo = this.get("_objStringsService").getHTMLTokenString("a24-string-html-colon-value", this.get("_objStringsService").getString("effectiveAt"), objDate.getDateFormat());
                    arrDisplay.push({
                        sName: arrData[i].display_version,
                        bExpanded: arrData[i].active,
                        sExtraInfo: sExtraInfo,
                        arrItems: [{
                            arrItems: [{
                                sLabel: this.get("_objStringsService").getString("status"),
                                sValue: arrData[i].active ? this.get("_objStringsService").getString("active") : this.get("_objStringsService").getString("inactive"),
                                bText: true
                            }, {
                                sLabel: this.get("_objStringsService").getString("effectiveAt"),
                                sValue: objDate.getDateFormat(),
                                bText: true
                            }]
                        }],
                        arrActions: [{
                            sTitle: this.get("_objStringsService").getString("downloadPdfPreview"),
                            sIcon: "get_app",
                            executeCardAction: funcGetDownloadActionCallback(iVersion)
                        }]
                    });
                }
            }
            this.set("_arrPolicyData", arrDisplay);
        },
        downloadPDFPreview: function (iVersion) {
            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdf"));

            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeViewId();
            //sPolicyTypeId read from route params that will be null during transition
            if (a24Core.isEmpty(sPolicyTypeId)) {
                return;
            }

            this.get("_objPolicyRestService").downloadPdfByGET(sPolicyTypeId, iVersion, () => {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFinish"));
            }, () => {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFailed"));
            });
        }
    });
});