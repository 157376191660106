define("a24-ember-lib/components/card-actions-component", ["exports", "a24-ember-lib/templates/components/card-actions-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardActionsComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardActionsComponent.default,
        classNameBindings: ["bMobileView:card-actions-component-mobile"],
        bMobileView: false,
        objSpecialAction: null,
        arrActionItems: null,
        arrVisibleItems: null,
        arrMoreItems: null,
        bShowDropdown: false,
        iVisibleActions: 3,
        _sItemId: null,
        _sCardActionDropdownContentId: null,
        // the tooltip position for the drop down menu
        _sDropDownToolTipPosition: null,
        init: function () {
            this._super(...arguments);

            this.set("_sCardActionDropdownContentId", Ember.guidFor({}));

            if (this.get("arrActionItems") === null) {
                this.set("arrActionItems", []);
            }

            this.setItems();
        },
        setItems: Ember.observer("arrActionItems", function () {

            var iVisibleActions = this.get("iVisibleActions");
            var iCount = 0;
            var arrVisibleItems = [];
            var arrMoreItems = [];
            if (!a24Core.isEmpty(this.get("objSpecialAction"))) {
                iVisibleActions = iVisibleActions - 1;
                // this will be the last item, so set the tooltip position to left
                this.set("objSpecialAction.sToolTipPosition", "left");
            }

            if (!a24Core.isEmpty(this.get("arrActionItems"))) {
                this.get("arrActionItems").forEach(objDataItem => {
                    if (!a24Core.isEmpty(objDataItem.sTitle)) {
                        Ember.set(objDataItem, "sToolTipPosition", "bottom");
                    }
                    if (iCount < iVisibleActions) {
                        arrVisibleItems.push(objDataItem);
                    } else {
                        arrMoreItems.push(objDataItem);
                    }
                    iCount++;
                });
                // set the tooltip position for the drop down
                if (arrMoreItems.length > 0) {
                    if (a24Core.isEmpty(this.get("objSpecialAction"))) {
                        // the drop down is the last item
                        this.set("_sDropDownToolTipPosition", "left");
                    } else {
                        // objSpecialAction is the last item
                        this.set("_sDropDownToolTipPosition", "bottom");
                    }
                }

                // set the tooltip position of the last item
                if (a24Core.isEmpty(this.get("objSpecialAction")) && arrVisibleItems.length > 0) {
                    var iLastIndex = arrVisibleItems.length - 1;
                    var objItem = arrVisibleItems[iLastIndex];
                    if (!a24Core.isEmpty(objItem.sTitle)) {
                        if (arrMoreItems.length > 0) {
                            // will have menu so not the last item
                            Ember.set(arrVisibleItems[iLastIndex], "sToolTipPosition", "bottom");
                        } else {
                            // this is the last item
                            Ember.set(arrVisibleItems[iLastIndex], "sToolTipPosition", "left");
                        }
                    }
                }
            }

            this.set("arrVisibleItems", arrVisibleItems);
            this.set("arrMoreItems", arrMoreItems);
        }),
        actions: {
            executeCardAction: function (objSelect, objEvent) {
                objSelect.executeCardAction();
                // Prevent action from being trigger on other elements
                objEvent.preventDefault();
                objEvent.stopPropagation();
            },
            executeCardDropdownAction: function (objSelect, objEvent) {

                // Prevent action from being trigger on other elements
                objEvent.preventDefault();
                objEvent.stopPropagation();

                objSelect.executeCardAction();
                this.set("bShowDropdown", false);
            }
        }
    });
});