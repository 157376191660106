define("a24-ember-form-inputs/components/input-form-suggest", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-form-inputs/templates/components/input-form-suggest", "a24-ember-lib/mixins/input-form-element"], function (exports, _didRenderChangesMixin, _inputFormSuggest, _inputFormElement) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _inputFormElement.default, {
        layout: _inputFormSuggest.default,
        classNames: ["a24ErrorInputParent", "md-form"],
        sInputType: "suggest",
        sPlaceHolder: "",
        // Need to set this when using this component as this will be the display key for the dropdown
        sOptionsLabelKey: null,
        // This is optional if you want a icon to display next to the entry in the dropdown
        sOptionsLinkKey: null,
        // This is for if you want a permanent item showed and the bottom of the dropdown
        objPermanentItem: null,
        // This is the display key from the above object to be sent in
        sPermanentLabelKey: null,
        /*
        When setting the function for the component you should set to variables for it.
        Variables are as follows
        - sFilter - This is the string to search for
        - funcCallback - Call this function at the end when setting up the function. The param of this funcCallback
          will be the array of results from the filter search
         */
        funcGetSuggestions: null,
        iSuggestAt: 3,
        bAlwaysShowOnFocus: false,
        _arrSuggestions: null,
        _bDropdownShow: false,
        _bPreventBlur: false,
        _bLoading: true,
        _iSelected: null,
        _sPreviousValue: null,
        _objInputFormStringsService: Ember.inject.service("a24-strings-form-inputs"),
        init: function () {
            this._super(...arguments);

            Ember.run.scheduleOnce("afterRender", () => {
                var objTextInput = $("#" + this.get("_sInputId"));
                var iSelected = null;

                this.set("_sPreviousValue", objTextInput.val());

                var objRunLater = null;

                // Binds the below events for when typing into the text box. This will set the suggestions and show dropdown
                objTextInput.bind("click keyup input paste", objEvent => {

                    //We want to ignore some keys that dont have anything to do with input value changing, like arrow keys
                    if (!a24Core.isEmpty(objEvent.which) && (objEvent.which === 13 || //enter
                    objEvent.which === 16 || //shift
                    objEvent.which === 17 || //ctrl
                    objEvent.which === 18 || //alt
                    objEvent.which === 20 || //caps lock
                    objEvent.which === 37 || //left
                    objEvent.which === 38 || //up
                    objEvent.which === 39 || //right
                    objEvent.which === 40 //down
                    )) {
                        return;
                    }

                    // sets the mValue to null when typing
                    if (!a24Core.isEmpty(this.get("mValue"))) {
                        this.setMValueWithInternalFlag(null, false);
                    }

                    if (this.get("_sPreviousValue") !== objTextInput.val() || objTextInput.val().length === 0 && this.get("iSuggestAt") === 0 || this.get("bAlwaysShowOnFocus")) {
                        this.set("_bLoading", true);
                        this.set("_arrSuggestions", []);
                        this.set("_sPreviousValue", objTextInput.val());

                        if (objTextInput.val().length >= this.get("iSuggestAt")) {

                            if (!a24Core.isEmpty(objRunLater)) {
                                Ember.run.cancel(objRunLater);
                            }
                            var funcCallback = arrSuggestions => {
                                this.set("_arrSuggestions", arrSuggestions);

                                this.set("_bLoading", false);
                            };

                            var funcRunLater = () => {
                                this.get("funcGetSuggestions")(objTextInput.val(), funcCallback);
                            };

                            objRunLater = Ember.run.later(funcRunLater, 500);

                            this.set("_bDropdownShow", true);
                            this.set("_iSelected", null);
                        } else {
                            this.set("_bDropdownShow", false);
                        }
                    }
                });
                // This is key up events for the arrow keys and the enter when selecting a input
                objTextInput.on("keyup", objEvent => {

                    if (objEvent.which === 38) {
                        // Up Arrow
                        objEvent.stopPropagation();
                        objEvent.preventDefault();

                        iSelected = this.get("_iSelected");
                        if (iSelected === null) {
                            iSelected = 0;
                        } else {
                            if (iSelected !== 0) {
                                iSelected = iSelected - 1;
                            }
                        }
                        this.get("_arrSuggestions").setEach("_bSuggestionSelected", false);
                        this.set("_arrSuggestions." + iSelected + "._bSuggestionSelected", true);

                        this.set("_iSelected", iSelected);
                    } else if (objEvent.which === 40) {
                        // Down Arrow
                        objEvent.stopPropagation();
                        objEvent.preventDefault();

                        iSelected = this.get("_iSelected");
                        if (iSelected === null) {
                            iSelected = 0;
                        } else {
                            if (iSelected !== this.$(".input-form-suggest-content-list li").length - 1) {
                                iSelected = iSelected + 1;
                            }
                        }
                        this.get("_arrSuggestions").setEach("_bSuggestionSelected", false);
                        this.set("_arrSuggestions." + iSelected + "._bSuggestionSelected", true);

                        this.set("_iSelected", iSelected);
                    } else if (objEvent.which === 13) {
                        // Enter
                        var iSelect = this.get("_iSelected");
                        if (iSelect === null && !a24Core.isEmpty(this.get("_arrSuggestions"))) {
                            this.setSelectedItem(this.get("_arrSuggestions.0"));
                        } else if (iSelect !== null && !a24Core.isEmpty(this.get("_arrSuggestions"))) {
                            this.setSelectedItem(this.get("_arrSuggestions." + iSelect));
                        }

                        this.set("_bDropdownShow", false);
                    } else if (objEvent.which === 27) {
                        // esc
                        objEvent.stopPropagation();
                        this.set("_bDropdownShow", false);
                    }
                });

                this.$("#" + this.get("_sInputId")).on("keyup", objEvent => {
                    if (objEvent.which === 27) {
                        // esc
                        objEvent.stopPropagation();
                        this.set("_bDropdownShow", false);
                    }
                });

                if (!a24Core.isEmpty(this.get("mValue"))) {
                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(this.get("mValue")[this.get("sOptionsLabelKey")]);
                    objTextInput.change();

                    this.resetFloatingLabel(objTextInput);
                }

                objTextInput.on("blur", () => {
                    // This is to prevent the blur to happen when dropdown is clicked when clicking the link
                    if (this.get("_bPreventBlur")) {
                        this.set("_bPreventBlur", false);
                    } else {
                        this.set("_bDropdownShow", false);
                        this.resetFloatingLabel(objTextInput);
                    }
                });
            });
        },
        clear: function () {
            this.setValueFromOutside(null);
        },
        setValueFromOutside: function (mValue) {
            this.setMValueWithInternalFlag(mValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            if (a24Core.isEmpty(mValue)) {
                objTextInput.val("");
            } else {
                objTextInput.val(mValue[this.get("sOptionsLabelKey")]);
            }
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        resetFloatingLabel: function (objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        setSelectedItem: function (objItem) {
            if (!a24Core.isEmpty(objItem) && !a24Core.isEmpty(objItem._bSuggestionSelected)) {
                delete objItem._bSuggestionSelected;
            }

            this.setMValueWithInternalFlag(objItem, false);

            var objTextInput = $("#" + this.get("_sInputId"));
            // Set input value after click
            objTextInput.val(objItem[this.get("sOptionsLabelKey")]);
            objTextInput.change();
            objTextInput.blur();

            this.set("_iSelected", null);
        },
        // might need to move this to emberlib some time
        _funcOpenWindow: function (sLink) {
            window.open(sLink);
        },
        actions: {
            closeSuggestDropdown: function () {
                this.set("_bDropdownShow", false);
                this.set("_iSelected", null);
            },
            setSelectedItemAction: function (objItem) {
                this.setSelectedItem(objItem);
            },
            onPermanentItemAction: function (objItem) {
                this.get("onPermanentItemAction")(objItem);
            },
            onLinkAction: function (objItem, objEvent) {
                this.set("_bPreventBlur", true);
                objEvent.stopPropagation();
                this._funcOpenWindow(objItem[this.get("sOptionsLinkKey")]);
            }
        }
    });
});