// Extended enum base object. All values on here will be applied to a24EnumBase and ultimately applied onto each
// a24Enums root property
var a24EnumBaseExtend = {
    /**
     * Will retrieve the first backend value associated with the specified frontend value
     *
     * @author Jacques Slabber <jacques.slabber@a24group.com>
     * @since  08 December 2016
     *
     * @param sValue - The frontend display value.
     *
     * @returns The backend value associated with the frontend value
     */
    getBackendValue: function(sValue) {

        for (var sKey in this) {
            if (this.hasOwnProperty(sKey)) {
                if (
                    !a24Core.isEmpty(this[sKey]) &&
                    !a24Core.isEmpty(sKey) &&
                    this[sKey] === sValue
                ) {
                    return sKey;
                }
            }
        }
        return null;
    },
    /**
     * Will retrieve the frontend value that is set on the specified backend value.
     *
     * @author Jacques Slabber <jacques.slabber@a24group.com>
     * @since  08 December 2016
     *
     * @param sValue - The backend value.
     *
     * @returns The frontend value associated with the backend value
     */
    getFrontendValue: function(sValue) {
        if (!a24Core.isEmpty(this[sValue]) && typeof this[sValue] !== "function") {
            return this[sValue];
        }
        return null;
    },
    /**
     * Will generate a list of backend/fronted values
     *
     * @author Jacques Slabber <jacques.slabber@a24group.com>
     * @since  08 December 2016
     *
     * @returns Array - List of backend and frontend values
     * eg: [
     *   {
     *     sValue: "backendValue",
     *     sLabel: "frontendValue"
     *   }
     * ]
     */
    getList: function() {
        var arrResult = [];
        for (var sKey in this) {

            if (
                this.hasOwnProperty(sKey) &&
                !a24Core.isEmpty(sKey) &&
                !a24Core.isEmpty(this[sKey]) &&
                typeof this[sKey] !== "function"
            ) {
                var objItem = {};
                objItem["sValue"] = sKey;
                objItem["sLabel"] = this[sKey];
                arrResult.push(objItem);
            }
        }
        return arrResult;
    }
};


// Extended enum object. All values on here will be applied to a24Enums
var a24EnumsExtend = {
    yesNo: {
        Yes: "Yes",
        No: "No"
    },
    gender: {
        "male": "Male",
        "female": "Female"
    }
};

// If a24EnumBase has not been defined we create it
if (typeof a24EnumBase === "undefined") {
    var a24EnumBase = {};
}
// If a24Enums has not been defined we create it
if (typeof a24Enums === "undefined") {
    var a24Enums = {};
}

// Only add the extended values to a24EnumBase if they do not already exist
Object.keys(a24EnumBaseExtend).forEach(function(sKey) {
    if (!a24EnumBase.hasOwnProperty(sKey)) {
        a24EnumBase[sKey] = a24EnumBaseExtend[sKey];
    }
});

// Only add the extended values to a24Enums if they do not already exist. This also applies for all sub-properties.
Object.keys(a24EnumsExtend).forEach(function(sKey) {
    if (!a24Enums.hasOwnProperty(sKey)) {
        a24Enums[sKey] = a24EnumsExtend[sKey];
    } else {
        Object.keys(a24EnumsExtend[sKey]).forEach(function(sSubKey) {
            if (!a24Enums[sKey].hasOwnProperty(sSubKey)) {
                a24Enums[sKey][sSubKey] = a24EnumsExtend[sKey][sSubKey];
            }
        });
    }
});

// We add all values of a24EnumBase to all root properties of a24Enums if they do not already exist
Object.keys(a24Enums).forEach(function(sKey) {
    if (a24Enums.hasOwnProperty(sKey)) {
        Object.keys(a24EnumBase).forEach(function(sSubKey) {
            if (!a24Enums[sKey].hasOwnProperty(sSubKey)) {
                a24Enums[sKey][sSubKey] = a24EnumBase[sSubKey];
            }
        });
    }
});
