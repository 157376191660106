define("a24-ember-staffshift-core/services/rest-login", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sLoginUrl: "/rest/login",
        _sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objLoginServiceProperty: null,
        /**
         * This service method will be used to attempt a login with the username and password as well
         * as the remember me flag. It will call either the success for failure after the service
         * call is made
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sUsername - The username the user is trying to log in with
         * @param sPassword - The password the user is trying to log in with
         * @param bRememberMe - Whether the user selected the remember me option
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The callback function to execute when the service call is not successful
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  5 May 2016
         */
        post: function (objCaller, sUsername, sPassword, bRememberMe, funcSuccess, objFailure) {

            var sData = "sPassword=" + sPassword;
            sData += "&sUsername=" + sUsername;
            sData += "&bRememberMe=" + bRememberMe;

            return a24RestCallHelper.doRestServiceCall(objCaller, "_objLoginServiceProperty", a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sTriageBaseRest") + "/rest/login", funcSuccess, objFailure, sData, "application/x-www-form-urlencoded; charset=UTF-8", false, false, true));
        }
    });
});