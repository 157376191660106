define("a24-ember-lib/mixins/template-left-column-resize", ["exports", "a24-ember-window/mixins/a24responsive"], function (exports, _a24responsive) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create(_a24responsive.default, {
        iRightColumnWidth: null,
        bRightColumnVisible: false,
        _bColumnVisible: false,
        _bShowRightColumn: false,
        _iMaxTemplateWidth: null,
        _bSmallDevice: false,
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        didInsertElement: function () {

            Ember.run.scheduleOnce("afterRender", () => {
                this.set("_iMaxTemplateWidth", this.get("_objWindowConstants.SCREEN_SIZE_TABLET_PX"));

                if (a24Core.isEmpty(this.get("iRightColumnWidth"))) {
                    this.set("iRightColumnWidth", this.get("_objWindowConstants.SCREEN_SIZE_TABLET_PX") - this.get("_objWindowConstants.SCREEN_SIZE_MOBILE_PX"));
                }

                this.setLeftColumn();
            });
        },
        setLeftColumn: Ember.observer("_bColumnVisible", "_bSmallDevice", "bRightColumnVisible", function () {
            if (!this.get("_bSmallDevice") && this.get("bRightColumnVisible")) {
                this.set("_bShowRightColumn", true);
            } else {
                this.set("_bShowRightColumn", false);
            }
            Ember.run.next(() => {
                Ember.run.scheduleOnce("afterRender", this, () => {

                    if (this.get("_bShowRightColumn")) {
                        this.$(".left-Column").css("margin-right", this.get("iRightColumnWidth") - this.get("_objWindowConstants.SCREEN_REM_BASE") * 0.75 + 2 + "px");
                        this.$(".a24-right-column-style").css("width", this.get("iRightColumnWidth") + "px");
                    } else {
                        this.$(".left-Column").css("margin-right", "0px");
                    }
                });
            });
        }),
        setSmallDevices: Ember.observer("sScreenSize", function () {
            if (this.get("sScreenSize") === this.get("_objWindowConstants.MEDIA_SIZE_TINY") || this.get("sScreenSize") === this.get("_objWindowConstants.MEDIA_SIZE_MOBILE")) {
                this.setRightHandSmallDevice();
            } else {
                this.set("_bColumnVisible", true);
                this.set("_bSmallDevice", false);
            }
        }),
        sRightColumnStyle: Ember.computed("sRightStyleWidth", function () {
            return "a24-right-column-style";
        }),
        setRightHandSmallDevice: function () {
            // We are disabling the right side on mobile until it is decided on what is going to happen with the right column on mobile
            this.set("_bSmallDevice", true);
            this.set("_bColumnVisible", false);
        }
    });
});