define("a24-ember-form-inputs/services/input-form-file-service", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * Get file for an input
         *
         * @param objFileInput - The input element
         * @param bReturnName - True if only the file name should be returned
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  15 February 2018
         */
        getFileObject(objFileInput, bReturnName) {
            if (a24Core.isEmpty(objFileInput.get(0).files) || a24Core.isEmpty(objFileInput.get(0).files[0])) {
                return "";
            } else {
                if (bReturnName) {
                    return objFileInput.get(0).files[0].name;
                } else {
                    return objFileInput.get(0).files[0];
                }
            }
        },

        /**
         * Get file input id
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  15 February 2018
         */
        getFileInputId() {
            return Ember.guidFor({});
        }
    });
});