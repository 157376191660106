define("a24-ember-lib/components/fab-button", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-window/mixins/a24responsive", "a24-ember-lib/templates/components/fab-button"], function (exports, _didRenderChangesMixin, _a24responsive, _fabButton) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _a24responsive.default, {
        layout: _fabButton.default,
        classNames: ["a24-fab-button-component"],
        _objFabCurrent: null,
        _sFabState: "hideEnd", //showStart, showEnd, hideStart, hideEnd
        _iAnimInSpeed: 250,
        _objFabService: Ember.inject.service("fab-button"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _bIsClickable: false, //This will be false during animations
        _bClearFab: false,
        init: function () {
            this._super(...arguments);
            this.get("_objFabService");
            this.get("_objSnackbarService");

            this.set("_objFabCurrent", {
                funcFabAction: null,
                sIconName: "add", //This needs a default otherwise the jQuery `.material-icons` select on afterRender wont work
                sTooltip: "",
                sButtonColor: "",
                sIconColor: "white-text",
                bLightWaves: true
            });
        },
        didInsertElement: function () {
            this._super(...arguments);

            Ember.run.scheduleOnce("afterRender", this, () => {
                //Set the initial values of the animating parts
                this.$(".a24-fab-button-container").css({
                    marginBottom: "0px",
                    width: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") + "px",
                    height: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") + "px",
                    padding: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") / 2 + "px"
                });

                this.$(".a24-fab-button-main .material-icons").css({
                    fontSize: "0px",
                    lineHeight: "0px"
                });

                this.showSnackbarMessage();
            });
        },
        showSnackbarMessage: Ember.observer("_objFabService._bHasNewAction", function () {
            if (this.get("_objFabService._bHasNewAction")) {
                this.set("_objFabService._bHasNewAction", false);

                if (this.get("_objFabService._arrFabActions").length === 0) {
                    //In this case we want to hide the FAB button

                    if (this.get("_sFabState") === "showStart") {
                        this.set("_bClearFab", true);
                    } else if (this.get("_sFabState") === "showEnd") {
                        this.hideFabButton();
                    }
                    //else if (this.get("_sFabState") === "hideStart") {
                    //    //Already busy hiding so nothing to do here
                    //} else if (this.get("_sFabState") === "hideEnd") {
                    //    //Already hidden so nothing to do here
                    //}
                } else {
                    //In this case we want to display the next queued FAB action

                    //if (this.get("_sFabState") === "showStart") {
                    //    //The showFabButtonEnd will cover this scenario
                    //} else
                    if (this.get("_sFabState") === "showEnd") {
                        this.hideFabButton();
                        //} else if (this.get("_sFabState") === "hideStart") {
                        //    //The hideFabButtonEnd will cover this scenario
                    } else if (this.get("_sFabState") === "hideEnd") {
                        this.setFabButtonDetails();
                    }
                }
            }
        }),
        observeSnackbarHeight: Ember.observer("_objSnackbarService.sSnackbarState", function () {
            var sSize = this.get("sScreenSize");
            if (sSize === this.get("_objWindowConstants.MEDIA_SIZE_TABLET") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_DESKTOP") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_JUMBO")) {
                this.$(".a24-fab-button-container").stop("fabSnackbarSyncAnim");
                this.$(".a24-fab-button-container").css({
                    marginBottom: "0px"
                });
            } else {
                if (this.get("_objSnackbarService.sSnackbarState") === "showStart") {
                    this.animateFabSnackbarSync(this.get("_objSnackbarService.iSnackbarHeight"));
                } else if (this.get("_objSnackbarService.sSnackbarState") === "hideStart") {
                    this.animateFabSnackbarSync(0);
                }
            }
        }),
        observeScreenSize: Ember.observer("sScreenSize", "_objSnackbarService.iSnackbarHeight", function () {
            if (a24Core.isEmpty(this.$(".a24-fab-button-container"))) {
                return;
            }

            var sSize = this.get("sScreenSize");
            if (sSize === this.get("_objWindowConstants.MEDIA_SIZE_TABLET") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_DESKTOP") || sSize === this.get("_objWindowConstants.MEDIA_SIZE_JUMBO")) {
                this.$(".a24-fab-button-container").stop("fabSnackbarSyncAnim");
                this.$(".a24-fab-button-container").css({
                    marginBottom: "0px"
                });
            } else {
                if (this.get("_objSnackbarService.sSnackbarState") === "showStart") {
                    this.animateFabSnackbarSync(this.get("_objSnackbarService.iSnackbarHeight"));
                } else if (this.get("_objSnackbarService.sSnackbarState") === "showEnd") {
                    this.$(".a24-fab-button-container").css({
                        marginBottom: this.get("_objSnackbarService.iSnackbarHeight") + "px"
                    });
                }
            }
        }),
        animateFabSnackbarSync: function (iHeight) {
            this.$(".a24-fab-button-container").stop("fabSnackbarSyncAnim");
            this.$(".a24-fab-button-container").animate({
                marginBottom: iHeight + "px"
            }, {
                duration: a24Constants.SNACKBAR_SLIDE_TIME,
                queue: "fabSnackbarSyncAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabSnackbarSyncAnim"); //Start the animation queue
        },
        setFabButtonDetails: function () {
            var objFabCurrent = this.get("_objFabService._arrFabActions").objectAt(0);
            this.get("_objFabService._arrFabActions").removeAt(0);
            this.set("_objFabCurrent", objFabCurrent);
            this.showFabButton();
        },
        showFabButton: function () {
            this.set("_sFabState", "showStart");

            //Stop the current animation if there is one and create new one with the correct height
            this.$(".a24-fab-button-container").stop("fabGrowAnim");
            this.$(".a24-fab-button-container").animate({
                padding: "0px"
            }, {
                duration: this.get("_iAnimInSpeed"),
                complete: () => {
                    Ember.run(() => {
                        this.showFabButtonEnd();
                    });
                },
                queue: "fabGrowAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabGrowAnim"); //Start the animation queue
            this.$(".a24-fab-button-main .material-icons").animate({
                fontSize: this.get("_objLibConstants.FONT_SIZE_H4_PX") + "px",
                lineHeight: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") + "px"
            }, this.get("_iAnimInSpeed"));
        },
        showFabButtonEnd: function () {
            this.set("_sFabState", "showEnd");

            //If there are other fabs that want to show then hide this one
            if (this.get("_bClearFab")) {
                this.set("_bClearFab", false);
                this.hideFabButton();
            } else if (this.get("_objFabService._arrFabActions").length > 0) {
                this.hideFabButton();
            }
        },
        hideFabButton: function () {
            this.set("_sFabState", "hideStart");

            //Stop the current animation if there is one and create new one with the correct height
            this.$(".a24-fab-button-container").stop("fabGrowAnim");
            this.$(".a24-fab-button-container").animate({
                padding: this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE_PX") / 2 + "px"
            }, {
                duration: this.get("_iAnimInSpeed"),
                complete: () => {
                    Ember.run(() => {
                        this.hideFabButtonEnd();
                    });
                },
                queue: "fabGrowAnim"
            });
            this.$(".a24-fab-button-container").dequeue("fabGrowAnim"); //Start the animation queue
            this.$(".a24-fab-button-main .material-icons").animate({
                fontSize: "0px",
                lineHeight: "0px"
            }, this.get("_iAnimInSpeed"));
        },
        hideFabButtonEnd: function () {
            this.set("_sFabState", "hideEnd");
            if (this.get("_objFabService._arrFabActions").length > 0) {
                this.setFabButtonDetails();
            }
        },
        actions: {
            onFabClick: function () {
                if (this.get("_sFabState") === "showEnd") {
                    this.get("_objFabCurrent.funcFabAction")();
                }
            }
        }
    });
});