define("a24-ember-lib/components/popup-modal", ["exports", "a24-ember-lib/templates/components/popup-modal", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _popupModal, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _popupModal.default,
        // id of the component
        sModalId: null,
        // used to determine what was clicked
        mValue: null,
        // the text on the confirm button
        sConfirmButtonText: null,
        // the text on the cancel button
        sCancelButtonText: null,
        // used to determine if clicking outside the popup will close it
        bDismissible: true,
        // used to open/close the popup
        bShowPopup: false,
        // used to open/close the popup when a button is clicked
        bAutoClose: true,
        // Keeps track of the loading state.
        bLoading: false,
        // hide buttons
        bHasDefaultButtons: true,
        // used to prevent popup from getting hidden or shown while animating
        _bPopupAnimating: false,
        _objStringsService: Ember.inject.service("a24-strings"),
        init: function () {
            this._super(...arguments);

            // If button not empty string set it to default
            if (a24Core.isEmpty(this.get("sConfirmButtonText")) && this.get("sConfirmButtonText") !== "") {
                this.set("sConfirmButtonText", this.get("_objStringsService").getString("ok"));
            }
            // If button not empty string set it to default
            if (a24Core.isEmpty(this.get("sCancelButtonText")) && this.get("sCancelButtonText") !== "") {
                this.set("sCancelButtonText", this.get("_objStringsService").getString("cancel"));
            }

            Ember.run.scheduleOnce("afterRender", this, function () {
                this.$().attr("id", this.get("sModalId"));
                this.$().attr("role", "dialog");
                // this is to preserve the order in which the attributes get added
                this.$().attr("style", "display: none");
                this.$().addClass("modal");
                this.$().addClass("fade");

                if (a24Core.isEmpty(this.get("bShowPopup"))) {
                    this.set("bShowPopup", false);
                }

                $(this.$()).modal({
                    backdrop: false,
                    show: this.get("bShowPopup")
                });

                var objThis = this;
                $(this.$()).on("hide.bs.modal", function () /*objEvent*/{
                    objThis.set("_bPopupAnimating", true);
                    objThis.set("bShowPopup", false);
                });
                $(this.$()).on("show.bs.modal", function () /*objEvent*/{
                    objThis.set("_bPopupAnimating", true);
                    objThis.set("bShowPopup", true);
                });
                $(this.$()).on("hidden.bs.modal", function () /*objEvent*/{
                    if (!a24Core.isEmpty(objThis)) {
                        objThis.set("_bPopupAnimating", false);
                        if (objThis.get("bShowPopup")) {
                            $(objThis.$()).modal("show");
                        }
                    }
                });
                $(this.$()).on("shown.bs.modal", function () /*objEvent*/{
                    if (!a24Core.isEmpty(objThis)) {
                        objThis.set("_bPopupAnimating", false);
                        if (!objThis.get("bShowPopup")) {
                            $(objThis.$()).modal("hide");
                        }
                    }
                });
            });
        },
        observeShowHideChanges: Ember.observer("bShowPopup", function () {
            if (this.get("bShowPopup") && !this.get("_bPopupAnimating")) {
                $(this.$()).modal("show");
                a24ComponentEvent.onPopupConfirmationModalOpen();
            } else if (!this.get("bShowPopup") && !this.get("_bPopupAnimating")) {
                $(this.$()).modal("hide");
                a24ComponentEvent.onPopupConfirmationModalClose();
            }
        }),
        actions: {
            onPopupConfirmationCancel: function () {
                if (!a24Core.isEmpty(this.get("onPopupConfirmationCancel"))) {
                    this.get("onPopupConfirmationCancel")(this.get("mValue"));
                }

                if (this.get("bAutoClose")) {
                    this.set("bShowPopup", false);
                }
            },
            onPopupConfirmationConfirm: function () {
                if (!a24Core.isEmpty(this.get("onPopupConfirmationConfirm"))) {
                    this.get("onPopupConfirmationConfirm")(this.get("mValue"));
                }

                if (this.get("bAutoClose")) {
                    this.set("bShowPopup", false);
                }
            },
            onPopupBackdropClick: function () {
                if (this.get("bDismissible")) {
                    if (!a24Core.isEmpty(this.get("onPopupConfirmationCancel"))) {
                        this.get("onPopupConfirmationCancel")(this.get("mValue"));
                    }

                    if (this.get("bAutoClose")) {
                        this.set("bShowPopup", false);
                    }
                }
            }
        }
    });
});