define("a24-ember-policy-library/services/rest-system-admin-policy-type", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        _sRestApiBaseUrl: Ember.computed.readOnly("_objEnv.sRestApiBaseUrl"),

        _objEnv: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This function return get current policy id
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  15 August 2018
         */
        getPolicyTypeViewId: function () {
            if (!a24Core.isEmpty(this.get("_objNavigation").get("objRouteParams")["index.policy-type-view"])) {
                return this.get("_objNavigation").get("objRouteParams")["index.policy-type-view"].policy_type_id;
            }
        },

        /**
         * This service method will be used to retrieve all policy types
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sSystemAdminId - The id of the system admin
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - The query param object
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  22 May 2018
         */
        getPolicyTypes: function (objCaller, sProperty, sSystemAdminId, funcSuccess, objFailure, objQueryParams) {
            var sUrl = this.get("_sRestApiBaseUrl") + "/v1/staffshift/web/policy-type";
            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },
        /**
         * This service method will be used to retrieve one policy type
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sSystemAdminId - The id of the system admin
         * @param sPolicyTypeId - The id of the policy type
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  22 May 2018
         */
        getPolicyType: function (objCaller, sProperty, sSystemAdminId, sPolicyTypeId, funcSuccess, objFailure) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", this.get("_sRestApiBaseUrl") + "/v1/staffshift/web/policy-type/" + sPolicyTypeId, funcSuccess, objFailure));
        },
        /**
         * This service method will post to the policy type route
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sSystemAdminId - The id of the system admin
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  07 July 2017
         */
        createPolicyType: function (objCaller, sProperty, sSystemAdminId, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + "/v1/staffshift/web/policy-type", funcSuccess, objFailure, objPayload));
        },
        /**
         * This service method will post to the policy type route for a specific policy type
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sSystemAdminId - The id of the system admin
         * @param sPolicyTypeId - The id of the policy type
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  22 May 2018
         */
        updatePolicyType: function (objCaller, sProperty, sSystemAdminId, sPolicyTypeId, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sRestApiBaseUrl") + "/v1/staffshift/web/policy-type/" + sPolicyTypeId, funcSuccess, objFailure, objPayload));
        }
    });
});