define("a24-ember-policy-library/services/rest-agency-group-policy", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service return get current policy id
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  22 May 2018
         */
        getPolicyTypeViewId: function () {
            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteParams")["agency-group.policy-type-view"])) {
                return this.get("_objNavigation.objRouteParams")["agency-group.policy-type-view"].policy_type_id;
            }
        },

        /**
         * This service return get current policy id
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  22 May 2018
         */
        getPolicyTypeAddId: function () {
            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteParams")["agency-group.policy-add"])) {
                return this.get("_objNavigation.objRouteParams")["agency-group.policy-add"].policy_type_id;
            }
        },

        /**
         * This function download pdf by get for a policy version
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  23 May 2018
         */
        downloadPdfByGET: function (sPolicyTypeId, iVersion, funcSuccess, funcFailure) {
            var sUrl = this.get("sRestApiBaseUrl") + "/v1" + "/agency-group/" + this.get("_objUserSession").getUserContextReference().mId + "/policy-type/" + sPolicyTypeId + "/version/" + iVersion;

            a24RestCallHelper.downloadPdfByGET(sUrl, "PolicyVersionPreview.pdf", funcSuccess, funcFailure, {
                Authorization: "Bearer " + this.get("_objUserSession.objAuthData.accessToken"),
                Context: "AgencyGroup " + this.get("_objUserSession").getUserContextReference().mId
            });
        },

        /**
         * This function download pdf by post for a policy version
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  23 May 2018
         */
        downloadPdfByPOST: function (sPolicyTypeId, iVersion, funcSuccess, funcFailure, objPdfPayload) {
            var sUrl = this.get("sRestApiBaseUrl") + "/v1" + "/agency-group/" + this.get("_objUserSession").getUserContextReference().mId + "/policy-type/" + sPolicyTypeId + "/version/" + iVersion + "/preview";

            a24RestCallHelper.downloadPdfByPOST(sUrl, objPdfPayload, "PolicyVersionPreview.pdf", funcSuccess, funcFailure, {
                Authorization: "Bearer " + this.get("_objUserSession.objAuthData.accessToken"),
                Context: "AgencyGroup " + this.get("_objUserSession").getUserContextReference().mId
            });
        },

        /**
         * This service method will be used to retrieve all policy types
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group
         * @param sPolicyTypeId - The id of the policy type that you want policies for
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - Any extra query params for the get list
         * @param bRetrieveAll - Whether to retrieve all pages
         *
         * @author Ruan Naude<ruan.naude@a24group.com>
         * @since  06 July 2017
         */
        getPoliciesForPolicyType: function (objCaller, sProperty, sAgencyGroupId, sPolicyTypeId, funcSuccess, objFailure, objQueryParams, bRetrieveAll) {

            if (a24Core.isEmpty(bRetrieveAll)) {
                bRetrieveAll = true;
            }

            var objQueryParamsInternal = {
                version: {
                    bSortBy: true,
                    mValue: "desc"
                },
                policy_type_id: {
                    mValue: sPolicyTypeId
                }
            };

            a24RestUrlConstruct.setBaseUrlSwagger("/v1/agency-group/" + sAgencyGroupId + "/policy");
            a24RestUrlConstruct.addQueryParamObject(objQueryParamsInternal);
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "sRestApiBaseUrl") + a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, bRetrieveAll);
        },

        /**
         * This service method will post to the policy route
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Ruan Naude<ruan.naude@a24group.com>
         * @since  13 July 2017
         */
        createPolicy: function (objCaller, sProperty, sAgencyGroupId, funcSuccess, objFailure, objPayload) {

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/policy", funcSuccess, objFailure, objPayload));
        }
    });
});