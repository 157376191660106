define("a24-ember-lib/components/view-edit-section-content", ["exports", "a24-ember-lib/templates/components/view-edit-section-content", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _viewEditSectionContent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _viewEditSectionContent.default,
        arrData: null,
        _sEmptyValue: "-",
        init: function () {
            this._super(...arguments);

            if (this.get("arrData") === null) {
                this.set("arrData", []);
            }
        }
    });
});