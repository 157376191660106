define("a24-ember-lookup/services/a24-enums-base", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        /**
         * This function will get you the backend equivalent when you have the frontend value
         *
         * @param sEnum - The name of the enum to use
         * @param sValue - The display value
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 January 2018
         *
         * @return The backend value that you can send to api
         */
        getBackendValue: function (sEnum, sValue) {
            var objEnum = this[sEnum];

            if (a24Core.isEmpty(objEnum)) {
                return null;
            }

            var objValue = objEnum[sValue];

            if (a24Core.isEmpty(objValue)) {
                return null;
            }

            return objValue.backend;
        },

        /**
         * This function will get you the frontend equivalent when you have the api value
         *
         * @param sEnum - The name of the enum to use
         * @param sValue - The value from the api
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 January 2018
         *
         * @return The frontend value that you can display on the UI
         */
        getFrontendValue: function (sEnum, sValue) {
            var objEnum = this[sEnum];

            if (a24Core.isEmpty(objEnum)) {
                return null;
            }

            var objValue = objEnum[sValue];

            if (a24Core.isEmpty(objValue)) {
                return null;
            }

            return objValue.frontend;
        },

        /**
         * This function will get you dropdown friendly list of items that are supported
         *
         * @param sEnum - The name of the enum to use
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  22 January 2018
         *
         * @return This method will return a dropdown key value list
         */
        getList: function (sEnum) {
            var objEnum = this[sEnum];

            if (a24Core.isEmpty(objEnum)) {
                return null;
            }

            return Object.keys(objEnum).map(sKey => {
                return {
                    sValue: this.getBackendValue(sEnum, sKey),
                    sLabel: this.getFrontendValue(sEnum, sKey)
                };
            });
        }
    });
});