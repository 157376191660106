define("a24-ember-lib/services/google-analytics", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objNavigation: Ember.inject.service("navigation"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        init: function () {
            this._super(...arguments);

            //Only create the google analytics if it is configured for the current environment
            if (!a24Core.isEmpty(this._getGoogleAnalyticsConfig())) {
                this._gaWrapper("create", this._getGoogleAnalyticsConfig().sWebPropertyId, "auto");
            }
        },
        doPageViewHit: function (sPage) {
            //Only do screen hit if it is configured for the current environment
            if (!a24Core.isEmpty(this._getGoogleAnalyticsConfig())) {
                var sPageHit = sPage;
                if (!a24Core.isEmpty(this.get("_objNavigation.sContextId"))) {
                    //Replace context id in the page url else analytics will think each user hits an unique page, when it
                    //is in fact the same page e.g /agency/543231364564645623/welcome and /agency/543231364666666666/welcome
                    //Will need to update this in the future when we have more/other ids in the url
                    sPageHit = sPageHit.replace(this.get("_objNavigation.sContextId"), this.get("_objLibConstants.GOOGLE_ANALYTICS_PAGE_VIEW_ID_TOKEN"));
                }
                this._gaWrapper("send", "pageview", sPageHit);
            }
        },
        _getGoogleAnalyticsConfig: function () {
            var objConfig = Ember.getOwner(this).resolveRegistration("config:environment");
            return objConfig.objGoogleAnalytics;
        },
        _gaWrapper: function (sAction, sActionValue, mPayload) {
            ga(sAction, sActionValue, mPayload);
        }
    });
});