define("a24-ember-lib/components/progress-loader-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-lib/templates/components/progress-loader-component"], function (exports, _didRenderChangesMixin, _progressLoaderComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _progressLoaderComponent.default,
        classNames: ["progress-loader-component"],
        iProgress: null,
        sLoaderText: null,
        _objDomEventService: Ember.inject.service("dom-event"),
        _iMinHeight: 100,

        _sProgressStyle: Ember.computed("iProgress", function () {
            return `width: ${this.get("iProgress")}%;`.htmlSafe();
        }),

        init: function () {
            this._super(...arguments);
            this.get("_objDomEventService");

            Ember.run.scheduleOnce("afterRender", () => {
                this.setLoaderWrapperSize();
            });
        },

        setLoaderWrapperSize: function () {
            this.$().removeClass("loader-min-height");
            if (this.$().parent().css("position") === "relative") {

                if (this.$().parent().height() < this.get("_iMinHeight")) {
                    this.$().addClass("loader-min-height");
                }
            } else {
                console.error( //eslint-disable-line no-console
                "The progress-loader-componen should always be within a parent div " + "that has position:relative on it.");
            }
        },

        _onWindowResize: Ember.observer("_objDomEventService.iPageWidth", "_objDomEventService.iPageHeight", function () {
            Ember.run.once(this, "setLoaderWrapperSize");
        })
    });
});