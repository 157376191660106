define("a24-ember-lookup/helpers/lookup-string-html", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Helper.extend({
        _objA24Strings: Ember.inject.service("a24-strings"),

        compute(arrParams) {
            // Pull service or default
            var objService = arrParams[1] || this.get("_objA24Strings");

            // Remove service argument
            var arrCopiedParams = Ember.copy(arrParams);
            arrCopiedParams.splice(1, 1);

            return objService.getHTMLTokenString.apply(objService, arrCopiedParams);
        }
    });
});