define("a24-ember-lib/components/accordion-item-component", ["exports", "a24-ember-lib/templates/components/accordion-item-component", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-window/mixins/a24responsive"], function (exports, _accordionItemComponent, _didRenderChangesMixin, _a24responsive) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, _a24responsive.default, {
        layout: _accordionItemComponent.default,
        _sItemId: null,
        // id of the parent component, this is used to group components so they
        // work together ( currently only 1 group per page is supported, mdbootstrap
        // needs to be updated to support more )
        sParentComponentId: null,
        classNames: ["accordion-item-component"],
        // header for the round icon header component
        sHeader: null,
        sHeaderClass: "text-bold a24-grey-text-darken-3",
        // subheader for the round icon header component
        sSubHeader: null,
        sSubHeaderClass: "text-bold a24-grey-text-darken-3",
        // Icon flag
        bHasIcon: true,
        // Icon details
        sRoundButtonSize: null,
        sLetter: null,
        sIconName: null,
        sImageUrl: null,
        sIconColor: null,
        sIconClass: "text-white",

        // whether the component is initially expanded or collapsed
        bExpanded: false,
        // array of action items
        arrActionItems: null,
        objSpecialAction: null,
        // function to be called when collapse trigger item is clicked
        funcOnItemClicked: null,
        // This will keep a state of the opened state to be used with the dropdown
        _bAccordionOpened: false,
        // Used to override the open dropdown back to false
        bOverrideDropdown: false,
        sExtraInfo: null,
        // Used to display the action items in the header of the accordion. Else will display on the extra info header
        _bHeaderActionItems: false,
        // This is just a copy of the action items array
        _arrExtraInfoActions: null,
        _objExtraInfoSpecialAction: null,
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        init: function () {
            this._super(...arguments);
            // this is done so that the _sItemId can passed in during testing and
            // we can test that it is set correct
            if (a24Core.isEmpty(this.get("_sItemId"))) {
                this.set("_sItemId", Ember.guidFor({}));
            }
            // If the default state is expanded, set the open state
            if (this.get("bExpanded")) {
                this.set("_bAccordionOpened", true);
            }

            this.set("_arrExtraInfoActions", Ember.copy(this.get("arrActionItems"), true));
            this.set("_objExtraInfoSpecialAction", Ember.copy(this.get("objSpecialAction"), true));
        },
        didInsertElement: function () {
            this._super(...arguments);
            Ember.run.next(() => {
                this.onScreenChange();
                // if the parent component id is set then we also need the panel
                // class for the accordion to work in a group
                if (!a24Core.isEmpty(this.get("sParentComponentId"))) {
                    this.$().addClass("panel");
                }
            });
        },
        onScreenChange: Ember.observer("sScreenSize", function () {
            var bHeaderActionItems = false;
            if (!a24Core.isEmpty(this.get("sExtraInfo"))) {
                if (this.get("sScreenSize") === this.get("_objWindowConstants.MEDIA_SIZE_MOBILE") || this.get("sScreenSize") === this.get("_objWindowConstants.MEDIA_SIZE_TINY")) {
                    bHeaderActionItems = true;
                }
            } else {
                bHeaderActionItems = true;
            }

            this.set("_bHeaderActionItems", bHeaderActionItems);
        }),
        actions: {
            onCollapseExpandStart: function (sCollapseId) {
                if (!a24Core.isEmpty(this.get("onCollapseExpandStart"))) {
                    return this.get("onCollapseExpandStart")(sCollapseId);
                }
            },
            onCollapseExpandEnd: function (sCollapseId) {
                this.set("_bAccordionOpened", true);
                if (!a24Core.isEmpty(this.get("onCollapseExpandEnd"))) {
                    return this.get("onCollapseExpandEnd")(sCollapseId);
                }
            },
            onCollapseCollapseStart: function (sCollapseId) {
                if (!a24Core.isEmpty(this.get("onCollapseCollapseStart"))) {
                    return this.get("onCollapseCollapseStart")(sCollapseId);
                }
            },
            onCollapseCollapseEnd: function (sCollapseId) {
                this.set("_bAccordionOpened", false);
                if (!a24Core.isEmpty(this.get("onCollapseCollapseEnd"))) {
                    return this.get("onCollapseCollapseEnd")(sCollapseId);
                }
            },
            onAccordionMouseLeave: function () {
                // If the accordion is not expanded
                if (!this.get("_bAccordionOpened")) {
                    // Force dropdown to be closed
                    this.set("bOverrideDropdown", false);
                }
            },
            onAccordionMouseClick: function () {
                if (!a24Core.isEmpty(this.get("funcOnItemClicked"))) {
                    return this.get("funcOnItemClicked")();
                }
            }
        }
    });
});