define("a24-ember-lib/components/default-image-component", ["exports", "a24-ember-lib/templates/components/default-image-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _defaultImageComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _defaultImageComponent.default,
        classNames: ["default-image"],
        sSrc: "", // The image url
        sImgClasses: "", // The classes to apply to the image
        sWidth: "",
        sHeight: "",
        _sImageSrc: "",
        //1x1 transparent pixel base64 encoded
        _sClearSrc: "data:image/gif;base64,R0lGODlhAQABAIAAAP///////yH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==",
        _bShowDefault: true,
        _bShowImage: false,
        init: function () {
            this._super(...arguments);
            this._onImageChange();
        },
        didInsertElement: function () {
            this._super(...arguments);
            var objThis = this;
            // Add a load listener
            this.$("img").on("load", function () {
                Ember.run(function () {
                    objThis._onLoad();
                });
                return false;
            });
            // Add an error listener
            this.$("img").on("error", function () {
                Ember.run(function () {
                    objThis._onError();
                });
                return false;
            });
            this._setSizeStyle();
        },
        willDestroyElement: function () {
            this._super(...arguments);
            // Unregister the image listeners
            this.$("img").off("load");
            this.$("img").off("error");
        },
        _setSizeStyle: Ember.observer("sWidth", "sHeight", function () {
            if (!a24Core.isEmpty(this.get("sWidth"))) {
                this.$().css("width", this.get("sWidth"));
            }
            if (!a24Core.isEmpty(this.get("sHeight"))) {
                this.$().css("height", this.get("sHeight"));
            }
        }),
        _sImageClassNames: Ember.computed("sImgClasses", "_bShowImage", function () {
            if (this.get("_bShowImage")) {
                return "display-block " + this.get("sImgClasses");
            }
            return "display-none";
        }),
        _onImageChange: Ember.observer("sSrc", function () {
            var objThis = this;

            this.set("_sImageSrc", this.get("_sClearSrc"));
            this.set("_bShowImage", false);
            this.set("_bShowDefault", true);

            if (!a24Core.isEmpty(this.get("sSrc"))) {
                //Then schedule a after render to change the src to the actual src, this is so that the image will first
                //render the placeholder and show that while the correct img src is fetched
                Ember.run.scheduleOnce("afterRender", function () {
                    objThis.set("_sImageSrc", objThis.get("sSrc"));
                });
            }
        }),
        _onLoad: function () {
            if (this.get("_sImageSrc") !== this.get("_sClearSrc")) {
                // If the image successfully loaded
                this.set("_bShowDefault", false);
                this.set("_bShowImage", true);

                if (!a24Core.isEmpty(this.get("onLoad"))) {
                    this.get("onLoad")();
                }
            }
        },
        _onError: function () {
            // If the image failed to load
            this.set("_bShowImage", false);
            this.set("_bShowDefault", true);

            if (!a24Core.isEmpty(this.get("onError"))) {
                this.get("onError")();
            }
        },
        click: function () {
            if (!a24Core.isEmpty(this.get("onClick"))) {
                this.get("onClick")();
            }
        }
    });
});