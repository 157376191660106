define("a24-ember-lib/components/shareable-link", ["exports", "a24-ember-lib/templates/components/shareable-link", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _shareableLink, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _shareableLink.default,
        classNames: ["display-inline-block position-relative"],

        bShowOnFailure: false,
        _sShareableLinkId: "shareableLinkDropdown",
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _sCurrentUrl: "",
        sToolTipPosition: null,
        init: function () {
            this._super(...arguments);

            this.set("_sShareableLinkId", Ember.guidFor({}));

            // set the tooltip position if not set
            if (a24Core.isEmpty(this.get("sToolTipPosition"))) {
                this.set("sToolTipPosition", "bottom");
            }
        },
        copyToClipboard: function (sValue) {
            // Creates a input element off screen
            var objTempElement = $("<input>");
            objTempElement.addClass("display-off-screen");
            // Add the value to the temp element.
            $("body").append(objTempElement);

            // Select temp element.
            objTempElement.val(sValue).select();

            var bSuccess = a24Core.copySelected();

            // Remove the input once copy is done
            objTempElement.remove();

            return bSuccess;
        },
        actions: {
            closeShareDropdown: function () {
                this.set("bShowOnFailure", false);
            },
            shareButtonClicked: function () {
                var sCurrentUrl = a24Core.getCurrentUrl();
                this.set("_sCurrentUrl", sCurrentUrl);

                //Run in next to allow the input to update with the new value in the DOM
                Ember.run.next(() => {
                    var bSuccessful = false;

                    try {
                        bSuccessful = this.copyToClipboard(sCurrentUrl);
                    } catch (objError) {
                        bSuccessful = false;
                    }

                    if (bSuccessful) {
                        this.set("bShowOnFailure", false);
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("copySuccess"));
                    } else {
                        this.set("bShowOnFailure", true);
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("copyFailed"));

                        //Re-focus and select the input since it will lose focus when the dropdown becomes visible
                        Ember.run.next(() => {
                            var sShareUrlText = this.get("objChildren.sShareLink").$("input");
                            sShareUrlText.select();
                        });
                    }
                });
            }
        }

    });
});