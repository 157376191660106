define("a24-ember-lib/services/snackbar", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        //For things such as the FAB button that needs to react on the height and animation of the snackbar will need
        //to observe the sSnackbarState to animate along with it AND also the iSnackbarHeight since this might change at
        //any time during the showing of the snackbar due to device rotation or resizing
        sSnackbarState: "hideEnd", //beforeShowStart, showStart, showEnd, hideStart, hideEnd
        iSnackbarHeight: 0,
        _bHasNewMessage: false,
        _arrSnackbarMessages: null,
        init: function () {
            this._super(...arguments);
            if (a24Core.isEmpty(this.get("_arrSnackbarMessages"))) {
                this.set("_arrSnackbarMessages", []);
            }
        },
        showSnackbarMessage: function (sMessage, sAction, funcActionCallback, sActionColor) {
            this.get("_arrSnackbarMessages").pushObject({
                sMessage: sMessage,
                sAction: sAction,
                funcActionCallback: funcActionCallback,
                sActionTextClass: sActionColor
            });
            this.set("_bHasNewMessage", true);
        }
    });
});