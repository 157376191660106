define("a24-ember-lib/components/shadow-scroller", ["exports", "a24-ember-lib/templates/components/shadow-scroller", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _shadowScroller, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _shadowScroller.default,
        classNames: ["position-relative"],
        iMaxHeight: -1,
        //Only needed when component is used inside a popup, this is because the parent wont have a height
        //until the popup is visible
        bPopupOpen: false,
        _bShowShadow: false,
        _objDomEventService: Ember.inject.service("dom-event"), // Dom event service
        _objInputScrollTo: null,
        _iOffset: null,
        _bInternalResizeProcess: false,
        init: function () {
            this._super(...arguments);
            this.get("_objDomEventService");

            Ember.run.scheduleOnce("afterRender", () => {
                //Set the correct state
                if (this.get("iMaxHeight") === -1) {
                    this.setResponsiveMaxHeight();
                } else {
                    this.setMaxHeight();
                }

                var objThis = this;
                this.$(".shadow-scroller").scroll(function () {
                    Ember.run(() => {
                        objThis.set("_bShowShadow", objThis.$(".shadow-scroller").scrollTop() !== 0);
                    });
                });
            });
        },
        setMaxHeight: Ember.observer("iMaxHeight", function () {
            this.$(".shadow-scroller").css({ maxHeight: this.get("iMaxHeight") + "px" });
        }),
        setResponsiveMaxHeight: function () {
            if (this.get("iMaxHeight") === -1) {
                //Reset max-height before calcs
                this.$(".shadow-scroller").css({ maxHeight: this.$(".shadow-scroller").prop("scrollHeight") + "px" });

                var objScrollerParent = this.$().parent();
                var iMaxHeight = objScrollerParent.height() - (objScrollerParent.prop("scrollHeight") - this.$(".shadow-scroller").prop("scrollHeight"));

                if (iMaxHeight < 0) {
                    iMaxHeight = 0;
                }
                this.$(".shadow-scroller").css({ maxHeight: iMaxHeight + "px" });
            }
        },
        onWindowResize: Ember.observer("_objDomEventService.iPageWidth", "_objDomEventService.iPageHeight", "bPopupOpen", function () {
            Ember.run.scheduleOnce("afterRender", this, this.setResponsiveMaxHeight);
        }),
        updateMaxHeightPreserveScrollPosition: function () {
            if (!this.get("_bInternalResizeProcess")) {
                this.set("_bInternalResizeProcess", true);
                // get scroll position
                var iScrollTop = this.$(".shadow-scroller").scrollTop();

                this.setResponsiveMaxHeight();

                // restore scroll position
                this.$(".shadow-scroller").scrollTop(iScrollTop);

                this.set("_bInternalResizeProcess", false);
            }
        },
        onRender: Ember.observer("_objDidRenderService._iCountRender", function () {
            Ember.run.scheduleOnce("afterRender", this, this.updateMaxHeightPreserveScrollPosition);
        }),
        // Scroll to object and offset then reset the scroll element and offset to null
        scrollToObject: function () {
            if (!a24Core.isEmpty(this.get("_objInputScrollTo"))) {
                this.$(".shadow-scroller").animate({
                    scrollTop: this.get("_objInputScrollTo").$().offset().top - this.$(".shadow-scroller").offset().top + this.$(".shadow-scroller").scrollTop() - this.get("_iOffset")
                }, 100);
            }
            this.set("_objInputScrollTo", null);
            this.set("_iOffset", null);
        },
        actions: {
            onScrollTo: function (objEmberItem, iOffset) {
                if (a24Core.isEmpty(this.get("_objInputScrollTo"))) {
                    this.set("_objInputScrollTo", objEmberItem);
                    this.set("_iOffset", iOffset);
                }

                Ember.run.once(this, this.scrollToObject);
            }
        }
    });
});