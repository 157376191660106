var a24ConstantsExtend = {

    DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY: "MMMM YYYY",
    DATE_FORMAT_REST_FORMAT_DATE_ONLY: "DD MMMM YYYY",
    DATE_FORMAT_REST_FORMAT_WITHOUT_TZ: "DD MMMM YYYY HH:mm:ss",
    DATE_FORMAT_REST_FORMAT: "DD MMMM YYYY HH:mm:ss ZZ",

    SIZE_LIMIT_COMPONENT_MAX_WIDTH: 752,
    SNACKBAR_SLIDE_TIME: 500,

    // #### This is only here because of the date helper using the constants
    DATE_FORMAT: "DD MMM YYYY",
    DATE_FORMAT_ISO_FORMAT: "YYYY-MM-DDTHH:mm:ssZ",
    DATE_TIME_FORMAT: "DD MMM YYYY HH:mm",
    DATE_TIME_DASH_FORMAT: "DD MMM YYYY - HH:mm",
    TIME_FORMAT: "HH:mm",
    YEAR_FORMAT: "YYYY",
    MONTH_FORMAT: "MMM",
    MONTH_YEAR_FORMAT: "MMM YYYY",
    DAY_FORMAT: "DD",

    MILLISECONDS_1_SECOND: 1000,
    MILLISECONDS_1_MINUTE: 60000,
    MILLISECONDS_1_DAY: 86400000,

    MOMENT_TIME_YEARS: "years",
    MOMENT_TIME_MONTHS: "months",
    MOMENT_TIME_WEEKS: "weeks",
    MOMENT_TIME_DATE: "date",
    MOMENT_TIME_DAYS: "days",
    MOMENT_TIME_HOURS: "hours",
    MOMENT_TIME_MINUTES: "minutes",
    MOMENT_TIME_SECONDS: "seconds",

    MOMENT_UNIT_ISO_WEEK: "isoWeek",
    MOMENT_UNIT_YEAR: "year",
    MOMENT_UNIT_MONTH: "month",
    MOMENT_TIMEZONE_UTC: "UTC",

    MAX_END_HOUR: 23,
    MAX_END_MIN: 59,
    MAX_END_SEC: 59,
    // ####

};

//Only add the extended values to the a24Constants if they do not exist on it already
Object.keys(a24ConstantsExtend).forEach(function(sKey) {
    if (!a24Constants.hasOwnProperty(sKey)) {
        a24Constants[sKey] = a24ConstantsExtend[sKey];
    }
});
