define("a24-ember-staffshift-core/services/rest-checkauth", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),
        _objNavigation: Ember.inject.service("navigation"),
        getCheckAuth: function (objContext, sProperty, funcSuccess, objFailure) {
            a24RestUrlConstruct.setBaseUrl(this.get("_sTriageBaseRest") + "/rest/checkauth");

            return a24RestCallHelper.doRestServiceCall(objContext, sProperty, a24RestCallHelper.makeJsonAjaxCall(objContext, null, //do not want to user user session since this will add headers we do not want
            this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure, null, null, false, false, true));
        }
    });
});