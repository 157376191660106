var a24EmberSSCore = {
    isUrlDefaultAvatar: function(objConstService, sUrl) {
        return (
            sUrl === objConstService.AGENCY_DEFAULT_AVATAR ||
            sUrl === objConstService.CANDIDATE_DEFAULT_AVATAR ||
            sUrl === objConstService.ORGANISATION_DEFAULT_AVATAR ||
            sUrl === objConstService.SYSTEM_ADMIN_DEFAULT_AVATAR ||
            sUrl === objConstService.AGENCY_GROUP_DEFAULT_AVATAR
        );
    },
};

if (typeof a24 === "undefined") {
    var a24 = {};
}

Object.keys(a24EmberSSCore).forEach(function(sKey) {
    a24[sKey] = a24EmberSSCore[sKey];
});
