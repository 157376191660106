define("a24-ember-window/mixins/a24responsive", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        sScreenSize: "",
        _objWindowConstants: Ember.inject.service("a24-constants-window"),
        init: function () {
            this._super(...arguments);
            this._onSizeChange();
        },
        _onSizeChange: Ember.observer("media.isTiny", "media.isMobile", "media.isTablet", "media.isDesktop", "media.isJumbo", function () {
            if (this.get("media.isTiny")) {
                this.set("sScreenSize", this.get("_objWindowConstants.MEDIA_SIZE_TINY"));
            } else if (this.get("media.isMobile")) {
                this.set("sScreenSize", this.get("_objWindowConstants.MEDIA_SIZE_MOBILE"));
            } else if (this.get("media.isTablet")) {
                this.set("sScreenSize", this.get("_objWindowConstants.MEDIA_SIZE_TABLET"));
            } else if (this.get("media.isDesktop")) {
                this.set("sScreenSize", this.get("_objWindowConstants.MEDIA_SIZE_DESKTOP"));
            } else if (this.get("media.isJumbo")) {
                this.set("sScreenSize", this.get("_objWindowConstants.MEDIA_SIZE_JUMBO"));
            }
        })
    });
});