define("a24-ember-form-inputs/components/input-form-tel-number", ["exports", "a24-ember-form-inputs/templates/components/input-form-tel-number", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormTelNumber, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormTelNumber.default,
        classNames: ["a24TelInput", "a24ErrorInputParent", "md-form"],
        sValue: null,
        sInputType: "tel",
        sCountryCode: "",
        // You have to handle the onInputFormTelNumberInitDone in the parent app
        onInputFormTelNumberInitDone: null,
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        //Remember to also update the scss vars when updating this to new version
        _sResourceLocation: "/a24-ember-form-inputs/intl-tel-v1",
        _sDefaultCountry: "gb",
        _bInitDone: false,
        _objInputFormStringsService: Ember.inject.service("a24-strings-form-inputs"),
        _objAjax: $.ajax,
        // this property exists so that we can init the component for formating purposes
        _objIntlTelConfig: null,
        init: function () {
            this._super(...arguments);
            if (a24Core.isEmpty(this.get("onInputFormTelNumberInitDone"))) {
                //eslint-disable-next-line no-console
                console.error("You have to handle and wait for the onInputFormTelNumberInitDone to fire before interacting with " + "this input");
            }

            var objThis = this;
            Ember.run.scheduleOnce("afterRender", this, function () {

                var objTextInput = $("#" + objThis.get("_sInputId"));

                if (a24Core.isEmpty(objThis.get("sCountryCode"))) {
                    if (objThis.get("env").environment === "test") {
                        objThis.set("sCountryCode", objThis.get("_sDefaultCountry"));
                        objThis.loadScripts(objThis);
                    } else {
                        objThis.get("_objAjax")({
                            url: "//ipinfo.io",
                            dataType: "jsonp"
                        }).always(function (objResponse) {
                            if (!a24Core.isEmpty(objResponse) && !a24Core.isEmpty(objResponse.country)) {
                                objThis.set("sCountryCode", objResponse.country);
                            } else {
                                //Could not determine country so just default to gb
                                objThis.set("sCountryCode", objThis.get("_sDefaultCountry"));
                            }
                            objThis.loadScripts(objThis);
                        });
                    }
                } else {
                    objThis.loadScripts(objThis);
                }

                if (objThis.get("env").environment === "test") {
                    objTextInput.on("onSetCountryCodeForTest", (objEvent, sCountryCode) => {
                        objThis.setCountryCode(sCountryCode);
                    });
                }
            });
        },
        loadScripts: function (objThis) {
            var objTextInput = $("#" + objThis.get("_sInputId"));
            var sResourceBase = objThis.get("_sResourceLocation");
            //Load the needed libs and styles for the intl tel number input
            $.when(objThis.get("_objAjax")({
                url: sResourceBase + "/js/utils.js",
                dataType: "script",
                cache: true
            }), objThis.get("_objAjax")({
                url: sResourceBase + "/js/intlTelInput.min.js",
                dataType: "script",
                cache: true
            }), objThis.get("_objAjax")({
                url: sResourceBase + "/css/intlTelInput.min.css",
                cache: true
            }).done(function (sCss) {
                //Add the loaded styles to the DOM
                $("<style type='text/css'></style>").html(sCss).appendTo("head");
            })).then(
            //All calls succes
            function () {
                var objIntlTelConfig = {
                    // allowDropdown: false,
                    // autoHideDialCode: false,
                    // autoPlaceholder: "off",
                    // dropdownContainer: objThis.$(),
                    // excludeCountries: ["us"],
                    // formatOnDisplay: false,
                    // geoIpLookup: function(funcCallback) {
                    //     funcCallback(sCountryCode);
                    // },
                    // hiddenInput: "full_number",
                    initialCountry: objThis.get("sCountryCode"),
                    // nationalMode: false,
                    // onlyCountries: ["us", "gb", "ch", "ca", "do", "za"],
                    // placeholderNumberType: "MOBILE",
                    preferredCountries: ["gb", "za"]
                    // separateDialCode: true,
                    // utilsScript: "/a24-ember-lib/assets/intl-tel-js/utils.js"
                };
                // This is just so that when we are running tests that the list of countries is not that long
                if (objThis.get("env").environment === "test") {
                    objIntlTelConfig.onlyCountries = ["us", "gb", "ch", "ca", "do", "za"];
                }

                this.set("_objIntlTelConfig", objIntlTelConfig);
                objTextInput.intlTelInput(objIntlTelConfig);

                objTextInput.on("countrychange", function () /*event, countryData*/{
                    objThis.send("onInputChange", objTextInput.intlTelInput("getNumber"));
                });

                objTextInput.on("keyup", objEvent => {
                    if (objEvent.which === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    }
                });

                objTextInput.on("blur", function () {
                    objThis.resetFloatingLabel(objTextInput);
                });

                //Done setting up the input as a intl tel input.
                objThis.set("_bInitDone", true);

                //Manually add the character counter since the intlTelInput moves the input that makes that the
                //automatic way of adding it does not work
                if (!a24Core.isEmpty(objThis.get("iMaxCharacters"))) {
                    objTextInput.attr("length", objThis.get("iMaxCharacters"));
                    objTextInput.characterCounter();
                }

                if (!a24Core.isEmpty(objThis.get("sValue"))) {

                    // Set the mvalue first
                    objThis.setMValueWithInternalFlag(objThis.get("sValue"), true);

                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(objThis.get("sValue"));
                    objTextInput.change();
                }

                objThis.resetFloatingLabel(objTextInput);

                //Add permanent validation. Will only execute if field is not empty
                objThis.get("arrValidation").pushObject(function () {
                    if (!a24Core.isEmpty(objTextInput.val())) {
                        if (!objTextInput.intlTelInput("isValidNumber")) {
                            return objThis.get("_objInputFormStringsService").getString("telNumberInputCountryError");
                        }
                    }
                });

                if (!a24Core.isEmpty(objThis.get("onInputFormTelNumberInitDone"))) {
                    // Send the current input instance on
                    objThis.get("onInputFormTelNumberInitDone")(this);
                }
            },
            //One or more calls failed
            function () {
                //eslint-disable-next-line no-console
                console.warn("Failed to load all scripts and styles needed for the input form tel number input");
                objThis.sendAction("onInputFormTelNumberError");
            });
        },
        // This function is used for returning a human readable number for displaying it on screen.
        formatNumberForDisplay: function (sNumber) {

            if (!this.get("_bInitDone")) {
                //eslint-disable-next-line no-console
                console.error("You have to wait for the onInputFormTelNumberInitDone to fire before interacting with this input");
            }

            var sFormatedNumber = "";
            // If the we do not send in the number to format it will pull the current formatted value of the input.
            if (a24Core.isEmpty(sNumber)) {
                var objTextInput = $("#" + this.get("_sInputId"));
                sFormatedNumber = objTextInput.val();
            } else {

                var sInputId = Ember.guidFor({});

                // We are adding a hidden input in the dom so that the google utlis script can initialize so that we can format
                // the number for display purpose.
                // The google utils is embedded into the input itself and not accessible globally thus we are forced to
                // do it this way.
                var sInput = "<div class=\"display-none\"><input hidden placeholder=\"\" type=\"text\" autocomplete=\"off\" id= " + sInputId + " " + "class=\"input-tel-number-placeholder form-control\"/></div>";

                // Append the input tag so we can initialize the lib on it.
                var objHiddenTextInput = $(sInput).appendTo(this.$()).find("input");

                // Initialize the intl tel input onto the input we appended
                // var objHiddenTextInput = $("#" + sInputId);
                objHiddenTextInput.intlTelInput(this.get("_objIntlTelConfig"));

                // Set the number on the hidden input
                objHiddenTextInput.intlTelInput("setNumber", sNumber);
                objHiddenTextInput.change();

                // Pull the formatted value from the hidden input
                sFormatedNumber = objHiddenTextInput.val();

                // Destroys the intlTelInput stuff off the input
                objHiddenTextInput.intlTelInput("destroy");
                // We are removing the parent of the input as we wrapped the input in our own div to make it hidden
                objHiddenTextInput.parent().remove();
            }

            // Return the formatted number
            return sFormatedNumber;
        },
        clear: function () {
            this.setValueFromOutside("");
            this.setCountryCode(this.get("sCountryCode"));
        },
        setValueFromOutside: function (sValue) {
            if (!this.get("_bInitDone")) {
                //eslint-disable-next-line no-console
                console.error("You have to wait for the onInputFormTelNumberInitDone to fire before interacting with this input");
            }

            this.setMValueWithInternalFlag(sValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            objTextInput.intlTelInput("setNumber", sValue);
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        observeValueChange: Ember.observer("sValue", function () {
            this.setValueFromOutside(this.get("sValue"));
        }),
        setCountryCode: function (sCountryCode) {
            var objTextInput = $("#" + this.get("_sInputId"));
            this.set("sCountryCode", sCountryCode);
            objTextInput.intlTelInput("setCountry", sCountryCode);
        },
        resetFloatingLabel: function (objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        },
        actions: {
            onInputChange: function (sValue) {
                // any internal changes that fire this function will have updated
                // mvalue already, meaning the duplicate update will not fire any
                // additional events

                var objTextInput = $("#" + this.get("_sInputId"));
                objTextInput.intlTelInput("setNumber", sValue);

                // The get number gets the number correctly formatted
                this.setMValueWithInternalFlag(objTextInput.intlTelInput("getNumber"), false);
            }
        }
    });
});