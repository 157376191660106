define("a24-ember-lib/components/input-form-password", ["exports", "a24-ember-lib/templates/components/input-form-password", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormPassword, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormPassword.default,
        classNames: ["a24ErrorInputParent", "md-form"],
        sValue: null,
        sInputType: "password",
        init: function () {
            this._super(...arguments);

            var objThis = this;
            Ember.run.scheduleOnce("afterRender", this, function () {
                var objTextInput = $("#" + objThis.get("_sInputId"));

                if (!a24Core.isEmpty(objThis.get("sValue"))) {
                    // Set the mvalue first
                    this.setMValueWithInternalFlag(objThis.get("sValue"), true);

                    // firing a change here will cause the mvalue to update to
                    // the same value, hence not causing a duplicate event to be fired
                    objTextInput.val(this.get("sValue"));
                    objTextInput.change();
                }

                objTextInput.on("keyup", objEvent => {
                    if (objEvent.which === 27) {
                        // Escape key
                        objTextInput.change();
                        objTextInput.blur();
                    }
                });

                this.resetFloatingLabel(objTextInput);

                objTextInput.on("blur", function () {
                    objThis.resetFloatingLabel(objTextInput);
                });
            });
        },
        clear: function () {
            this.setValueFromOutside("");
        },
        setValueFromOutside: function (sValue) {

            this.setMValueWithInternalFlag(sValue, false);

            //Set the new value onto the input since it does not bind anymore
            var objTextInput = $("#" + this.get("_sInputId"));
            objTextInput.val(sValue);
            objTextInput.change();

            this.resetFloatingLabel(objTextInput);
        },
        observeValueChange: Ember.observer("sValue", function () {
            this.setValueFromOutside(this.get("sValue"));
        }),
        resetFloatingLabel: function (objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (objTextInput.val() === "" || objTextInput.val() === null) {
                this.$("label").removeClass("active");
            }
        },
        actions: {
            onInputChange: function (sValue) {
                // any internal changes that fire this function will have updated
                // mvalue already, meaning the duplicate update will not fire any
                // additional events
                this.setMValueWithInternalFlag(sValue, false);
            }
        }
    });
});