define("a24-ember-lib/components/icon-label-value-table-component", ["exports", "a24-ember-lib/templates/components/icon-label-value-table-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _iconLabelValueTableComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _iconLabelValueTableComponent.default,
        // example of the array
        //arrValueEntries: [
        //    {
        //        sIcon: "phone",
        //        sValue: "value",
        //        sLabel: "label",
        //        sRightLabel: "rightLabel",
        //        bEmail: false
        //        bPhone: false
        //        bLink: false
        //    }
        //],
        arrValueEntries: null,
        init: function () {
            this._super(...arguments);

            if (a24Core.isEmpty(this.get("arrValueEntries"))) {
                this.set("arrValueEntries", []);
            }
        }
    });
});