define("a24-ember-lib/components/card-datagrid-column-toggle", ["exports", "a24-ember-lib/templates/components/card-datagrid-column-toggle", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridColumnToggle, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridColumnToggle.default,
        classNames: ["display-inline-block position-relative"],
        arrAllowedHeaders: null,
        _sColumnSelected: "",
        bLastItemChecked: Ember.computed("arrAllowedHeaders.@each.bShowHeader", function () {
            var arrItems = this.get("arrAllowedHeaders").filter(objItem => {
                return Ember.get(objItem, "bShowHeader") && !Ember.get(objItem, "bSilent");
            });

            Ember.run.scheduleOnce("afterRender", this, "toggleDatagridColumnChange");

            return arrItems.length === 1;
        }),
        init: function () {
            this._super(...arguments);

            this.set("_sColumnSelected", Ember.guidFor({}));
        },
        toggleDatagridColumnChange: function () {
            if (!a24Core.isEmpty(this.get("cardDatagridColumnToggleChangeEvent"))) {
                return this.get("cardDatagridColumnToggleChangeEvent")();
            }
        }
    });
});