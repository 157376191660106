define("a24-ember-policy-library/components/page-policy-type-list", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/page-policy-type-list"], function (exports, _inputFormElement, _didRenderChangesMixin, _pagePolicyTypeList) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _pagePolicyTypeList.default,
        classNames: ["page-agency-group-policy-type-list"],
        objSelectedClassification: null,
        sPolicyTypeRestService: "rest-agency-group-policy-type",
        sPolicyRestService: "rest-agency-group-policy",

        _bLoading: false,
        _arrActions: null,
        _bAddPopupShow: false,
        _bAddPopupLoading: false,
        _bShowUnsavedData: false,
        _arrItems: null,
        _sInputPolicyTitle: null,
        _sInputPolicyDesc: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objPolicyTypeRestService: Ember.computed("sPolicyTypeRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyTypeRestService"));
        }),
        _objPolicyRestService: Ember.computed("sPolicyRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyRestService"));
        }),
        _objRestAgencyGroupPolicyTypeProperty: null,
        _objRestAgencyGroupPolicyProperty: null,
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objFabButtonService: Ember.inject.service("fab-button"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objOriginalData: null,
        _objQueryParams: null,
        _arrClassificationDropdown: null,
        _arrPolicyVersionData: null,
        _arrFilterConfig: null,
        _iResultCount: 0,
        _iPage: 1,
        _iPerPage: 25,
        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),
        init: function () {
            this._super(...arguments);

            this.set("_arrFilterConfig", [{
                sTitle: "Classification",
                sProperty: "classification",
                sFilterType: "dropdown",
                arrMappingData: [{
                    sValue: "acknowledgeable",
                    sTitle: a24Enums.classification.getFrontendValue("acknowledgeable")
                }, {
                    sValue: "viewable",
                    sTitle: a24Enums.classification.getFrontendValue("viewable")
                }],
                bHideEmptyCheck: true,
                bMapping: true
            }]);

            this.get("_objFabButtonService").showFabButton(() => {
                this.showAddPopup();
            });

            this.setInitialDropdownPopupData();

            // Set up the form children
            this.set("objChildConfig", {
                "objChildren.title": objMixin => {
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 255);
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.description": objMixin => {
                    a24Validation.addMinLength(objMixin, 3);
                    a24Validation.addMaxLength(objMixin, 1000);
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.classification": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            this.set("_objSpecialAddPopupAction", {
                sTitle: this.get("_objStringsService").getString("close"),
                sIcon: "close",
                executeCardAction: () => {
                    this.send("onPopupAddCancel");
                }
            });

            this.doPolicyTypeListServiceCall();
        },
        doPolicyTypeListServiceCall: function (objQueryParams) {

            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            // Save the current query params for later use
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            this.set("_bLoading", true);

            this.get("_objPolicyTypeRestService").getPolicyTypes(this, "_objRestAgencyGroupPolicyTypeProperty", this.get("_objUserSession").getUserContextReference().mId, (arrData, sStatus, objXHR) => {
                if (!a24Core.isEmpty(arrData)) {
                    this.formatResponseData(arrData);
                } else {
                    this.formatResponseData([]);
                }

                // Set the result count
                this.set("_iResultCount", a24RestCallHelper.getXResultCount(objXHR));

                this.set("_bLoading", false);
            }, null, objQueryParams);
        },
        setInitialDropdownPopupData() {
            this.set("_arrClassificationDropdown", a24Enums.classification.getList());
        },
        formatResponseData: function (arrData) {
            var sPolicyTypeId = null;
            var objThis = this;
            var funcCardActionExecute = function (sId) {
                return function () {
                    objThis.get("_objNavigationService").navigateWithinContext("policy-type-view", {
                        "policy-type-view": [sId]
                    });
                };
            };
            for (var i = 0; i < arrData.length; i++) {
                sPolicyTypeId = arrData[i]._id;
                arrData[i].sItemCollapseId = Ember.guidFor({});
                arrData[i].bPoliciesCallStarted = false;
                arrData[i].arrCollapseActions = [{
                    sTitle: this.get("_objStringsService").getString("edit"),
                    sIcon: "mode_edit",
                    executeCardAction: funcCardActionExecute(sPolicyTypeId)
                }];

                arrData[i].sOptionList = "";
                var arrOptions = [];
                if (!a24Core.isEmpty(arrData[i].options)) {
                    if (!a24Core.isEmpty(arrData[i].options.public_policy) && arrData[i].options.public_policy) {
                        arrOptions.push(this.get("_objPolicyStringsService").getString("public"));
                    }
                    if (!a24Core.isEmpty(arrData[i].options.registration_policy) && arrData[i].options.registration_policy) {
                        arrOptions.push(this.get("_objPolicyStringsService").getString("registration"));
                    }
                }
                arrData[i].sOptionList = arrOptions.join(", ");
            }
            this.set("_arrItems", arrData);
        },
        getPolicyTypeVersions: function (objPolicyType) {
            Ember.set(objPolicyType, "bPoliciesCallStarted", true);

            this.get("_objPolicyRestService").getPoliciesForPolicyType(this, "_objRestAgencyGroupPolicyProperty", this.get("_objUserSession").getUserContextReference().mId, objPolicyType._id, arrData => {
                var arrPolicies = [];
                var sStatus = "";

                if (!a24Core.isEmpty(arrData)) {

                    var funcGetDownloadActionCallback = (iPolicyTypeId, iVersion) => {
                        return () => {
                            this.downloadPDFPreview(iPolicyTypeId, iVersion);
                        };
                    };
                    for (var i = 0; i < arrData.length; i++) {

                        sStatus = arrData[i].active ? this.get("_objStringsService").getString("active") : this.get("_objStringsService").getString("inactive");
                        var iVersion = arrData[i].version;
                        var objDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        objDate.setDateFromRest(arrData[i].effective_at, true);

                        arrPolicies.push({
                            sSubHeader: arrData[i].display_version,
                            iVersion: iVersion,
                            iPolicyTypeId: objPolicyType._id,
                            arrExtraData: [{
                                arrItems: [{
                                    sLabel: this.get("_objStringsService").getString("status"),
                                    sValue: sStatus,
                                    bText: true
                                }, {
                                    sLabel: this.get("_objStringsService").getString("effectiveAt"),
                                    sValue: objDate.getDateFormat(),
                                    bDate: true
                                }]
                            }],
                            arrActions: [{
                                sTitle: this.get("_objStringsService").getString("downloadPdfPreview"),
                                sIcon: "get_app",
                                executeCardAction: funcGetDownloadActionCallback(objPolicyType._id, iVersion)
                            }]
                        });
                    }
                }

                Ember.set(objPolicyType, "arrPolicies", arrPolicies);
                Ember.set(objPolicyType, "bPoliciesCallDone", true);
            });
        },
        downloadPDFPreview: function (iPolicyTypeId, iVersion) {
            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdf"));

            this.get("_objPolicyRestService").downloadPdfByGET(iPolicyTypeId, iVersion, () => {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFinish"));
            }, () => {
                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFailed"));
            });
        },
        showAddPopup: function () {
            this.set("_bAddPopupShow", true);

            Ember.run.next(() => {
                //Clean up the popup input before showing
                this.clearForm();
                this.clearAllErrorMessages();
                this.set("_bAddPopupLoading", false);

                Ember.run.next(() => {
                    Ember.run.schedule("afterRender", () => {
                        this.set("_objOriginalData", this.getPlainFormValueObject());
                    });
                });
            });
        },
        actions: {
            onPopupAddConfirm: function () {
                if (!this.doFullValidation()) {
                    this.set("_bAddPopupLoading", true);

                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicy"));
                    this.get("_objPolicyTypeRestService").createPolicyType(this, "_objRestAgencyGroupPolicyTypeProperty", this.get("_objUserSession").getUserContextReference().mId, () => {
                        this.doPolicyTypeListServiceCall(this.get("_objQueryParams"));
                        this.set("_bAddPopupShow", false);
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicySuccess"));
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                        if (sCode === "MIN_LENGTH") {
                            this.setResponseError(objError.arrPath, "validationRestMinLength");
                        } else if (sCode === "MAX_LENGTH") {
                            this.setResponseError(objError.arrPath, "validationRestMaxLength");
                        } else if (sCode === "PATTERN") {
                            this.setResponseError(objError.arrPath, "validationRestInvalidChar");
                        } else {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicyFailed"));
                        }
                        this.set("_bAddPopupLoading", false);
                    }), this.getPlainFormValueObject());
                }
            },
            onPopupAddCancel: function () {
                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.set("_bAddPopupShow", false);
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onPopupUnsavedConfirm: function () {
                this.set("_bAddPopupShow", false);
                this.set("_bShowUnsavedData", false);
            },
            onCollapseExpandStart: function (sCollapseId) {
                var arrPolicyTypesData = this.get("_arrItems");
                var objPolicyType = null;
                for (var i = 0; i < arrPolicyTypesData.length; i++) {
                    if (arrPolicyTypesData[i].sItemCollapseId === sCollapseId) {
                        objPolicyType = arrPolicyTypesData[i];
                    }
                }
                if (!objPolicyType.bPoliciesCallStarted) {
                    this.getPolicyTypeVersions(objPolicyType);
                }
            },
            onDownloadPDFPreview: function (iPolicyTypeId, iVersion) {
                this.downloadPDFPreview(iPolicyTypeId, iVersion);
            },
            cardDatagridFilterChangeEvent: function (sProperty, objValue) {
                var objParams = Ember.copy(objValue, true);
                this.set("_iPage", 1);
                objParams.page = { mValue: 1 };
                objParams.items_per_page = { mValue: this.get("_iPerPage") };
                this.doPolicyTypeListServiceCall(objParams);
            },
            cardDatagridPagerEvent: function (objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                var objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this.doPolicyTypeListServiceCall(objQueryParams);
            }
        }
    });
});