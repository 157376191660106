define("a24-ember-staffshift-core/services/rest-user", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        _sTriageBaseRest: Ember.computed.readOnly("env.sTriageBaseRest"),
        _objNavigation: Ember.inject.service("navigation"),
        _objUserSession: Ember.inject.service("user-session"),
        _objResetPasswordServiceProperty: null,

        /**
         * This service method will be used to send a link to the user to reset their password
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sUsername - The username the user is trying to log in with
         * @param sEmailAddress - The email address the user is trying to log in with
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The callback function to execute when the service call is not successful
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  6 June 2018
         */
        resetPassword: function (objCaller, sUsername, sEmailAddress, funcSuccess, objFailure) {

            var objData = {
                "sType": "resetPassword"
            };
            if (!a24Core.isEmpty(sUsername)) {
                objData.sUsername = sUsername;
            }
            if (!a24Core.isEmpty(sEmailAddress)) {
                objData.sEmailAddress = sEmailAddress;
            }

            return a24RestCallHelper.doRestServiceCall(objCaller, "_objResetPasswordServiceProperty", a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", this.get("_sTriageBaseRest") + "/rest/user/reset-password", funcSuccess, objFailure, objData, "application/x-www-form-urlencoded; charset=UTF-8", false, true));
        }
    });
});