define("a24-ember-lib/components/dropdown-trigger", ["exports", "a24-ember-lib/templates/components/dropdown-trigger", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _dropdownTrigger, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _dropdownTrigger.default,
        classNameBindings: ["bInline:display-inline-block:display-block"],
        sDropdownContentId: null,
        bInline: true,
        bStopEventPropagation: true,
        /**
         * Handles the click event built in to ember
         *
         * @param objEvent - The default jQuery event that is triggered
         */
        click: function (objEvent) {
            this.dispatchEventAndAction(objEvent, "a24DropdownClick");
        },
        /**
         * Handles the hover enter event built in to ember
         *
         * @param objEvent - The default jQuery event that is triggered
         */
        mouseEnter: function (objEvent) {
            this.dispatchEventAndAction(objEvent, "a24DropdownEnter");
        },
        /**
         * Handles the hover exit event built in to ember
         *
         * @param objEvent - The default jQuery event that is triggered
         */
        mouseLeave: function (objEvent) {
            this.dispatchEventAndAction(objEvent, "a24DropdownLeave");
        },
        /**
         * This is a wrapper that will dispatch the event to the correct place and call a sendAction
         *
         * @param objEvent - The default jQuery event that is triggered
         * @param sDropdownActionName - The name of the action that should be sent to the dropdown
         */
        dispatchEventAndAction: function (objEvent, sDropdownActionName) {
            // Stop the propagation on the event
            if (this.get("bStopEventPropagation")) {
                objEvent.preventDefault();
                objEvent.stopPropagation();
            }
            // Get the id of the dropdown this trigger is linked to
            var sDropdownContentId = this.get("sDropdownContentId");

            // Select the dropdown, and trigger the event on it
            Ember.$("#" + sDropdownContentId).trigger(sDropdownActionName);

            if (!a24Core.isEmpty(this.get(sDropdownActionName))) {
                // Send an action to the parent of this component
                this.get(sDropdownActionName)(sDropdownContentId);
            }
        }
    });
});