define("a24-ember-staffshift-core/services/user-session", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({

        objAuthData: null, //accessToken, refreshToken, context name/avatar/type/id, sSocialImageUrl
        objContextDetails: null, //This is details about the current context like sTimezone, sCountryId, sLocaleId
        objGeneralDetails: null, // This is used to set general details not available on context or auth
        arrMenuItems: null, //The menu structure for the context
        objRestDetails: null, //This holds things such as the bearer and context concatenated values. e.g "Bearer 42b6018b..."

        //This flag is here to stop child route from doing another auth check on initial load, this will currently only
        //work one level deep and will need to be redone if we ever have deeper nesting
        bSkipNextAuthCall: false,

        _funcValidAuth: null, //Function to fire when user has valid auth and context details
        _funcInValidAuth: null, //Function to call when user has invalid auth or could not get user context details
        _objRestUserContext: Ember.inject.service("rest-user-context"),
        _objMenuService: Ember.inject.service("triage-menu"),
        _objCheckAuthService: Ember.inject.service("rest-checkauth"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        _objCheckAuthProperty: null,
        _objContextCallProperty: null,

        //Hold all the service calls that need to be aborted when the doCheckAuth start again
        _objCheckAuthCall: null,
        _objContextCall: null,
        _objMenuCall: null,
        _objUserContextsCall: null,
        _objUserContextsSetCall: null,
        init: function () {
            this._super(...arguments);
            this.set("objRestDetails", {
                sBearer: "",
                sContext: ""
            });
            if (this.get("arrMenuItems") == null) {
                this.set("arrMenuItems", []);
            }
        },
        doCheckAuth: function (funcValidAuth, funcInValidAuth, sContext, sCID, bReloadContextData) {
            this.set("_funcValidAuth", funcValidAuth);
            this.set("_funcInValidAuth", funcInValidAuth);

            if (!a24Core.isEmpty(this.get("_objCheckAuthCall")) && typeof (this.get("_objCheckAuthCall.abort") === "function")) {
                this.get("_objCheckAuthCall").abort();
            }
            if (!a24Core.isEmpty(this.get("_objContextCall")) && typeof (this.get("_objContextCall.abort") === "function")) {
                this.get("_objContextCall").abort();
            }
            if (!a24Core.isEmpty(this.get("_objMenuCall")) && typeof (this.get("_objMenuCall.abort") === "function")) {
                this.get("_objMenuCall").abort();
            }
            if (!a24Core.isEmpty(this.get("_objUserContextsCall")) && typeof (this.get("_objUserContextsCall.abort") === "function")) {
                this.get("_objUserContextsCall").abort();
            }
            if (!a24Core.isEmpty(this.get("_objUserContextsSetCall")) && typeof (this.get("_objUserContextsSetCall.abort") === "function")) {
                this.get("_objUserContextsSetCall").abort();
            }

            var objAuthCall = this.get("_objCheckAuthService").getCheckAuth(this, "_objCheckAuthProperty", objData => {
                // Check if there is a logged in user
                if (objData.code !== 1) {
                    this.set("objAuthData", null);
                    this._doFinish(false, true);
                } else {
                    var sAuthToken = this.get("objAuthData.accessToken");
                    //Only set the auth details when there is a valid logged in user
                    this.set("objAuthData", objData);

                    //We will reload the context data when context changes or when the logged in user changes
                    //This can happen when the user duplicates tabs and login with a different account in the one and
                    //then start navigating other existing menu
                    if (bReloadContextData || objData.accessToken !== sAuthToken) {
                        //Clear any previous details
                        this._resetContextDetails();

                        //Get the needed context details
                        this._getLoggedInUserDetails(sContext, sCID, objData.accessToken);
                    } else {
                        this._doFinish(true, false);
                    }
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], () => /*sCode, sStatus*/{
                this._doFinish(false, false);
            }));
            this.set("_objCheckAuthCall", objAuthCall);
        },
        _resetContextDetails: function () {
            this.set("objContextDetails", null);
            this.set("objGeneralDetails", null);
            this.set("arrMenuItems", []);
            this.set("objRestDetails.sBearer", "");
            this.set("objRestDetails.sContext", "");
        },
        _doFinish: function (bSuccess, bInvalidAuth) {
            var funcToRun = null;
            if (bSuccess) {
                funcToRun = this.get("_funcValidAuth");
            } else {
                funcToRun = this.get("_funcInValidAuth");

                //Clear all the context data
                this.set("objAuthData", null);
                this.set("objContextDetails", null);
                this.set("arrMenuItems", []);
                this.set("objRestDetails.sBearer", "");
                this.set("objRestDetails.sContext", "");
            }

            //Clear the callback functions
            this.set("_funcValidAuth", null);
            this.set("_funcInValidAuth", null);

            if (!a24Core.isEmpty(funcToRun)) {
                funcToRun(bInvalidAuth);
            }
        },
        _getLoggedInUserDetails: function (sResource, sResourceId, sAccessToken) {

            var sAuthResource = this.get("objAuthData.context.objReferenceId.sResource");
            var sAuthResourceId = this.get("objAuthData.context.objReferenceId.mId");

            if (sAuthResource === "User") {
                sAuthResource = "system-admin";
            }
            var bShouldSetContext = false;
            if (sResource.replace("-", "").toLowerCase() === sAuthResource.replace("-", "").toLowerCase()) {
                if (sResource !== "system-admin" && sResource !== "candidate") {
                    // Resources match, and it is Agency, Org or AgencyGroup
                    if (sResourceId !== sAuthResourceId) {
                        // These are not the same resources
                        bShouldSetContext = true;
                    }
                }
            } else {
                bShouldSetContext = true;
            }

            var sContext = "";

            if (sResource === "system-admin") {
                sContext = this.get("_objConstantsSSCore").CONTEXT_SYSTEM_ADMIN;
            } else if (sResource === "agency") {
                sContext = this.get("_objConstantsSSCore").CONTEXT_AGENCY + " " + sResourceId;
            } else if (sResource === "agency-group") {
                sContext = this.get("_objConstantsSSCore").CONTEXT_AGENCY_GROUP + " " + sResourceId;
            } else if (sResource === "organisation") {
                sContext = this.get("_objConstantsSSCore").CONTEXT_ORGANISATION + " " + sResourceId;
            } else if (sResource === "candidate") {
                sContext = this.get("_objConstantsSSCore").CONTEXT_CANDIDATE;
            }

            //Set the bearer and sCID for further rest calls
            this.set("objRestDetails.sBearer", "Bearer " + sAccessToken);
            this.set("objRestDetails.sContext", sContext);

            if (!bShouldSetContext // If we have the correct context
            ) {
                    this.set("objGeneralDetails", {
                        sResourceId: sResourceId,
                        sResourceType: sResource,
                        sAvatarUrl: this.get("objAuthData.context.sAvatarUrl") // Set avatar for general use
                    });

                    var objUserContextDetailsPromise = this._getUserContextDetails(sResource, sResourceId);
                    var objMenuItemsPromise = this._getMenuItems();

                    a24RSVP.configure(this, {
                        _objRestKeyUserContext: objUserContextDetailsPromise,
                        _objRestKeyMenu: objMenuItemsPromise
                    }, [{
                        arrRequiredResponses: ["_objRestKeyUserContext", "_objRestKeyMenu"],
                        funcOnComplete: objResponses => {

                            if (a24Core.isEmpty(objResponses._objRestKeyUserContext.objResponse) || a24Core.isEmpty(objResponses._objRestKeyMenu.objResponse)) {
                                //if all do NOT succeed, this is to cater for the 404 from the contract expense
                                this._doFinish(false, false);
                            } else {
                                //if all succeed
                                this._doFinish(true, false);
                            }
                        }
                    }]);
                } else {
                this._setContextAndCallDoAuth(sResource, sResourceId);
            }
        },
        _setContextAndCallDoAuth: function (sResource, sResourceId) {

            var objUserContextsCall = this.get("_objRestUserContext").getList(this, this.get("objAuthData.context.sUserId"), arrData => {
                // At least one context will be set
                var sCID = null;
                for (var i = 0; i < arrData.length; i++) {
                    /**
                     * If the context is system-admin or candidate we use the id of the reference object,
                     * If the context is any other, we look to see if the mId matches the
                     * resource id and only set the id once we have a match
                     */
                    if (sResource.toLowerCase() === "system-admin" && arrData[i].objReferenceId.sResource === "User") {
                        sCID = arrData[i].id;
                        break;
                    } else if (sResource.toLowerCase() === "candidate" && arrData[i].objReferenceId.sResource === "Candidate") {
                        sCID = arrData[i].id;
                        break;
                    } else if (sResource.toLowerCase() !== "candidate" && sResource.toLowerCase() !== "system-admin") {
                        if (arrData[i].objReferenceId.mId === sResourceId) {
                            sCID = arrData[i].id;
                            break;
                        }
                    }
                }

                if (a24Core.isEmpty(sCID)) {
                    this._doFinish(false, false);
                } else {
                    var objUserContextsSetCall = this.get("_objRestUserContext").setSelectedContext(this, sCID, () => {
                        this.doCheckAuth(this.get("_funcValidAuth"), this.get("_funcInValidAuth"), sResource, sResourceId, true);
                    }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], () => /*sCode, sStatus*/{
                        this._doFinish(false, false);
                    }));
                    this.set("_objUserContextsSetCall", objUserContextsSetCall);
                }
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], () => /*sCode, sStatus*/{
                this._doFinish(false, false);
            }));
            this.set("_objUserContextsCall", objUserContextsCall);
        },
        _getUserContextDetails: function (sResource, sResourceId) {

            var objContextCall = this.get("_objRestUserContext").getUserContextDetails(this, sResource, sResourceId, objContextDetails => {
                //Set the context details and let RSVP handle rest
                this.set("objContextDetails", objContextDetails);
            }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], () => /*sCode, sStatus*/{
                //RSVP will handle any failures
            }));
            this.set("_objContextCall", objContextCall);
            return objContextCall;
        },
        _getMenuItems: function () {
            var objMenuCall = this.get("_objMenuService").getMenuItems();
            this.set("_objMenuCall", objMenuCall);
            return objMenuCall;
        },
        /**
         * Get the users first name
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  23 May 2016
         *
         * @returns the users first name
         */
        getUserFirstName: function () {
            var objAuthData = this.get("objAuthData");
            if (!a24Core.isEmpty(objAuthData) && !a24Core.isEmpty(objAuthData.identity) && !a24Core.isEmpty(objAuthData.identity.sFirstName)) {
                return objAuthData.identity.sFirstName;
            } else {
                return null;
            }
        },
        /**
         * Get the users last name
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  8 Aug 2017
         *
         * @returns the users last name
         */
        getUserLastName: function () {
            var objAuthData = this.get("objAuthData");
            if (!a24Core.isEmpty(objAuthData) && !a24Core.isEmpty(objAuthData.identity) && !a24Core.isEmpty(objAuthData.identity.sLastName)) {
                return objAuthData.identity.sLastName;
            } else {
                return null;
            }
        },
        /**
         * Get the users first and last name
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  8 Aug 2017
         *
         * @returns the users full name
         */
        getUserFullName: function () {
            return this.getUserFirstName() + " " + this.getUserLastName();
        },
        /**
         * Get the users email address
         *
         * @author Deon De Wet <deon.dewet@a24group.com>
         * @since  8 Aug 2017
         *
         * @returns the users email
         */
        getUserEmail: function () {
            var objAuthData = this.get("objAuthData");
            if (!a24Core.isEmpty(objAuthData) && !a24Core.isEmpty(objAuthData.identity) && !a24Core.isEmpty(objAuthData.identity.sEmailAddress)) {
                return objAuthData.identity.sEmailAddress;
            } else {
                return null;
            }
        },
        /**
         * Get the users avatar
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  23 May 2016
         *
         * @returns the users avatar url
         */
        getUserAvatar: function () {
            var objAuthData = this.get("objAuthData");
            if (!a24Core.isEmpty(objAuthData) && !a24Core.isEmpty(objAuthData.context) && !a24Core.isEmpty(objAuthData.context.sAvatarUrl)) {
                return objAuthData.context.sAvatarUrl;
            } else {
                return null;
            }
        },
        /**
         * Get the user context reference object
         *
         * @author Michael Barnard <michael.barnard@a24group.com>
         * @since  1 July 2016
         *
         * @returns The context reference object
         */
        getUserContextReference: function () {
            var objAuthData = this.get("objAuthData");
            return objAuthData.context.objReferenceId;
        }
    });
});