define("a24-ember-lib/components/card-datagrid-filter", ["exports", "a24-ember-lib/templates/components/card-datagrid-filter", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _cardDatagridFilter, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _cardDatagridFilter.default,
        sDatagridHeader: null,
        arrHeaderConfig: null,
        objDatagridConfig: null,
        bHasFilterApplied: false,
        bShowShareButton: false,
        bShareableLink: false,
        bShowColumnToggles: false,
        arrCustomActions: null,
        _objStringsService: Ember.inject.service("a24-strings"),
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        _objQueryParamObject: null,
        _sFilterButtonText: null,
        _sFilterHeaderText: null,
        _sFilterOptionPrompt: null,
        _sEmptyOnlyText: null,
        _sFilterAddButtonText: null,
        _sFilterDateFrom: null,
        _sFilterDateTo: null,
        _sIsLabel: null,
        _sIsInLabel: null,
        _sFilterOptionsButtonId: null,
        _sFilterOptionsDropdownId: null,
        _sFilterOptionsContainerId: null,
        _sFilterOptionsTextId: null,
        _sFilterOptionsPopupId: null,
        _bDisplayFilterChoices: false,
        _objSelectedFilterOption: null,
        _bEmptyEntriesOnly: false,
        _bTextFilter: false,
        _bBoolFilter: false,
        _bDropDownFilter: false,
        _bDropDownMultiFilter: false,
        _bDateFilter: false,
        _arrDateFilterData: null,
        _sFromDate: "",
        _sToDate: "",
        _bCheckForCustomDate: true,
        _sFilterValue: null,
        _objFilterValue: null,
        _arrFilterValue: null,
        _sFilterInputPlaceHolder: null,
        _arrFilterInputArray: null,
        _bDisplayAddButton: false,
        _sTagButtonStyle: ".chip",
        _objUserSession: Ember.inject.service("user-session"),
        _funcFilterValuesObserverCallback: function () {
            Ember.run.once(this, this.setHeaderQueryParamObjects);
        },
        _setDateFilterFields: Ember.observer("_objFilterValue", function () {
            if (this.get("_bDateFilter")) {
                var sDateOption = this.get("_objFilterValue").sValue;
                this.set("_sFilterValue", sDateOption);
                var sFromDate = "";
                var sToDate = "";

                //This code can be moved out to a different function later if we want to reuse it. For now it will be
                //kept in this component
                var objCurrentDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                if ("dateFilterToday" === sDateOption) {
                    sFromDate = objCurrentDate.getDateFormat();
                    sToDate = sFromDate;
                } else if ("dateFilterYesterday" === sDateOption) {
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 1);
                    sFromDate = objCurrentDate.getDateFormat();
                    sToDate = sFromDate;
                } else if ("dateFilterThisWeekToday" === sDateOption) {
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterThisWeekToFri" === sDateOption) {
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sFromDate = objCurrentDate.getDateFormat();
                    objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 2);
                    sToDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterThisWeekToSun" === sDateOption) {
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sFromDate = objCurrentDate.getDateFormat();
                    objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sToDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterLast7Days" === sDateOption) {
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 7);
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterLastWeekToFri" === sDateOption) {
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_WEEKS"), 1);
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sFromDate = objCurrentDate.getDateFormat();
                    objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 2);
                    sToDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterLastWeekToSun" === sDateOption) {
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_WEEKS"), 1);
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sFromDate = objCurrentDate.getDateFormat();
                    objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_ISO_WEEK"));
                    sToDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterLast14Days" === sDateOption) {
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 14);
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterThisMonthToToday" === sDateOption) {
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterThisMonthToEnd" === sDateOption) {
                    objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterLast30Days" === sDateOption) {
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_DAYS"), 30);
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterLastMonth" === sDateOption) {
                    objCurrentDate.subtractTimeFromDate(this.get("_objLibConstants.MOMENT_TIME_MONTHS"), 1);
                    objCurrentDate.endOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                    sToDate = objCurrentDate.getDateFormat();
                    objCurrentDate.startOf(this.get("_objLibConstants.MOMENT_UNIT_MONTH"));
                    sFromDate = objCurrentDate.getDateFormat();
                } else if ("dateFilterCustomRange" === sDateOption) {
                    //We do not want to change the dates since the user could be altering a previous choice, so do nothing
                    //and return
                    return;
                }

                this.set("_bCheckForCustomDate", false);
                this.set("_sFromDate", sFromDate);
                this.set("_sToDate", sToDate);
            } else if (this.get("_bBoolFilter") || this.get("_bDropDownFilter")) {
                this.set("_sFilterValue", this.get("_objFilterValue").sValue);
            }
        }),
        _setMultiSelectFilterFields: Ember.observer("_arrFilterValue", function () {
            if (this.get("_bDropDownMultiFilter")) {
                this._setDisplayAddButton();
                var arrItems = this.get("_arrFilterValue");
                var arrStatuses = [];
                if (!a24Core.isEmpty(arrItems)) {
                    for (var i = 0; i < arrItems.length; i++) {
                        arrStatuses.push(arrItems[i].sValue);
                    }
                }
                var sQuery = arrStatuses.join("|");
                if (a24Core.isEmpty(sQuery.trim())) {
                    sQuery = null;
                }
                this.set("_sFilterValue", sQuery);
            }
        }),
        _setDisplayAddButton: Ember.observer("_sFilterValue", "_bEmptyEntriesOnly", "_sFromDate", "_sToDate", function () {
            //We only display the add filter button if the empty entries only checkbox is selected or if we have
            //a value for the filter. In the date filter case we check that either to or from has a value
            if (this.get("_bDateFilter")) {
                if (this.get("_bEmptyEntriesOnly") || !a24Core.isEmpty(this.get("_sFromDate")) || !a24Core.isEmpty(this.get("_sToDate"))) {
                    this.set("_bDisplayAddButton", true);
                    return;
                }
            } else if (this.get("_bDropDownMultiFilter")) {
                var arrItems = this.get("_arrFilterValue");
                var bShowButton = arrItems.length > 0;
                for (var i = 0; i < arrItems.length; i++) {
                    if (a24Core.isEmpty(arrItems[i])) {
                        bShowButton = false;
                        break;
                    }
                }
                if (bShowButton || this.get("_bEmptyEntriesOnly")) {
                    this.set("_bDisplayAddButton", true);
                    return;
                }
            } else {
                if (this.get("_bEmptyEntriesOnly") || !a24Core.isEmpty(this.get("_sFilterValue"))) {
                    this.set("_bDisplayAddButton", true);
                    return;
                }
            }
            this.set("_bDisplayAddButton", false);
        }),
        _setFilterInput: Ember.observer("_objSelectedFilterOption", function () {
            //Rest the filter value when the selected filter option changes.
            this.resetFilterBools();
            var objSelectedFilterOption = this.get("_objSelectedFilterOption");

            //If null if means that the filter is closed then don't set any filter things
            if (!a24Core.isEmpty(objSelectedFilterOption)) {
                //Set the correct bool flag based on the filter type
                //You might note that we use a sFilterType for the type and not send in bBool, bText etc like on the card
                //datagrid table this is because we could potentially have different filters for the same column type e.g
                //date could be a dropdown filter or a datepicker filter. Having the components share the bool flags would
                //complicate things later.
                var sFilterType = objSelectedFilterOption.sFilterType;
                if ("text" === sFilterType) {
                    this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("enterValue") + "...");
                    this.set("_bTextFilter", true);
                    this.set("_bHideEmptyCheck", objSelectedFilterOption.bHideEmptyCheck);
                } else if ("bool" === sFilterType) {
                    this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("selectValue") + "...");
                    this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                    this.set("_bBoolFilter", true);
                } else if ("dropdown" === sFilterType) {
                    this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("selectValue") + "...");
                    this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                    this.set("_bDropDownFilter", true);
                    this.set("_bHideEmptyCheck", objSelectedFilterOption.bHideEmptyCheck);
                } else if ("dropdownMulti" === sFilterType) {
                    this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("selectValue") + "...");
                    this.set("_arrFilterInputArray", objSelectedFilterOption.arrMappingData);
                    this.set("_bDropDownMultiFilter", true);
                    this._setDisplayAddButton();
                } else if ("date" === sFilterType) {
                    this.set("_sFilterInputPlaceHolder", this.get("_objStringsService").getString("selectValue") + "...");
                    this.set("_arrFilterInputArray", this.get("_arrDateFilterData"));
                    this.set("_bDateFilter", true);
                }

                var objThis = this;

                //One next to allow time to draw a dropdown if filter has one
                Ember.run.next(() => {
                    //Another next to first let mdb finish initing a dropdown if filter has one
                    Ember.run.next(() => {
                        //Now we should be all good to focus any input that was rendered for the filter option
                        Ember.run.schedule("afterRender", () => {
                            //Add enter event listener to filter option input and also focus field if it
                            //exists

                            var objOptionsInputItem = this.$("#" + this.get("_sFilterOptionsContainerId") + " input");
                            if (!a24Core.isEmpty(objOptionsInputItem)) {
                                //Add enter key listener to input
                                objOptionsInputItem.on("keydown", function (objEvent) {
                                    if (objEvent.keyCode === 13 && objThis.get("_bDisplayAddButton") && a24Core.isEmpty($(objThis.$(".multiple-select-dropdown.active")))) {
                                        //Because this is inside jQuery callback add it in Ember run
                                        Ember.run(() => {
                                            objThis.addFilter();
                                        });
                                    }
                                });

                                //Focus the input
                                objOptionsInputItem.first().focus();
                            }
                        });
                    });
                });
            }
        }),
        init: function () {
            this._super(...arguments);

            this.set("_sFilterButtonText", this.get("_objStringsService").getString("filterResults"));
            this.set("_sFilterHeaderText", this.get("_objStringsService").getString("showAllResultsWhere") + ":");
            this.set("_sFilterOptionPrompt", this.get("_objStringsService").getString("selectFilter") + "...");
            this.set("_sEmptyOnlyText", this.get("_objStringsService").getString("emptyEntries"));
            this.set("_sFilterAddButtonText", this.get("_objStringsService").getString("addFilter"));
            this.set("_sFilterDateFrom", this.get("_objStringsService").getString("from") + ":");
            this.set("_sFilterDateTo", this.get("_objStringsService").getString("to") + ":");
            this.set("_sIsLabel", this.get("_objStringsService").getString("is") + ":");
            this.set("_sIsInLabel", this.get("_objStringsService").getString("isIn") + ":");

            var arrDateFilterData = [{
                sTitle: this.get("_objStringsService").getString("dateFilterToday"),
                sValue: "dateFilterToday"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterYesterday"),
                sValue: "dateFilterYesterday"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterThisWeekToday"),
                sValue: "dateFilterThisWeekToday"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterThisWeekToFri"),
                sValue: "dateFilterThisWeekToFri"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterThisWeekToSun"),
                sValue: "dateFilterThisWeekToSun"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterLast7Days"),
                sValue: "dateFilterLast7Days"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterLastWeekToFri"),
                sValue: "dateFilterLastWeekToFri"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterLastWeekToSun"),
                sValue: "dateFilterLastWeekToSun"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterLast14Days"),
                sValue: "dateFilterLast14Days"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterThisMonthToToday"),
                sValue: "dateFilterThisMonthToToday"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterThisMonthToEnd"),
                sValue: "dateFilterThisMonthToEnd"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterLast30Days"),
                sValue: "dateFilterLast30Days"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterLastMonth"),
                sValue: "dateFilterLastMonth"
            }, {
                sTitle: this.get("_objStringsService").getString("dateFilterCustomRange"),
                sValue: "dateFilterCustomRange"
            }];
            this.set("_arrDateFilterData", arrDateFilterData);

            this.set("_sFilterOptionsButtonId", Ember.guidFor({}));
            this.set("_sFilterOptionsDropdownId", Ember.guidFor({}));
            this.set("_sFilterOptionsContainerId", Ember.guidFor({}));
            this.set("_sFilterOptionsTextId", Ember.guidFor({}));
            this.set("_sFilterOptionsPopupId", Ember.guidFor({}));

            if (a24Core.isEmpty(this.get("_arrFilterValue"))) {
                this.set("_arrFilterValue", []);
            }
            if (a24Core.isEmpty(this.get("_arrFilterInputArray"))) {
                this.set("_arrFilterInputArray", []);
            }

            //Set _objQueryParamObject to empty object if empty.
            if (a24Core.isEmpty(this.get("_objQueryParamObject"))) {
                this.set("_objQueryParamObject", {});
            }

            //Set up the initial _objQueryParamObject
            var arrHeaderConfig = this.get("arrHeaderConfig");
            if (!a24Core.isEmpty(arrHeaderConfig)) {
                for (var i = 0; i < arrHeaderConfig.length; i++) {
                    if (!a24Core.isEmpty(arrHeaderConfig[i].sFilterValue)) {
                        this.setHeaderQueryParamObject(arrHeaderConfig[i]);
                    }
                }
            }
            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));

            if (!a24Core.isEmpty(this.get("cardDatagridFilterInitEvent"))) {
                this.get("cardDatagridFilterInitEvent")(Ember.copy(this.get("_objQueryParamObject"), true));
            }
        },
        setHeaderQueryParamObjects: function () {
            var objQueryParamObject = this.get("_objQueryParamObject");
            var arrHeaderConfig = this.get("arrHeaderConfig");

            var bHasFilterApplied = false;
            if (!a24Core.isEmpty(arrHeaderConfig)) {
                for (var i = 0; i < arrHeaderConfig.length; i++) {
                    if (a24Core.isEmpty(arrHeaderConfig[i].sFilterValue)) {
                        delete objQueryParamObject[arrHeaderConfig[i].sProperty];
                    } else {
                        this.setHeaderQueryParamObject(arrHeaderConfig[i]);
                        bHasFilterApplied = true;
                    }
                }
            }
            this.set("bHasFilterApplied", bHasFilterApplied);

            //Let parent know the filters changed
            if (!a24Core.isEmpty(this.get("cardDatagridFilterParamChangeEvent"))) {
                this.get("cardDatagridFilterParamChangeEvent")(Ember.copy(this.get("_objQueryParamObject"), true));
            }
        },
        setCustomDataChoiceObserver: Ember.observer("_sFromDate", "_sToDate", function () {
            Ember.run.once(this, this.setCustomDataChoice);
        }),
        setCustomDataChoice: function () {
            //If empty we know that the filter is closed.
            if (!a24Core.isEmpty(this.get("_objFilterValue"))) {
                var sDateOption = this.get("_objFilterValue").sValue;
                if (this.get("_bCheckForCustomDate") && this.get("_bDateFilter") && !a24Core.isEmpty(sDateOption) && "dateFilterCustomRange" !== sDateOption) {
                    this.set("_objFilterValue", this.get("_arrDateFilterData." + (this.get("_arrDateFilterData").length - 1)));
                } else {
                    this.set("_bCheckForCustomDate", true);
                }
            }
        },
        resetFilterBools: function () {
            //Clear all the variables used and share in the filter options popup
            this.set("_bTextFilter", false);
            this.set("_bBoolFilter", false);
            this.set("_bDropDownFilter", false);
            this.set("_bDropDownMultiFilter", false);
            this.set("_bDateFilter", false);
            this.set("_sFilterValue", null);
            this.set("_objFilterValue", null);
            this.set("_sFilterInputPlaceHolder", null);
            this.set("_arrFilterInputArray", []);
            this.set("_arrFilterValue", []);
            this.set("_bEmptyEntriesOnly", false);
            this.set("_sFromDate", "");
            this.set("_sToDate", "");
            this.set("_bHideEmptyCheck", false);
        },
        addFilter: function () {
            this.removeObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));

            var objSelectedFilterOption = this.get("_objSelectedFilterOption");

            var sHeaderFilterValue = null;
            if (this.get("_bEmptyEntriesOnly")) {
                sHeaderFilterValue = "null";
            } else {
                sHeaderFilterValue = this.get("_sFilterValue");
            }
            Ember.set(objSelectedFilterOption, "sFilterValue", sHeaderFilterValue);

            //If date filter set the 2 extra properties
            if ("date" === objSelectedFilterOption.sFilterType) {
                if (sHeaderFilterValue === "null") {
                    Ember.set(objSelectedFilterOption, "sFilterFromValue", null);
                    Ember.set(objSelectedFilterOption, "sFilterToValue", null);
                } else {
                    Ember.set(objSelectedFilterOption, "sFilterFromValue", this.get("_sFromDate"));
                    Ember.set(objSelectedFilterOption, "sFilterToValue", this.get("_sToDate"));
                }
            }

            this.setHeaderQueryParamObject(objSelectedFilterOption);

            this.resetFilterBools();
            this.set("_objSelectedFilterOption", null);
            this.set("_bDisplayFilterChoices", false);

            //Let parent know the filters changed
            if (!a24Core.isEmpty(this.get("cardDatagridFilterChangeEvent"))) {
                this.get("cardDatagridFilterChangeEvent")(objSelectedFilterOption.sProperty, Ember.copy(this.get("_objQueryParamObject"), true));
            }

            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
        },
        setHeaderQueryParamObject: function (objHeaderItem) {
            //Build up the filter query param based on the selected filter option. Also build up tag value for item.
            var objQueryObject = {};
            var sHeaderFilterTag = null;
            var mHeaderFilterValue = objHeaderItem.sFilterValue;
            if (mHeaderFilterValue === "null") {
                sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("isEmpty").toLowerCase();
            } else {
                if ("text" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    objQueryObject.bLike = true;
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("contains").toLowerCase() + " " + mHeaderFilterValue;
                } else if ("bool" === objHeaderItem.sFilterType) {
                    var sTagValue = null;
                    for (var j = 0; j < objHeaderItem.arrMappingData.length; j++) {
                        if (objHeaderItem.arrMappingData[j].sValue === mHeaderFilterValue) {
                            sTagValue = objHeaderItem.arrMappingData[j].sTitle;
                            break;
                        }
                    }
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + sTagValue;
                    mHeaderFilterValue = mHeaderFilterValue === "true";
                } else if ("dropdown" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    var sDropdownTagValue = null;
                    for (var k = 0; k < objHeaderItem.arrMappingData.length; k++) {
                        if (objHeaderItem.arrMappingData[k].sValue === mHeaderFilterValue) {
                            sDropdownTagValue = objHeaderItem.arrMappingData[k].sTitle;
                            break;
                        }
                    }
                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + sDropdownTagValue;
                } else if ("dropdownMulti" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    objQueryObject.bDelimited = true;

                    var sDropMultiTagValue = null;

                    var arrFilterItems = mHeaderFilterValue.split("|");
                    if (arrFilterItems.length === 1) {
                        for (var i = 0; i < objHeaderItem.arrMappingData.length; i++) {
                            if (objHeaderItem.arrMappingData[i].sValue === arrFilterItems[0]) {
                                sDropMultiTagValue = objHeaderItem.arrMappingData[i].sTitle;
                                break;
                            }
                        }
                    } else {
                        sDropMultiTagValue = this.get("_objStringsService").getString("multiple");
                    }

                    sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("is").toLowerCase() + " " + sDropMultiTagValue;
                } else if ("date" === objHeaderItem.sFilterType) {
                    objQueryObject.bEncode = true;
                    if (!a24Core.isEmpty(objHeaderItem.sFilterFromValue) && !a24Core.isEmpty(objHeaderItem.sFilterToValue)) {
                        //If both to and from dates have value we need to create a bFromAndTo query, so we set the
                        //from date time to the beginning of that day and the to date time to the end of that day.
                        objQueryObject.bFromAndTo = true;
                        var objFromAndTo = {};
                        var objSelectedFromDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        objSelectedFromDate.setDateFromBrowser(objHeaderItem.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objSelectedFromDate.setTimeToStartOfDay();
                        if (objHeaderItem.bTimeless === true) {
                            objFromAndTo.sFrom = objSelectedFromDate.getDateFormat();
                        } else {
                            objFromAndTo.sFrom = objSelectedFromDate.getDateStringForRest();
                        }

                        objSelectedFromDate.setDateFromBrowser(objHeaderItem.sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objSelectedFromDate.setTimeToEndOfDay();
                        if (objHeaderItem.bTimeless === true) {
                            objFromAndTo.sTo = objSelectedFromDate.getDateFormat();
                        } else {
                            objFromAndTo.sTo = objSelectedFromDate.getDateStringForRest();
                        }

                        mHeaderFilterValue = objFromAndTo;

                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("in").toLowerCase() + " " + this.get("_objStringsService").getString(objHeaderItem.sFilterValue);
                    } else if (!a24Core.isEmpty(objHeaderItem.sFilterFromValue)) {
                        //If only the from date has a value then we set the time to the beginning of that day and create a
                        //bFrom query
                        objQueryObject.bFrom = true;
                        var objSelectedToDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        objSelectedToDate.setDateFromBrowser(objHeaderItem.sFilterFromValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objSelectedToDate.setTimeToStartOfDay();
                        if (objHeaderItem.bTimeless === true) {
                            mHeaderFilterValue = objSelectedToDate.getDateFormat();
                        } else {
                            mHeaderFilterValue = objSelectedToDate.getDateStringForRest();
                        }

                        //For now we make this the same as custom range, might revert or add a range part in future
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("in").toLowerCase() + " " + this.get("_objStringsService").getString(objHeaderItem.sFilterValue);
                    } else if (!a24Core.isEmpty(objHeaderItem.sFilterToValue)) {
                        //If only the to date has a value then we set the time to the end of that day and create a
                        //bTo query
                        objQueryObject.bTo = true;
                        var objSelectedDate = a24DateManager.createDate(this.get("_objUserSession.objContextDetails.sTimezone"));
                        objSelectedDate.setDateFromBrowser(objHeaderItem.sFilterToValue, this.get("_objLibConstants.DATE_FORMAT"));
                        objSelectedDate.setTimeToEndOfDay();

                        if (objHeaderItem.bTimeless === true) {
                            mHeaderFilterValue = objSelectedDate.getDateFormat();
                        } else {
                            mHeaderFilterValue = objSelectedDate.getDateStringForRest();
                        }

                        //For now we make this the same as custom range, might revert or add a range part in future
                        sHeaderFilterTag = objHeaderItem.sTitle + " " + this.get("_objStringsService").getString("in").toLowerCase() + " " + this.get("_objStringsService").getString(objHeaderItem.sFilterValue);
                    }
                }
            }
            objQueryObject.mValue = mHeaderFilterValue;
            objQueryObject.bSubProperty = objHeaderItem.bSubProperty;
            if (objQueryObject.bSubProperty) {
                objQueryObject.sName = objHeaderItem.sName;
            }

            this.set("_objQueryParamObject." + objHeaderItem.sProperty, objQueryObject);
            Ember.set(objHeaderItem, "sTagValue", sHeaderFilterTag);
        },
        removeFilter: function (sProperty) {
            this.removeObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
            var arrHeaderConfig = this.get("arrHeaderConfig");
            var objQueryParamObject = this.get("_objQueryParamObject");

            //Loop over the header, find the one to remove and reset the filter and tag value.
            for (var i = 0; i < arrHeaderConfig.length; i++) {
                if (arrHeaderConfig[i].sProperty === sProperty) {
                    this.set("arrHeaderConfig." + i + ".sFilterValue", null);
                    this.set("arrHeaderConfig." + i + ".sTagValue", null);

                    if ("date" === arrHeaderConfig[i].sFilterType) {
                        this.set("arrHeaderConfig." + i + ".sFilterFromValue", null);
                        this.set("arrHeaderConfig." + i + ".sFilterToValue", null);
                    }

                    delete objQueryParamObject[sProperty];
                    break;
                }
            }

            //Let parent know the filters changed
            if (!a24Core.isEmpty(this.get("cardDatagridFilterChangeEvent"))) {
                this.get("cardDatagridFilterChangeEvent")(sProperty, Ember.copy(objQueryParamObject, true));
            }

            this.addObserver("arrHeaderConfig.@each.sFilterValue", this, this.get("_funcFilterValuesObserverCallback"));
        },
        filterOptionButtonClick: function (bAutoFocus) {
            this.resetFilterBools();
            this.set("_objSelectedFilterOption", null);
            this.set("_bDisplayFilterChoices", true);

            var objCurrentController = this;

            //Here we do a next since we first need the DOM to draw the filter options popup before we can access
            //the first dropdown to focus it(seems it can only be focused in a ember next, might have to do with how
            //mdb inits the dropdown) and also override the click on the popup so that it does not close the
            //popup when click happens on itself.
            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    var objOptionsDropdown = objCurrentController.$("#" + objCurrentController.get("_sFilterOptionsDropdownId") + " input");
                    if (objCurrentController.get("_bDisplayFilterChoices") && !a24Core.isEmpty(objOptionsDropdown) && bAutoFocus) {
                        objOptionsDropdown.first().focus();
                    }
                });
            });
        },
        actions: {
            filterOptionsClicked: function () {
                this.filterOptionButtonClick(true);
            },
            filterTagClicked: function (objFilterItem) {
                this.filterOptionButtonClick(false);
                this.set("_objSelectedFilterOption", objFilterItem);
                if ("null" === objFilterItem.sFilterValue) {
                    this.set("_sFilterValue", "");
                    this.set("_bEmptyEntriesOnly", true);
                } else {
                    this.set("_sFilterValue", objFilterItem.sFilterValue);
                }
                if ("date" === objFilterItem.sFilterType) {
                    this.set("_sFromDate", objFilterItem.sFilterFromValue);
                    this.set("_sToDate", objFilterItem.sFilterToValue);
                    var arrDateMappings = this.get("_arrDateFilterData");
                    var objDateFilterValue = null;
                    for (var l = 0; l < arrDateMappings.length; l++) {
                        if (arrDateMappings[l].sValue === objFilterItem.sFilterValue) {
                            objDateFilterValue = arrDateMappings[l];
                            break;
                        }
                    }
                    this.set("_objFilterValue", objDateFilterValue);
                }
                if ("bool" === objFilterItem.sFilterType || "dropdown" === objFilterItem.sFilterType) {
                    var arrMappings = objFilterItem.arrMappingData;
                    var objFilterValue = null;
                    for (var k = 0; k < arrMappings.length; k++) {
                        if (arrMappings[k].sValue === objFilterItem.sFilterValue) {
                            objFilterValue = arrMappings[k];
                            break;
                        }
                    }
                    this.set("_objFilterValue", objFilterValue);
                }
                if ("dropdownMulti" === objFilterItem.sFilterType) {
                    var arrSelectedItems = [];
                    if (!a24Core.isEmpty(objFilterItem.sFilterValue)) {
                        var arrItems = objFilterItem.sFilterValue.split("|");
                        var arrMappingData = objFilterItem.arrMappingData;
                        for (var i = 0; i < arrItems.length; i++) {
                            for (var j = 0; j < arrMappingData.length; j++) {
                                if (arrMappingData[j].sValue === arrItems[i]) {
                                    arrSelectedItems.push(arrMappingData[j]);
                                    break;
                                }
                            }
                        }
                    }
                    this.set("_arrFilterValue", arrSelectedItems);
                }
            },
            addFilterAction: function () {
                this.addFilter();
            },
            removeFilterAction: function (sProperty) {
                this.removeFilter(sProperty);
            },
            closeFilterDropdown: function () {
                var objMultiDropDown = $(this.$(".multiple-select-dropdown.active"));

                if (!a24Core.isEmpty(objMultiDropDown)) {
                    objMultiDropDown.trigger("close");
                } else {
                    this.set("_bDisplayFilterChoices", false);
                }
            },
            customActionClicked: function (objAction) {
                if (!a24Core.isEmpty(this.get("customActionClicked"))) {
                    return this.get("customActionClicked")(objAction);
                }
            },
            cardDatagridColumnToggleChangeEvent: function () {
                if (!a24Core.isEmpty(this.get("cardDatagridColumnToggleChangeEvent"))) {
                    return this.get("cardDatagridColumnToggleChangeEvent")();
                }
            }
        }
    });
});