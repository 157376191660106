define("a24-ember-staffshift-core/services/a24-constants-staffshift-core", ["exports", "a24-ember-lookup/services/a24-constants-base"], function (exports, _a24ConstantsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24ConstantsBase.default.extend({
        AGENCY_DEFAULT_AVATAR: "images/defaultAvatar/agencyAvatar.png",
        AGENCY_GROUP_DEFAULT_AVATAR: "images/defaultAvatar/agencygroupAvatar.png",
        CANDIDATE_DEFAULT_AVATAR: "images/defaultAvatar/defaultAvatar.png",
        ORGANISATION_DEFAULT_AVATAR: "images/defaultAvatar/organisationAvatar.png",
        SYSTEM_ADMIN_DEFAULT_AVATAR: "images/defaultAvatar/systemadminAvatar.png",

        CONTEXT_AGENCY: "Agency",
        CONTEXT_AGENCY_GROUP: "AgencyGroup",
        CONTEXT_CANDIDATE: "Candidate",
        CONTEXT_ORGANISATION: "Organisation",
        CONTEXT_SYSTEM_ADMIN: "SystemAdmin",

        // Locale Strings
        LANGUAGE_LOCALE_UK: "en_UK",
        LANGUAGE_LOCALE_SA: "en_ZA"
    });
});