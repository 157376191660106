define("a24-ember-title-bar/components/title-bar", ["exports", "a24-ember-title-bar/templates/components/title-bar", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _titleBar, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Default values
        */
        layout: _titleBar.default,
        classNames: ["title-bar"],
        objTitleBarService: Ember.inject.service("title-bar"),

        actions: {
            onBack: function () {
                if (!this.get("objTitleBarService.bBackDisabled")) {
                    var funcOnBack = this.get("objTitleBarService.onBack");
                    if (!a24Core.isEmpty(funcOnBack)) {
                        funcOnBack();
                    }
                }
            }
        }
    });
});