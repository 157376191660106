define("a24-ember-lib/components/popup-trigger", ["exports", "a24-ember-lib/templates/components/popup-trigger", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _popupTrigger, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _popupTrigger.default,
        // the id of the modal component
        sModalId: "",
        init: function () {
            this._super(...arguments);
            Ember.run.scheduleOnce("afterRender", this, function () {
                this.$("a").attr("data-toggle", "modal");
            });
        }
    });
});