define("a24-ember-staffshift-core/routes/abstractRoute", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        bContextBaseRoute: false,
        sAppContext: Ember.computed.readOnly("env.sAppContext"),
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),
        _objBeforeModelPromiseResolve: null,
        model: function (objModel, objTrans) {
            if (this.get("bContextBaseRoute")) {
                this.get("_objUserSession").set("bSkipNextAuthCall", true);
            } else {
                if (this.get("_objUserSession").get("bSkipNextAuthCall")) {
                    this.get("_objUserSession").set("bSkipNextAuthCall", false);
                    var objModelHook = this.modelHook();
                    if (objModelHook === null) {
                        return;
                    } else {
                        return objModelHook;
                    }
                }
            }

            var currentController = this;

            //Reset the before model custom promise
            this.set("_objBeforeModelPromiseResolve", null);
            //Get the context from url, will be the first segment e.g targetName=candidate.welcome
            var sContext = null;
            var sCID = null;
            var sParamLookup = null;

            if (!a24Core.isEmpty(this.get("sAppContext"))) {
                sContext = this.get("sAppContext");
                sParamLookup = "index";
                this.get("_objNavigation").set("sContext", null);
            } else {
                sContext = objTrans.targetName.split(".")[0];
                sParamLookup = sContext;
                this.get("_objNavigation").set("sContext", sContext);
            }

            //Context Id can be null in candidate and systemAdmin context
            if (objTrans.params[sParamLookup].context_id != null) {
                sCID = objTrans.params[sParamLookup].context_id;
            }

            //Update the current context base url for easier navigation inside this context later
            this.get("_objNavigation").set("sContextId", sCID);
            this.get("_objNavigation").set("objRouteParams", objTrans.params);
            this.get("_objNavigation").set("objRouteQueryParams", Ember.copy(objTrans.queryParams, true));

            //Create custom promise that will be resolved if public page or resolved when private page with valid
            //auth and user details
            return new Ember.RSVP.Promise(function (funcResolve) {
                //Set the resolve function so that we can resolve the promise when all needed calls/checks are done
                currentController.set("_objBeforeModelPromiseResolve", funcResolve);
                currentController.get("_objUserSession").doCheckAuth(function () {
                    var objModelHook = currentController.modelHook();
                    if (objModelHook == null) {
                        currentController.get("_objBeforeModelPromiseResolve")();
                    } else {
                        objModelHook.finally(function () {
                            currentController.get("_objBeforeModelPromiseResolve")();
                        });
                    }
                }, function (bInvalidAuth) {
                    if (bInvalidAuth) {
                        //Save the current transition so that we can retry later
                        currentController.get("_objNavigation").set("objPrevTrans", objTrans);

                        //Redirect to the index page where user will be able to login
                        currentController.get("_objNavigation").navigateToGWTLogin(true);
                    } else {
                        //Check Auth did not fail because of auth error/session timeout, thus it is service call
                        //failures e.g incorrect context id or user does not have access to that context
                        currentController.get("_objNavigation").navigateOutsideContext("404");
                    }
                }, sContext, sCID, currentController.get("bContextBaseRoute"));
            });
        },
        /**
         * This function will be called after the abstract model code before it resolves, thus devs can use this
         * function the same way that they would have used the model function
         *
         * @returns Null or a promise object, in case of promise abstract model will wait till promise is resolved
         *     before resolving its own
         */
        modelHook: function () {}
    });
});