define("a24-ember-staffshift-core/components/login-component", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/login-component"], function (exports, _inputFormElement, _didRenderChangesMixin, _loginComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _loginComponent.default,
        classNames: ["position-relative"],
        bMobileView: false,
        objSpecialLoginPopupAction: null,
        _bLoading: true,
        _sUsernameEmail: null,
        _bShowForgotPasswordPopup: false,
        _objNavigation: Ember.inject.service("navigation"),
        _objLoginService: Ember.inject.service("rest-login"),
        _objStaffshiftCoreStringsService: Ember.inject.service("a24-strings-staffshift-core"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objSpecialResetPasswordPopupAction: null,
        init: function () {
            this._super(...arguments);
            this.set("objChildConfig", {
                "objChildren._sUsername": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                },
                "objChildren._sPassword": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                    a24Validation.addMinLength(objMixin, 3);
                }
            });

            this.set("_bLoading", false);
        },
        keyPress: function (objEvent) {
            if (objEvent.keyCode === 13 && !this.get("_bLoading") && !this.get("_bShowForgotPasswordPopup")) {
                this._doLogin();
            }
        },
        _doLogin: function () {
            if (!this.doFullValidation()) {
                this.set("_bLoading", true);
                var objData = this.getPlainFormValueObject();
                this.get("_objLoginService").post(this, objData._sUsername.trim(), objData._sPassword, objData._bRememberMe, objData => {
                    // Check if login was success
                    if (objData.code === 1) {
                        // Send login success to parent
                        if (!a24Core.isEmpty(this.get("onLoginSuccess"))) {
                            this.get("onLoginSuccess")();
                        }
                    } else {
                        this.setResponseError(["_sUsername"], this.get("_objStaffshiftCoreStringsService").getString("incorrectLoginDetails"), true, true);
                        this.setResponseError(["_sPassword"], this.get("_objStaffshiftCoreStringsService").getString("incorrectLoginDetails"), true, true);
                        this.set("_bLoading", false);
                    }
                }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE"], () => {
                    // Show failure message
                    this.setResponseError(["_sUsername"], this.get("_objStringsService").getString("serverError"), true, true);
                    this.setResponseError(["_sPassword"], this.get("_objStringsService").getString("serverError"), true, true);
                    this.set("_bLoading", false);
                }));
            }
        },
        actions: {
            doLogin: function () {
                this._doLogin();
            },
            showForgotPasswordPopup: function () {
                this.set("_bShowForgotPasswordPopup", true);
                // populate the username\email field
                var objData = this.getPlainFormValueObject();
                if (!a24Core.isEmpty(objData._sUsername)) {
                    this.set("_sUsernameEmail", objData._sUsername.trim());
                }
            },
            hideForgotPasswordPopup: function () {
                this.set("_bShowForgotPasswordPopup", false);
            }
        }
    });
});