define("a24-ember-menu/services/left-menu-service", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /*
     * Default values
    */
    /*
     * @property sImageLogoUrl
     * True if menu items are loading
    */
    bLoading: false,

    /*
     * @property sImageLogoUrl
     * An array holding the list of menu items
    */
    arrMenuItems: null,

    /*
     * @property sTopBarComponent
     * String holding the top bar component name
    */
    sTopBarComponent: null,

    /*
     * @property iTopBarComponentHeight
     * Number(Integer) holding the top bar component height in px
    */
    iTopBarComponentHeight: 0,

    /**
     * This function is used to get an array of menu items
     * This function needs to be overriden by a subclass
     *
     * @param funcOnComplete - A function to call with the menu items
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  3 November 2017
     */
    getMenuItems() {
      (true && !(false) && Ember.assert("The `getMenuItems` function in `left-menu-service` needs to be overridden by a subclass"));
    },

    /**
     * This function is invoked when a menu item is clicked
     *
     * @param objMenuItem - The clicked menu item
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  6 November 2017
     */
    onClickMenuItem() /* objMenuItem */{/* noop */},

    /**
     * This function is invoked after left menu animation is completed
     *
     * @author Ahmed Onawale <ahmedonawale@gmail.com>
     * @since  16 November 2017
     */
    afterMenuAnimation() {/* noop */}
  });
});