define("a24-ember-lib/services/fab-button", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _arrFabActions: null,
        _bHasNewAction: false,
        _objNavigationService: Ember.inject.service("navigation"),
        _objStringsService: Ember.inject.service("a24-strings"),
        init: function () {
            this._super(...arguments);
            this.set("_arrFabActions", []);
            this.get("_objNavigationService");
            this.get("_objStringsService");
        },
        observeRouteChange: Ember.observer("_objNavigationService.sCurrentRouteName", function () {
            this.hideFabButton();
        }),
        showFabButton: function (funcFabAction, sIconName, sTooltip, sButtonColor, sIconColor, bLightWaves) {
            var objFabData = {
                funcFabAction: funcFabAction,
                sIconName: sIconName,
                sTooltip: sTooltip,
                sButtonColor: sButtonColor,
                sIconColor: sIconColor,
                bLightWaves: bLightWaves
            };

            if (a24Core.isEmpty(objFabData.sIconName)) {
                objFabData.sIconName = "add";
            }
            if (a24Core.isEmpty(objFabData.sTooltip)) {
                objFabData.sTooltip = this.get("_objStringsService").getString("add");
            }
            if (a24Core.isEmpty(objFabData.sButtonColor)) {
                objFabData.sButtonColor = "";
            }
            if (a24Core.isEmpty(objFabData.sIconColor)) {
                objFabData.sIconColor = "white-text";
            }
            if (a24Core.isEmpty(objFabData.bLightWaves)) {
                objFabData.bLightWaves = true;
            }

            //We do this in a next so that the route change code can run first
            Ember.run.next(() => {
                this.get("_arrFabActions").pushObject(objFabData);
                this.set("_bHasNewAction", true);
            });
        },
        hideFabButton: function () {
            this.get("_arrFabActions").clear();
            //Yes this should be true, so that if fires the observe in the fab component to let it know it should clear the
            //fab button from the screen. The fab component observes the _bHasNewAction and will set it back to false each
            //time it goes to true
            this.set("_bHasNewAction", true);
        }
    });
});