define("a24-ember-lib/components/iframe-sourceless", ["exports", "a24-ember-lib/templates/components/iframe-sourceless"], function (exports, _iframeSourceless) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        layout: _iframeSourceless.default,
        classNames: ["iframe-sourceless"],
        sContent: "",
        sStyles: "",
        init: function () {
            this._super(...arguments);

            Ember.run.schedule("afterRender", () => {
                this.onContentOrStyleChange();
            });
        },
        getFrameHtml: function () {

            let sContent = this.get("sContent");
            if (a24Core.isEmpty(sContent)) {
                sContent = "";
            }
            let sStyle = this.get("sStyles");
            if (a24Core.isEmpty(sStyle)) {
                sStyle = "";
            }
            var sHtml = "" + "<!DOCTYPE html>" + "<html>" + "   <head>" + "       <style>" + sStyle + "       </style>" + "   </head>" + "   <body>" + sContent + "   </body>" + "</html>";

            return sHtml;
        },
        onContentOrStyleChange: Ember.observer("sStyles", "sContent", function () {
            // Get the iframe
            var objIFrame = this.$("iframe");

            // Set the content of the iframe to the content given
            objIFrame.contents().find("html").html(this.getFrameHtml());

            // Set the height of the iframe to that of it's content
            objIFrame.css("height", objIFrame.contents().find("html").height());

            // Attach a listener to images so that the iframe can be resized once they load
            objIFrame.contents().find("img").on("load", () => {
                objIFrame.css("height", objIFrame.contents().find("html").height());
            });

            // Open sources in blank
            objIFrame.contents().find("a").attr("target", "_blank");
        })
    });
});