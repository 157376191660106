define("a24-ember-lib/mixins/input-form-element", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        // id for the component
        _sInputId: null,
        //Properties that can be overridden for the floating right side icon
        sFloatRightIconName: "info",
        sFloatRightIconColor: "a24-grey-text-lighten-1",
        funcFloatRightIconClickCallback: null,
        // This property allows adding additional info onto the field for later use e.g saving field ID for post data
        // NOTE: Currently only works when bIsArrayProperty is true and items in array are objects
        mExtraValue: null,
        // The key that you want to map the above mExtraValue to when using functions like getPlainFormValueObject
        mExtraValueKey: null,
        // the selected item/input
        mValue: null,
        // If the field required and should get the * after field name
        bRequired: false,
        // if property is a ghost property thus not to be validated or returned
        bGhostProperty: false,
        // character limit for this component
        iMaxCharacters: null,
        // validation error for this input
        sErrorMessage: null,
        // the label/header
        sFieldName: null,
        //Placeholder
        sPlaceholder: null,
        //Whether to use a static header instead of floating label
        bStaticHeader: false,
        // the name of the input
        sProperty: "",
        // the type of mValue
        sInputType: "",
        // the order in which to validate this input
        iOrder: null,
        // whether the value of the property is an array
        bIsArrayProperty: false,
        // the order to validate the child inputs, if there is an array of child inputs
        iArrayOrder: 0,
        // the validation functions that will be called, populated by top level
        arrValidation: null,
        // the validation functions that will be called at a later stage, populated by top level
        arrLateValidation: null,
        // the children of this input
        arrValidationOrder: null,
        // instances of the child input fields
        objChildren: null,
        // Used to define the validation
        objChildConfig: null,

        _objStringsService: Ember.inject.service("a24-strings"),
        // Keep track of when internal updates happen on the component
        _bInternalMValueUpdate: false,
        init: function () {
            this._super(...arguments);
            // this is done so that the _sInputId can passed in during testing and
            // we can test that it is set correct
            if (a24Core.isEmpty(this.get("_sInputId"))) {
                this.set("_sInputId", Ember.guidFor({}));
            }
            if (a24Core.isEmpty(this.get("arrValidation"))) {
                this.set("arrValidation", []);
            }
            if (a24Core.isEmpty(this.get("arrLateValidation"))) {
                this.set("arrLateValidation", []);
            }
            if (a24Core.isEmpty(this.get("arrValidationOrder"))) {
                this.set("arrValidationOrder", []);
            }
            if (a24Core.isEmpty(this.get("objChildren"))) {
                this.set("objChildren", {});
            }
            Ember.run.scheduleOnce("afterRender", this, () => {
                if (!a24Core.isEmpty(this.get("sErrorMessage"))) {
                    this.showErrorMessage();
                }

                if (!a24Core.isEmpty(this.get("onInputValidationAdd"))) {
                    this.get("onInputValidationAdd")(this);
                }

                //This has to happen after adding the child to the parent so that the validation can set the iMaxCharacters
                if (!a24Core.isEmpty(this.get("iMaxCharacters"))) {
                    $("#" + this.get("_sInputId")).characterCounter();
                }
            });
        },
        didDestroyElement: function () {
            this._super(...arguments);
            if (!a24Core.isEmpty(this.get("onInputValidationRemove"))) {
                this.get("onInputValidationRemove")(this);
            }
        },
        observeErrorMessage: Ember.observer("sErrorMessage", function () {
            // remove the error message div if sErrorMessage is empty
            if (a24Core.isEmpty(this.get("sErrorMessage"))) {
                var objErrorMessage = this.$(".a24ErrorMessage");
                objErrorMessage.remove();
            }
        }),
        setMValueWithInternalFlag: function (mValue, bInternalUpdate) {
            this.set("_bInternalMValueUpdate", bInternalUpdate);
            this.set("mValue", mValue);
            this.set("_bInternalMValueUpdate", false);
        },
        onMValueChange: Ember.observer("mValue", function () {
            if (!this.get("_bInternalMValueUpdate")) {
                this._buildInputValueChangedPath([]);
            }
        }),
        getPlainFormValueObject: function () {
            // Create blank return object
            var objValueObject = {};

            // Get the object containing the children
            var objChildren = this.get("objChildren");
            if (!this.isVisible()) {
                return objValueObject;
            }
            if (this.get("bGhostProperty")) {
                return objValueObject;
            }
            // Iterate over each of the children
            for (var sKey in objChildren) {

                // Ensure object has key
                if (objChildren.hasOwnProperty(sKey)) {
                    // Make a reference to the child
                    let mChild = objChildren[sKey];

                    // Distinguish array of children from direct child
                    if (mChild instanceof Array) {
                        // Ensure array is not empty
                        if (!a24Core.isEmpty(mChild)) {
                            // Loop over the array
                            for (let i = 0; i < mChild.length; i++) {
                                // Add array child to main object
                                objValueObject = this.addChildToObject(objValueObject, mChild[i], true);
                            }
                        }
                    } else {
                        // Add child to main object
                        objValueObject = this.addChildToObject(objValueObject, mChild, false);
                    }
                }
            }

            // Return the populated object
            return objValueObject;
        },
        addChildToObject(objValueObject, objChild, bIsArray) {
            if (!objChild.isVisible()) {
                return objValueObject;
            }

            if (objChild.get("bGhostProperty")) {
                return objValueObject;
            }
            // Get the property name of the object
            let sProperty = objChild.sProperty;
            // Define a nesting object
            var objNest = objValueObject;

            // Check if property is nested
            if (sProperty.indexOf(".") !== -1) {
                // The property is nested

                // Split the name in to individual properties
                let arrFields = objChild.sProperty.split(".");
                // Get the last item of the array since this is the actual property
                sProperty = arrFields.pop();

                // Iterate over the remaining fields
                for (let i = 0; i < arrFields.length; i++) {
                    // Check if the object has this property
                    if (!objNest.hasOwnProperty(arrFields[i])) {
                        // Create the object if it has not been done
                        objNest[arrFields[i]] = {};
                    }
                    // Redefine objNest as reference to embedded object
                    objNest = objNest[arrFields[i]];
                }
            }

            // Check if property is an array
            if (bIsArray) {
                // If the array is empty
                if (a24Core.isEmpty(objNest[sProperty])) {
                    // Create the array
                    objNest[sProperty] = [];
                }
                var mArrayInputValue = this.getValueFromType(objChild);

                if (mArrayInputValue !== null && typeof mArrayInputValue === "object") {
                    if (!a24Core.isEmpty(objChild.get("mExtraValue"))) {
                        mArrayInputValue[objChild.get("mExtraValueKey")] = objChild.get("mExtraValue");
                    }
                }

                // Push item in to array
                objNest[sProperty].push(mArrayInputValue);
            } else {
                // Set the item on the property
                objNest[sProperty] = this.getValueFromType(objChild);
            }
            // Return the newly modified value object
            return objValueObject;
        },
        getValueFromType: function (objChild) {
            var objResult = objChild.checkExtraTypes(objChild);
            if (!a24Core.isEmpty(objResult) && objResult.bHandled) {
                return objResult.mReturned;
            } else {
                return objChild.getValueTypeCheck(objChild);
            }
        },
        checkExtraTypes: function () /*objChild*/{
            // This function is for when there is extra types you want to add but is not generic to be placed in the lib
            // This function is expected to return the following object
            /*
             {
                bHandled: false/true,
                mReturned: "what ever the value is"
             }
             */

        },
        getValueTypeCheck: function (objChild) {
            // Check type
            if (objChild.sInputType === "text" || objChild.sInputType === "date" || objChild.sInputType === "text-area" || objChild.sInputType === "tel" || objChild.sInputType === "html") {
                if (a24Core.isEmpty(objChild.mValue)) {
                    return null;
                }
                return objChild.mValue.trim();
            } else if (objChild.sInputType === "password" || objChild.sInputType === "time" || objChild.sInputType === "file") {
                if (objChild.mValue === "" || objChild.mValue === null) {
                    return null;
                }
                return objChild.mValue;
            } else if (objChild.sInputType === "radio") {
                if (objChild.mValue == null) {
                    return null;
                }
                let sValue = objChild.mValue[objChild.get("sOptionsValueKey")];
                if (a24Core.isEmpty(sValue)) {
                    return null;
                }
                return sValue.trim();
            } else if (objChild.sInputType === "dropdown-single") {
                if (objChild.mValue == null) {
                    return null;
                }
                let sValue = objChild.mValue[objChild.get("sOptionsValueKey")];
                if (a24Core.isEmpty(sValue)) {
                    return null;
                }
                return sValue.trim();
            } else if (objChild.sInputType === "dropdown-multi") {
                if (objChild.mValue == null) {
                    return null;
                }
                let arrList = [];

                for (var j = 0; j < objChild.mValue.length; j++) {
                    arrList.push(Ember.copy(objChild.mValue[j][objChild.get("sOptionsValueKey")], true));
                }
                return arrList;
            } else if (objChild.sInputType === "suggest") {
                if (objChild.mValue == null) {
                    return null;
                }
                let mValue = Ember.copy(objChild.mValue, true);
                if (a24Core.isEmpty(mValue)) {
                    return null;
                }
                return mValue;
            } else if (objChild.sInputType === "checkbox") {
                if (objChild.mValue == null) {
                    return null;
                }
                let bValue = objChild.mValue.bValue;
                if (a24Core.isEmpty(bValue)) {
                    return null;
                }
                return bValue;
            } else if (objChild.sInputType === "tag") {
                if (a24Core.isEmpty(objChild.mValue)) {
                    return null;
                }

                let arrList = [];
                for (var i = 0; i < objChild.mValue.length; i++) {
                    arrList.push(Ember.copy(objChild.mValue[i], true));
                }

                return arrList;
            } else {

                // Any unknown type will be treated as a nested object
                return objChild.getPlainFormValueObject();
            }
        },
        showErrorMessage: function () {
            if (!this.isVisible()) {
                return;
            }
            var objInputElement = this.$(".a24ErrorInputParent");
            if (objInputElement.length === 0) {
                objInputElement = this.$();
            }
            var objErrorMessage = this.$("<div class='a24ErrorMessage'></div>");
            if (!a24Core.isEmpty(this.get("iMaxCharacters"))) {
                //if we have character counter then add right padding to error message so that they dont collide
                objErrorMessage.css("padding-right", "3rem");
            }
            var sErrorMessage = this.get("sErrorMessage");

            if (!a24Core.isEmpty(this.get("sErrorMessage"))) {
                objErrorMessage.html(sErrorMessage);
                //This has to happen after the characterCounter else the character counter will display below the error
                objInputElement.append(objErrorMessage);
            }
        },
        _buildInputValueChangedPath: function (arrPathToInputMixin) {

            this.onInputValueChanged(arrPathToInputMixin);

            if (this.get("bIsArrayProperty")) {
                // add the array order if array property
                arrPathToInputMixin.unshift(this.get("iArrayOrder"));
            }
            // add sProperty to beginning of arrPathToInputMixin
            arrPathToInputMixin.unshift(this.get("sProperty"));
            // add "objChildren" to beginning of arrPathToInputMixin
            arrPathToInputMixin.unshift("objChildren");

            if (!a24Core.isEmpty(this.get("onInputValueChangedAction"))) {
                this.get("onInputValueChangedAction")(arrPathToInputMixin);
            }
        },
        _buildInputChildAddedPath: function (arrPathToInputMixin, objChildMixin) {
            this.onAddChildValidation(arrPathToInputMixin, objChildMixin);

            if (this.get("bIsArrayProperty")) {
                // add the array order if array property
                arrPathToInputMixin.unshift(this.get("iArrayOrder"));
            }
            // add sProperty to beginning of arrPathToInputMixin
            arrPathToInputMixin.unshift(this.get("sProperty"));

            // add "objChildren" to beginning of arrPathToInputMixin
            arrPathToInputMixin.unshift("objChildren");

            if (!a24Core.isEmpty(this.get("onAddChildValidationAction"))) {
                this.get("onAddChildValidationAction")(arrPathToInputMixin, objChildMixin);
            }
        },

        onAddChildValidation: function (arrPathToInputMixin, objChildMixin) {
            var objChildConfig = this.get("objChildConfig");

            var arrFinalLookup = [];
            for (var i = 0; i < arrPathToInputMixin.length; i++) {
                let pathItem = arrPathToInputMixin[i];
                if (typeof pathItem === "number") {
                    arrFinalLookup.push("@each");
                } else {
                    arrFinalLookup.push(pathItem);
                }
            }
            var sLookup = arrFinalLookup.join(".");
            if (!a24Core.isEmpty(objChildConfig) && objChildConfig.hasOwnProperty(sLookup)) {
                objChildConfig[sLookup](objChildMixin);
            }
        },
        isVisible: function () {
            return this.$().is(":visible");
        },
        doValidation: function (bFullValidate) {
            if (!this.isVisible()) {
                return false;
            }
            this.set("sErrorMessage", "");
            // this is to lose focus on all fields so that the mValue could update instead of still being empty
            // even if the field did change its value
            var objFocused = this.$(":focus");
            if (!a24Core.isEmpty(objFocused)) {
                objFocused.blur();
            }

            var bHasError = false;
            var objChildren = this.get("objChildren");
            if (!a24Core.isEmpty(objChildren)) {
                var arrValidationOrder = this.get("arrValidationOrder");
                arrValidationOrder.sort(function (objOne, objTwo) {
                    return objOne.iOrder - objTwo.iOrder;
                });

                for (var i = 0; i < arrValidationOrder.length; i++) {
                    if (arrValidationOrder[i].bArrayProperty) {

                        objChildren[arrValidationOrder[i].sProperty].sort(function (objOne, objTwo) {
                            return objOne.iArrayOrder - objTwo.iArrayOrder;
                        });

                        for (var j = 0; j < objChildren[arrValidationOrder[i].sProperty].length; j++) {
                            let bChildValidation = objChildren[arrValidationOrder[i].sProperty][j].doValidation(bFullValidate);

                            if (bChildValidation) {
                                bHasError = true;
                            }
                            //Dont break when we have error, we want all fields with errors to go red
                        }
                    } else {
                        let bChildValidation = objChildren[arrValidationOrder[i].sProperty].doValidation(bFullValidate);

                        if (bChildValidation) {
                            bHasError = true;
                        }
                        //Dont break when we have error, we want all fields with errors to go red
                    }
                }
            }

            //loop over all arrValidation function and call then
            var arrValidation = this.get("arrValidation");
            for (var k = 0; k < arrValidation.length; k++) {
                var sErrorMessage = arrValidation[k]();
                if (!a24Core.isEmpty(sErrorMessage)) {
                    bHasError = true;
                    this.setErrorState(sErrorMessage);
                    break; //Only one error per input field so we break
                }
            }

            var arrLateValidation = this.get("arrLateValidation");
            if (bFullValidate && !bHasError) {
                for (var l = 0; l < arrLateValidation.length; l++) {
                    var sLateErrorMessage = arrLateValidation[l]();
                    if (!a24Core.isEmpty(sLateErrorMessage)) {
                        bHasError = true;
                        this.setErrorState(sLateErrorMessage);
                    }
                }
            }

            return bHasError;
        },
        doFullValidation: function () {
            return this.doValidation(true);
        },
        setResponseError: function (arrPath, sErrorMessageKey, bNoTokens, bUseKeyAsString, objStringService) {
            var arrMixinPath = [];
            for (let i = 0; i < arrPath.length; i++) {
                if (!a24Core.isNumber(arrPath[i])) {
                    arrMixinPath.push("objChildren");
                }
                arrMixinPath.push(arrPath[i]);
            }

            var objChildMixin = this;

            if (a24Core.isEmpty(objStringService)) {
                objStringService = objChildMixin.get("_objStringsService");
            }

            for (let i = 0; i < arrMixinPath.length; i++) {
                objChildMixin = objChildMixin[arrMixinPath[i]];
                if (a24Core.isEmpty(objChildMixin)) {
                    break;
                }
            }

            if (!a24Core.isEmpty(objChildMixin)) {
                if (bNoTokens) {
                    if (bUseKeyAsString) {
                        objChildMixin.setErrorState(sErrorMessageKey);
                    } else {
                        objChildMixin.setErrorState(objStringService.getString(sErrorMessageKey));
                    }
                } else {
                    objChildMixin.setErrorMessage(objStringService.getTokenString(sErrorMessageKey, objChildMixin.get("sFieldName")));
                }
            }
        },
        clearAllErrorMessages: function () {
            this.set("sErrorMessage", "");

            var objChildren = this.get("objChildren");
            if (!a24Core.isEmpty(objChildren)) {
                for (var sKey in objChildren) {
                    if (objChildren.hasOwnProperty(sKey)) {
                        if (objChildren[sKey] instanceof Array) {
                            for (var i = 0; i < objChildren[sKey].length; i++) {
                                objChildren[sKey][i].clearAllErrorMessages();
                            }
                        } else {
                            objChildren[sKey].clearAllErrorMessages();
                        }
                    }
                }
            }
        },
        clearForm: function () {

            var objChildren = this.get("objChildren");
            if (!a24Core.isEmpty(objChildren)) {
                for (var sKey in objChildren) {
                    if (objChildren.hasOwnProperty(sKey)) {
                        var objChild = objChildren[sKey];
                        if (objChild instanceof Array) {
                            for (let i = 0; i < objChild.length; i++) {
                                objChild[i].clearForm();
                            }
                        } else {
                            objChild.clearForm();
                        }
                    }
                }
            }

            this.clear();
        },
        clear: function () {
            // To be implemented by the children
        },
        setErrorMessage: function (sErrorMessage) {
            this.set("sErrorMessage", sErrorMessage);
            this.showErrorMessage();
            if (!a24Core.isEmpty(sErrorMessage)) {
                if (!a24Core.isEmpty(this.get("onErrorMessageSetAction"))) {
                    this.get("onErrorMessageSetAction")(this, parseInt(this.$().css("margin-top")));
                }
            }
        },
        setErrorState: function (sMessage) {
            // This is default behaviour. This can be overridden for custom error highlighting
            this.setErrorMessage(sMessage);
        },
        onFocusOut: function () {},
        onInputValueChanged: function () /*arrPathToInputMixin*/{
            // This is defaultly empty, this can be overridden to listen to changes
            // If you implement this in your component you first need to do an
            // empty check on arrPathToInputMixin and if it is empty simply return
        },
        _onErrorMessageSet: function (objInputMixin, iScrollOffset) {
            this.onErrorMessageSet(objInputMixin);
            if (!a24Core.isEmpty(this.get("onErrorMessageSetAction"))) {
                this.get("onErrorMessageSetAction")(objInputMixin, iScrollOffset);
            }
        },
        onErrorMessageSet: function () /*objInputMixin*/{
            // This is defaultly empty, this can be overridden
        },
        actions: {
            onFloatRightIconClicked: function () {
                if (!a24Core.isEmpty(this.get("funcFloatRightIconClickCallback"))) {
                    this.get("funcFloatRightIconClickCallback")();
                }
            },
            onInputValidationAdd: function (objChild) {
                if (objChild.get("bIsArrayProperty")) {
                    if (a24Core.isEmpty(this.get("objChildren")[objChild.get("sProperty")])) {
                        this.get("objChildren")[objChild.get("sProperty")] = [objChild];
                        this.get("arrValidationOrder").push({
                            bArrayProperty: true,
                            iOrder: objChild.get("iOrder"),
                            sProperty: objChild.get("sProperty")
                        });
                    } else {
                        this.get("objChildren")[objChild.get("sProperty")].push(objChild);
                    }
                    this._buildInputChildAddedPath(["objChildren", objChild.get("sProperty"), objChild.get("iArrayOrder")], objChild);
                } else {
                    this.get("arrValidationOrder").push({
                        bArrayProperty: false,
                        iOrder: objChild.get("iOrder"),
                        sProperty: objChild.get("sProperty")
                    });
                    this.get("objChildren")[objChild.get("sProperty")] = objChild;

                    this._buildInputChildAddedPath(["objChildren", objChild.get("sProperty")], objChild);
                }
            },
            onInputValidationRemove: function (objChild) {
                if (objChild.get("bIsArrayProperty")) {

                    var arrItems = this.get("objChildren")[objChild.get("sProperty")];
                    for (let i = 0; i < arrItems.length; i++) {
                        if (arrItems[i] === objChild) {
                            this.get("objChildren")[objChild.get("sProperty")].splice(i, 1);
                            break;
                        }
                    }

                    if (this.get("objChildren")[objChild.get("sProperty")].length === 0) {
                        for (let i = 0; i < this.get("arrValidationOrder").length; i++) {
                            if (this.get("arrValidationOrder")[i].sProperty === objChild.get("sProperty")) {
                                this.get("arrValidationOrder").splice(i, 1);
                                break;
                            }
                        }
                        delete this.get("objChildren")[objChild.get("sProperty")];
                    }
                } else {
                    for (let i = 0; i < this.get("arrValidationOrder").length; i++) {
                        if (this.get("arrValidationOrder")[i].sProperty === objChild.get("sProperty")) {
                            this.get("arrValidationOrder").splice(i, 1);
                            break;
                        }
                    }
                    delete this.get("objChildren")[objChild.get("sProperty")];
                }
            },
            onInputValueChangedAction: function (arrPathToInputMixin) {
                this._buildInputValueChangedPath(arrPathToInputMixin);
            },
            onAddChildValidationAction: function (arrPathToInputMixin, objChildMixin) {
                this._buildInputChildAddedPath(arrPathToInputMixin, objChildMixin);
            },
            onErrorMessageSetAction: function (objInputMixin, iScrollOffset) {
                this._onErrorMessageSet(objInputMixin, iScrollOffset);
            }
        }
    });
});