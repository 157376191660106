define("a24-ember-staffshift-core/services/a24-strings-staffshift-core", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,

        init: function () {
            this._super(...arguments);
            this.set("_objStrings", {

                //===== STRINGS START =====

                incorrectLoginDetails: "Either the Email or Password entered was incorrect",
                wantsAccess: " wants to get information from your Staffshift Account",
                welcomeMessage: "Please select an item from the menu on the left to get started",
                supportChat: "Support Chat",
                forgotYourPassword: "Forgot Your Password?",
                bySubmittingThisForm: "By submitting this form, you will receive an email to reset your password.<br/>To complete the process you will need to follow the instructions set out in the email.",
                emailNotInDatabase: "The email address/username you have entered does not appear in our database, please enter the correct email address or username.",
                resetPasswordEmailSent: "An email to reset your password has been sent",
                usernameEmail: "Username/Email",
                resetPassword: "Reset Password",
                signIn: "Sign In",
                submit: "Submit",

                //===== STRINGS END   =====

                //===== TOKEN STRINGS START =====

                currentlyLoggedIn: "{0} You are currently logged in as "

                //===== TOKEN STRINGS END =====
            });
        }
    });
});