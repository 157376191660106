define("a24-ember-window/services/component-did-render", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _iCountRender: 0,
        incrementCounter: function () {
            this.set("_iCountRender", this.get("_iCountRender") + 1);
        },
        countRendered: function () {
            Ember.run.scheduleOnce("afterRender", this, this.incrementCounter);
        }
    });
});