define("a24-ember-staffshift-core/components/navigation-bar-component", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-staffshift-core/templates/components/navigation-bar-component"], function (exports, _didRenderChangesMixin, _navigationBarComponent) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _navigationBarComponent.default,

        arrNavbarItems: null, // The navbar items to display
        sAvatarUrl: null, // The Avatar url to display
        sFirstNameInitial: null, // The initial of the user to display if the avatar is null
        sContextSwitcherDropdownContentId: "contextSwitcherDropdown",
        sBaseClass: "white",

        _objTitleBarService: Ember.inject.service("title-bar-service"), // The title bar service
        _objConstantsEmberLib: Ember.inject.service("a24-constants-ember-lib"),

        init: function () {
            this._super(...arguments);
            this.set("arrNavbarItems", []);
        },

        _buildTitleBarItems: Ember.observer("_objTitleBarService.sPageTitle", function () {
            // Set the new navbar items when the page title changes
            this.set("arrNavbarItems", []);
            // Push only the page title as a navbar item
            this.get("arrNavbarItems").push(this.get("_objTitleBarService.sPageTitle"));
        }).on("init"),
        _buildTitleBarAvatar: Ember.observer("_objTitleBarService.sUserAvatar", function () {
            // Set the new avatar url if the user avatar changes
            this.set("sAvatarUrl", this.get("_objTitleBarService.sUserAvatar"));
        }).on("init"),
        _buildTitleInitial: Ember.observer("_objTitleBarService.sUserNameInitial", function () {
            // Set the new avatar alternative letter if the user initial changes
            this.set("sFirstNameInitial", this.get("_objTitleBarService.sUserNameInitial"));
        }).on("init")
    });
});