define("a24-ember-lib/components/input-form-radio-group", ["exports", "a24-ember-lib/templates/components/input-form-radio-group", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputFormRadioGroup, _inputFormElement, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _inputFormRadioGroup.default,
        classNames: ["a24ErrorInputParent"],
        sInputType: "radio",
        _sGroupName: null,
        objSelectedItem: null,
        arrRadioButtonItems: null, // the items that will display on screen
        // the key in the array for the radio button value
        sOptionsValueKey: "sValue",
        // the key in the array for the radio button label
        sOptionsLabelKey: "sLabel",
        // the key in the array for whether the radio button is selected
        sOptionsSelectedKey: "bSelected",
        init: function () {
            this._super(...arguments);
            // this is done so that the _sItemId can passed in during testing and
            // we can test that it is set correct
            if (a24Core.isEmpty(this.get("_sGroupName"))) {
                this.set("_sGroupName", Ember.guidFor({}));
            }
            Ember.run.scheduleOnce("afterRender", this, () => {
                this.updateInputSelectedValues(false);
            });
        },
        clear: function () {
            var sSelectedKey = this.get("sOptionsSelectedKey");

            //Sets all to false
            this.get("arrRadioButtonItems").setEach(sSelectedKey, false);
            this.setValueFromOutside(null, false);
        },
        setValueFromOutside: function (objSelectedItem, bInternalProcess) {
            var arrRadioButtonItemData = this.get("arrRadioButtonItems");
            if (a24Core.isEmpty(arrRadioButtonItemData)) {
                arrRadioButtonItemData = [];
            }

            var objFinalSelectedItem = null;
            var sOptionsValueKey = this.get("sOptionsValueKey");

            // add the item id to each object
            for (var i = 0; i < arrRadioButtonItemData.length; i++) {
                Ember.set(arrRadioButtonItemData[i], "_sItemId", Ember.guidFor({}));
                if (!a24Core.isEmpty(objSelectedItem) && arrRadioButtonItemData[i][sOptionsValueKey] === objSelectedItem[sOptionsValueKey]) {
                    Ember.set(arrRadioButtonItemData[i], "bSelected", true);
                    objFinalSelectedItem = arrRadioButtonItemData[i];
                }
            }
            this.setMValueWithInternalFlag(objFinalSelectedItem, bInternalProcess);
        },
        observeOptionsChange: Ember.observer("arrRadioButtonItems.@each", "objSelectedItem", function () {
            Ember.run.once(this, this.updateInputSelectedValuesFromObserve);
        }),
        updateInputSelectedValuesFromObserve: function () {
            this.updateInputSelectedValues(true);
        },
        updateInputSelectedValues: function (bFireEvent) {
            this.setValueFromOutside(this.get("objSelectedItem"), !bFireEvent);
        },
        actions: {
            onItemSelected: function (objRadioButtonItem) {
                var sSelectedKey = this.get("sOptionsSelectedKey");

                //Sets all to false
                this.get("arrRadioButtonItems").setEach(sSelectedKey, false);
                Ember.set(objRadioButtonItem, sSelectedKey, true); //Set selected to true

                this.setMValueWithInternalFlag(objRadioButtonItem, false);
            }
        }
    });
});