define("a24-ember-lookup/services/a24-strings-base", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objStrings: null,

        /**
         * This function will get you a string for a given key
         *
         * @param sKey - The unique key of the string
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  8 January 2018
         *
         * @return The un-escaped string
         */
        getString: function (sKey) {
            return this.get("_objStrings")[sKey] || null;
        },

        /**
         * This function will get you a string with all tokens replaced
         *
         * @param sKey - The unique locale string key
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  8 January 2018
         *
         * @return The string with all tokens replaced
         */
        getTokenString: function (sKey /*,sToken, sToken2 ...*/) {
            var sLocaleString = this.getString(sKey);

            var arrTokens = [].slice.call(arguments, 1);

            return a24Core.replaceTokensInString(sLocaleString, arrTokens);
        },

        /**
         * This function will get you an un-escaped version of the html string with all
         * tokens replaced.
         * Note that this string will render in the browser.
         *
         * @param sKey - The unique locale string key
         *
         * @author Ahmed Onawale <ahmedonawale@gmail.com>
         * @since  8 January 2018
         *
         * @return The un-escaped locale html string
         */
        getHTMLTokenString: function () /*sKey, sToken, sToken2 ...*/{
            return new Ember.String.htmlSafe(this.getTokenString(...arguments) // Call getTokenString function with the arguments
            );
        }
    });
});