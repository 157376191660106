var a24EnumsPolicyLibrary = {

    classification: {
        "viewable": "Viewable",
        "acknowledgeable": "Acknowledgeable"
    }

};

if (typeof a24Enums === "undefined") {
    var a24Enums = {};
}

if (typeof a24EnumBase === "undefined") {
    var a24EnumBase = {};
}

// Only add the extended values to a24Enums if they do not already exist. This also applies for all sub-properties.
Object.keys(a24EnumsPolicyLibrary).forEach(function(sKey) {
    if (!a24Enums.hasOwnProperty(sKey)) {
        a24Enums[sKey] = a24EnumsPolicyLibrary[sKey];
    } else {
        Object.keys(a24EnumsPolicyLibrary[sKey]).forEach(function(sSubKey) {
            a24Enums[sKey][sSubKey] = a24EnumsPolicyLibrary[sKey][sSubKey];
        });
    }
});

// We add all values of a24EnumBase to all root properties of a24Enums if they do not already exist
Object.keys(a24Enums).forEach(function(sKey) {
    if (a24Enums.hasOwnProperty(sKey)) {
        Object.keys(a24EnumBase).forEach(function(sSubKey) {
            if (!a24Enums[sKey].hasOwnProperty(sSubKey)) {
                a24Enums[sKey][sSubKey] = a24EnumBase[sSubKey];
            }
        });
    }
});
