define("a24-ember-lib/mixins/outside-click-handler-mixin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        onOutsideClick() {}, // Placeholder function
        handleOutsideClick: function (objEvent) {
            Ember.run(() => {
                let objElement = this.$();
                let objTarget = Ember.$(objEvent.target);

                // Check if the click is inside the target
                if (!objTarget.closest(objElement).length) {
                    this.onOutsideClick(objEvent); // Handle the click
                }
            });
        },
        didInsertElement: function () {
            this._super(...arguments);
            this.setupOutsideClickListener();
        },
        willDestroyElement: function () {
            this._super(...arguments);
            this.removeOutsideClickListener();
        },
        setupOutsideClickListener: function () {
            // Set up the click handler
            let objClickHandler = this.get("handleOutsideClick").bind(this);
            return Ember.$(window).on("click touchstart", objClickHandler);
        },
        removeOutsideClickListener: function () {
            // remove the click handler
            let objClickHandler = this.get("handleOutsideClick").bind(this);
            return Ember.$(window).off("click touchstart", Ember.run.cancel(this, objClickHandler));
        }
    });
});