define("a24-ember-policy-library/components/page-policy-add-edit", ["exports", "a24-ember-lib/mixins/input-form-element", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/page-policy-add-edit"], function (exports, _inputFormElement, _didRenderChangesMixin, _pagePolicyAddEdit) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_inputFormElement.default, _didRenderChangesMixin.default, {
        layout: _pagePolicyAddEdit.default,
        sPolicyId: null,
        sPolicyRestService: "rest-agency-group-policy",
        sPolicyTypeRestService: "rest-agency-group-policy-type",

        _bLoading: true,
        _bShowUnsavedData: false,
        _objOriginalData: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objBreadCrumbService: Ember.inject.service("bread-crumb"),
        _objPolicyRestService: Ember.computed("sPolicyRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyRestService"));
        }),
        _objPolicyTypeRestService: Ember.computed("sPolicyTypeRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyTypeRestService"));
        }),
        _objRestAgencyGroupPolicyProperty: null,
        _objRestAgencyGroupPolicyTypeProperty: null,
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _iNewVersion: 1,
        _arrAdditionalCssFiles: null,

        // The starting values of the inputs.

        _bPromptVisible: true,
        //The starting values of the inputs
        _sContentValue: null,
        _sPromptValue: null,
        _sEffectiveFromValue: null,

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        init: function () {
            this._super(...arguments);

            this.set("_arrAdditionalCssFiles", ["/assets/custom_policy.css"]);

            this.set("_arrActions", [{
                sTitle: this.get("_objStringsService").getString("add"),
                sIcon: "add",
                executeCardAction: () => {
                    //Clean up the popup input before showing
                    this.set("_sInputPolicyTitle", null);
                    this.set("_sInputPolicyDesc", null);
                    this.set("_bAddPopupLoading", false);

                    this.clearAllErrorMessages();

                    this.set("_bAddPopupShow", true);
                }
            }]);

            // Set up the form children
            this.set("objChildConfig", {
                "objChildren.content.html": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.prompt.html": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                },
                "objChildren.effective_at": objMixin => {
                    a24Validation.addRequired(objMixin, true);
                }
            });

            var objDoGetLatestPolicy = this.doGetLatestPolicy();
            var funcGetPolicyTypeDetailsCallback = () => {
                // Handled in RSVP
            };
            var objDoGetPolicyTypeDetailsCall = this.doGetPolicyTypeDetailsCall(funcGetPolicyTypeDetailsCallback);

            if (a24Core.isEmpty(this.get("sPolicyId"))) {
                a24RSVP.configure(this, {
                    "_objDoGetLatestPolicy": objDoGetLatestPolicy,
                    "_objDoGetPolicyTypeDetailsCall": objDoGetPolicyTypeDetailsCall
                }, [{
                    arrRequiredResponses: ["_objDoGetLatestPolicy", "_objDoGetPolicyTypeDetailsCall"],
                    funcOnComplete: objPromiseResponse => {
                        Ember.run.next(() => {
                            this.setupFormData(objPromiseResponse["_objDoGetLatestPolicy"].objResponse);
                        });

                        if (!a24Core.isEmpty(objPromiseResponse["_objDoGetPolicyTypeDetailsCall"].objResponse)) {
                            this.setPageBreadCrumbItems(objPromiseResponse["_objDoGetPolicyTypeDetailsCall"].objResponse);
                            this.setClassificationFields(objPromiseResponse["_objDoGetPolicyTypeDetailsCall"].objResponse);
                        }
                        this.set("_bLoading", false);
                    }
                }]);
            } else {
                //Here we can start doing things related to the edit state of this page
                funcGetPolicyTypeDetailsCallback = objResponse => {
                    this.setPageBreadCrumbItems(objResponse);
                    this.setClassificationFields(objResponse);
                    this.set("_bLoading", false);
                };
                this.doGetPolicyTypeDetailsCall(funcGetPolicyTypeDetailsCallback);
            }
        },
        doGetLatestPolicy: function () {
            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeAddId();
            if (!a24Core.isEmpty(sPolicyTypeId)) {
                return this.get("_objPolicyRestService").getPoliciesForPolicyType(this, "_objRestAgencyGroupPolicyProperty", this.get("_objUserSession").getUserContextReference().mId, sPolicyTypeId, () => /*objResponse*/{
                    // handled in RSVP
                }, null, {
                    items_per_page: {
                        mValue: "1"
                    }
                }, false);
            }
        },
        doGetPolicyTypeDetailsCall: function (funcSuccessCallback) {
            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeAddId();
            if (!a24Core.isEmpty(sPolicyTypeId)) {
                return this.get("_objPolicyTypeRestService").getPolicyType(this, "_objRestAgencyGroupPolicyTypeProperty", this.get("_objUserSession").getUserContextReference().mId, sPolicyTypeId, funcSuccessCallback);
            }
        },
        setPageBreadCrumbItems: function (objResponse) {
            Ember.run.scheduleOnce("afterRender", () => {
                var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeAddId();
                if (a24Core.isEmpty(sPolicyTypeId)) {
                    return;
                }
                var arrCurrentBreadCrumb = [{
                    sLink: this.get("_objNavigationService").navigateWithinContext("policy-type-list", null, null, true),
                    sTitle: this.get("_objPolicyStringsService").getString("policies")
                }, {
                    sLink: this.get("_objNavigationService").navigateWithinContext("policy-type-view", {
                        "policy-type-view": [sPolicyTypeId]
                    }, null, true),
                    sTitle: objResponse.title
                }, {
                    sLink: this.get("_objNavigationService").navigateWithinContext("policy-add", {
                        "policy-add": [sPolicyTypeId]
                    }, null, true),
                    sTitle: this.get("_objPolicyStringsService").getString("createPolicyVersion")
                }];

                this.get("_objBreadCrumbService").setCustomBreadItems(arrCurrentBreadCrumb);

                // Set the default items so that the custom can be applied
                this.get("_objBreadCrumbService").setBreadCrumbItems();
            });
        },
        setClassificationFields: function (objData) {
            if (objData.classification !== "acknowledgeable") {
                this.set("_bPromptVisible", false);
            } else {
                this.set("_bPromptVisible", true);
            }
        },
        setupFormData: function (arrData) {
            var objData = null;
            if (!a24Core.isEmpty(arrData)) {
                // The first item will be the latest since the getPoliciesForPolicyType does sort by on version
                objData = arrData[0];
                this.set("_iNewVersion", objData.version + 1);
            }

            if (!a24Core.isEmpty(objData)) {
                //Format data for rest post
                let objDateManager = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
                objDateManager.setDateFromRest(objData.effective_at);

                this.set("_sContentValue", objData.content.html);

                if (!a24Core.isEmpty(objData.prompt)) {
                    this.set("_sPromptValue", objData.prompt.html);
                }

                this.set("_sEffectiveFromValue", objDateManager.getDateFormat());
            }

            this.set("_bLoading", false);

            Ember.run.next(() => {
                Ember.run.schedule("afterRender", () => {
                    this.set("_objOriginalData", this.getPlainFormValueObject());
                });
            });
        },
        formatPayload: function () {
            var objPayload = this.getPlainFormValueObject();
            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeAddId();
            if (a24Core.isEmpty(sPolicyTypeId)) {
                return;
            }
            objPayload.policy_type_id = sPolicyTypeId;

            //Format data for rest post
            let objDateManager = a24DateManager.createDate(this.get("_objUserSession").get("objContextDetails.sTimezone"));
            objDateManager.setDateFromBrowser(objPayload.effective_at, this.get("_objConstantsEmberLibService").DATE_FORMAT);
            objPayload.effective_at = objDateManager.getDateStringForRest();

            return objPayload;
        },
        navigateToViewPage: function () {
            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeAddId();
            if (a24Core.isEmpty(sPolicyTypeId)) {
                return;
            }
            //policy-type-view
            this.get("_objNavigationService").navigateWithinContext("policy-type-view", {
                "policy-type-view": [sPolicyTypeId]
            });
        },
        getCSSPayload: function (funcCallback, funcError) {
            $.ajax(this.get("_arrAdditionalCssFiles")[0]).then(sContent => {
                funcCallback(sContent);
            }, objErr => {
                funcError(objErr);
            });
        },
        actions: {
            onSave: function () {
                if (!this.doFullValidation()) {
                    this.set("_bLoading", true);

                    this.getCSSPayload(sCssContent => {
                        var objPayload = this.formatPayload();
                        objPayload.content.css = sCssContent;
                        if (this.get("_bPromptVisible")) {
                            objPayload.prompt.css = sCssContent;
                        }

                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicyVersion"));
                        this.get("_objPolicyRestService").createPolicy(this, "_objRestAgencyGroupPolicyTypeProperty", this.get("_objUserSession").getUserContextReference().mId, () => {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicyVersionSuccess"));
                            this.navigateToViewPage();
                        }, a24RestCallHelper.createJsonAjaxFailureObject(["*", "*CODE", "*MALFORMED"], (sCode, sStatus, objErrorThrown, objError) => {
                            if (objError.arrPath[objError.arrPath.length - 1] === "html") {
                                objError.arrPath = [objError.arrPath.join(".")];
                            }

                            if (sCode === "EFFECTIVE_AT_BEFORE_PREVIOUS_VERSION") {
                                this.setResponseError(objError.arrPath, "validationEffectiveAtBeforePrev", false, false, this.get("_objPolicyStringsService"));
                            } else if (sCode === "EFFECTIVE_AT_IN_THE_PAST") {
                                this.setResponseError(objError.arrPath, "validationDateInPast");
                            } else if (sCode === "MIN_LENGTH") {
                                this.setResponseError(objError.arrPath, "validationRestMinLength");
                            } else if (sCode === "MAX_LENGTH") {
                                this.setResponseError(objError.arrPath, "validationRestMaxLength");
                            } else if (sCode === "PATTERN") {
                                this.setResponseError(objError.arrPath, "validationRestInvalidChar");
                            } else if (sCode === "POLICY_TYPE_ALREADY_IN_USE") {
                                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("createPolicyVersionClassificationDiffers"));
                            } else {
                                this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicyVersionFailed"));
                            }

                            this.set("_bLoading", false);
                        }), objPayload);
                    }, () => {

                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objPolicyStringsService").getString("creatingPolicyVersionFailed"));

                        this.set("_bLoading", false);
                    });
                }
            },
            onCancel: function () {

                // Check if the form has any unsaved data
                var bDataMatches = a24Core.compareObjects(Ember, this.getPlainFormValueObject(), this.get("_objOriginalData"));

                if (bDataMatches) {
                    this.navigateToViewPage();
                } else {
                    this.set("_bShowUnsavedData", true);
                }
            },
            onDownloadPDFPreview: function () {
                if (!this.doFullValidation()) {
                    this.getCSSPayload(sCssContent => {

                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdf"));
                        var objPayload = this.formatPayload();

                        var sFullName = this.get("_objUserSession").getUserFullName();
                        var sEmailAddress = this.get("_objUserSession").getUserEmail();
                        var sCurrentDateTime = momentHelper.getCurrentDateString(this.get("_objConstantsEmberLibService").DATE_FORMAT_ISO_FORMAT);

                        // payload to send to the pdf preview function call
                        var objPayloadForPdf = {
                            content: objPayload.content.html,
                            content_css: sCssContent,
                            prompt_css: sCssContent,
                            tokens: {
                                effective_at: objPayload.effective_at,
                                actioned_at: sCurrentDateTime,
                                candidate_full_name: sFullName,
                                candidate_email: sEmailAddress
                            }
                        };

                        if (this.get("_bPromptVisible")) {
                            objPayloadForPdf.prompt = objPayload.prompt.html;
                        }

                        this.get("_objPolicyRestService").downloadPdfByPOST(objPayload.policy_type_id, this.get("_iNewVersion"), () => {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFinish"));
                        }, () => {
                            this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFailed"));
                        }, objPayloadForPdf);
                    }, () => {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFailed"));
                    });
                }
            },
            onPopupUnsavedConfirm: function () {
                this.navigateToViewPage();
            }
        }
    });
});