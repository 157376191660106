define("a24-ember-window/mixins/did-render-changes-mixin", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Mixin.create({
        _objDidRenderService: Ember.inject.service("component-did-render"),
        _objDomService: Ember.inject.service("dom-event"),
        init: function () {
            this._super(...arguments);
            this.get("_objDomService");
        },
        didRender: function () {
            this._super(...arguments);
            this.sizeChanges();
        },
        sizeChanges: Ember.observer("_objDomService.iPageWidth", "_objDomService.iPageHeight", function () {
            this.get("_objDidRenderService").countRendered();
        })
    });
});