define("a24-ember-menu/components/left-menu", ["exports", "a24-ember-menu/templates/components/left-menu", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _leftMenu, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Services
        */
        objMenuService: Ember.inject.service("left-menu-service"),

        /*
         * Default values
        */
        layout: _leftMenu.default,

        classNames: ["left-menu"],

        /*
         * @property sLoaderComponent
         * The loader component to render if this component is in a loading state
        */
        sLoaderComponent: null,

        /*
         * Computed properties
        */
        /*
         * @property bLoading
         * True if this component is in a loading state
        */
        bLoading: Ember.computed.readOnly("objMenuService.bLoading"),

        arrMenuItems: Ember.computed("objMenuService.arrMenuItems.[]", function () {
            return this._sortMenuItems(Ember.get(this, "objMenuService.arrMenuItems"));
        }),

        /*
         * Actions
        */
        actions: {
            onClickMenuItem() {
                Ember.get(this, "onMenuItemClick")(...arguments);
                Ember.get(this, "objMenuService").onClickMenuItem(...arguments);
            }
        },

        _sortMenuItems(arrMenuItems) {
            if (a24Core.isEmpty(arrMenuItems)) {
                return [];
            }
            var funcSorter = (objMenuItem1, objMenuItem2) => {
                return Ember.get(objMenuItem1, "iOrder") - Ember.get(objMenuItem2, "iOrder");
            };

            arrMenuItems.sort(funcSorter);

            arrMenuItems.forEach(objMenuItem => {
                let arrSubMenuItems = Ember.get(objMenuItem, "arrSubMenuItems");
                if (!a24Core.isEmpty(arrSubMenuItems)) {
                    arrSubMenuItems.sort(funcSorter);
                }
            });

            return arrMenuItems;
        }
    });
});