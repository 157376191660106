define("a24-ember-menu/components/collapsible-left-menu-item", ["exports", "a24-ember-menu/templates/components/collapsible-left-menu-item", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _collapsibleLeftMenuItem, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        /*
         * Default values
        */
        layout: _collapsibleLeftMenuItem.default,
        classNames: ["collapsible-left-menu-item"],

        /*
         * An object holding the data for the menu item
        */
        objMenuItem: null,

        /*
         * True if this menu item has a parent menu item
        */
        bIsSubmenuItem: false,

        /*
         * True if this menu item is expanded
        */
        bIsExpanded: false,

        /*
         * Inline computed properties
         *
         * True if this menu item has submenu items
        */
        bHasSubmenuItems: Ember.computed.notEmpty("objMenuItem.arrSubMenuItems"),

        /*
         * Lifecycle
        */
        init() {
            this._super(...arguments);
            Ember.set(this, "sCollapseId", Ember.guidFor({}));
        },

        didReceiveAttrs() {
            this._super(...arguments);
            this._setExpansionState();
        },

        didUpdateAttrs() {
            this._super(...arguments);
            this._setExpansionState();
        },

        /*
         * Actions
        */
        actions: {
            onClickMenuItem() {
                if (Ember.get(this, "bHasSubmenuItems")) {
                    this.toggleProperty("bIsExpanded");
                    let objMenuItem = Ember.get(this, "objMenuItem");
                    Ember.set(objMenuItem, "bIsSelected", Ember.get(objMenuItem, "arrSubMenuItems").isAny("bIsSelected") && !Ember.get(this, "bIsExpanded"));
                } else {
                    this.get("onClick")(...arguments);
                }
            }
        },

        _setExpansionState() {
            let objMenuItem = Ember.get(this, "objMenuItem");
            let arrSubMenuItems = Ember.A(Ember.get(objMenuItem, "arrSubMenuItems") || []);
            Ember.set(this, "bIsExpanded", arrSubMenuItems.isAny("bIsSelected") || Ember.get(objMenuItem, "bIsSelected"));
        }
    });
});