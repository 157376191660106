var a24DateManagerExtend = {
    /**
     * This is a wrapper function for creating TimeZoneDate date objects.
     *
     * @param sTimeZone - The timezone the date should be created in.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns TimeZoneDate
     */
    createDate: function(sTimeZone) {
        return TimeZoneDate.create({_sTimeZone: sTimeZone});
    },
    /**
     * This function will trim the timezone from a rest date formatted string
     * Date string MUST be in DD MMMM YYYY HH:mm:ss ZZ format
     * Only meant to be used inside the a24DateHelper.js
     *
     * @param sRestDateString - The rest date string to trim, must be in DD MMMM YYYY HH:mm:ss ZZ format
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The time zone stripped date string or null if send in value is null
     */
    _stripTimeZone: function(sRestDateString) {
        if (a24Core.isEmpty(sRestDateString)) {
            return null;
        } else {
            return sRestDateString.substr(0, sRestDateString.length - 5);
        }
    }
};

/**
 * This is the moment helper object, that wraps all
 * moment.js functions, so that we can have them in
 * a central place.
 */
var momentHelperExtend = {
    /**
     * This function will return all the timezone offset change details for the passed in timezone.
     *
     * @param sTimeZone - The timezone to retrieve offset change details
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Object - All the timezone offset change details for the passed in timezone.
     */
    getTimeZoneChangesDetails: function(sTimeZone) {
        return moment.tz.zone(sTimeZone);
    },
    /**
     * This function will filter the timezone offset details to only return the details of the range specified.
     * If the end year is null then the end year will be set the same as the start year.
     *
     * @param objTimeZoneChanges - The timezone offset change details
     * @param iStartYear - The year to start filtering from
     * @param iEndYear - The year to end filtering on
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Object - The filtered timezone offset change details.
     */
    getTimeZoneChangesFiltered: function(objTimeZoneChanges, iStartYear, iEndYear) {
        return moment.tz.filterYears(objTimeZoneChanges, iStartYear, iEndYear);
    },
    /**
     * This function will take a date string and convert it to date string with the correct offset for the passed in
     * timezone and make sure that the date and time is NOT affected by the change.
     *
     * E.g of Europe/London timezone:
     *    IN: 12 June 2014 05:00:00 +0200
     *   out: 12 June 2014 05:00:00 +0100
     *
     * @param sDate - The date to convert (Must be in "DD MMMM YYYY HH:mm:ss ZZ" format)
     * @param sTimeZone - The timezone to convert the date to.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The date string with the correct date, time and now also offset.
     */
    duplicateDateAndTimeToTimeZone: function(sDate, sTimeZone) {
        var sDateWithoutTZ = a24DateManager._stripTimeZone(sDate);
        return moment.tz(
            sDateWithoutTZ,
            a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
            sTimeZone
        ).format(a24Constants.DATE_FORMAT_REST_FORMAT);
    },
    /**
     * This function will get the full year from the passed in date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The full year from the passed in date string
     */
    getFullYear: function(sDate, sParseFormat) {
        return this._stringToMoment(sDate, sParseFormat).get(a24Constants.MOMENT_TIME_YEARS);
    },
    /**
     * This function will get the time stamp of the passed in date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns This function will get the time stamp of the passed in date string
     */
    getTime: function(sDate, sParseFormat) {
        return this._stringToMoment(sDate, sParseFormat).unix();
    },
    /**
     * This function will get a date string for the current date
     *
     * @param sDisplayFormat - The format to display the date in
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns A date string for the current date
     */
    getCurrentDateString: function(sDisplayFormat) {
        return this._currentDateToMoment().format(sDisplayFormat);
    },
    /**
     * This method will parse a string, and format it
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sDisplayFormat - The format to display the date in
     *
     * @return The formatted string
     */
    stringFormat: function(sDate, sParseFormat, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).format(sDisplayFormat);
    },
    /**
     * This method will return a moment object with the current date
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 11 July 2016
     *
     * @return The moment object
     */
    _currentDateToMoment: function() {
        return moment();
    },
    /**
     * This function simply returns the current timestamp in milliseconds
     *
     * This function is allowed to be used outside of the Date Helper since the date helper was not written to be
     * more specific than seconds. Thus if you try and get the time stamp from the date helper is will always round
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 03 May 2017
     *
     * @return The timestamp in milliseconds
     */
    getTimezoneInMilli: function() {
        return moment.tz(a24Constants.MOMENT_TIMEZONE_UTC).valueOf();
    },
    /**
     * This method will parse a string, and return the moment object
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     *
     * @return The moment object
     */
    _stringToMoment: function(sDate, sParseFormat) {
        return moment(sDate, sParseFormat);
    },
    /**
     * This method will parse a time stamp, and return the moment object
     *
     * @param iTime - The time stamp
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @return The moment object
     */
    _timeToMoment: function(iTime) {
        return moment(iTime);
    },
    /**
     * This method will calculate the difference of two dates, and
     * return it in the given time unit.
     *
     * @param sDate1 - The first string date
     * @param sParseFormat1 - The format to use to parse the first string date
     * @param sDate2 - The second string date
     * @param sParseFormat2 - The format to use to parse the second string date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param bFloatPoint - Whether to return a float value that is NOT rounded down.(moment will round DOWN by default)
     *
     * @return The time difference in the given time unit
     */
    stringDiffInUnits: function(sDate1, sParseFormat1, sDate2, sParseFormat2, sTimeUnit, bFloatPoint) {
        var objDate1 = this._stringToMoment(
            sDate1,
            sParseFormat1
        );
        var objDate2 = this._stringToMoment(
            sDate2,
            sParseFormat2
        );
        if (a24Core.isEmpty(bFloatPoint)) {
            bFloatPoint = false;
        }
        return objDate1.diff(objDate2, sTimeUnit, bFloatPoint);
    },
    /**
     * This method will format a date object
     *
     * @param objDate - The object date we want to format
     * @param sDisplayFormat - The format to display the date in
     *
     * @return The formatted string
     */
    dateFormat: function(objDate, sDisplayFormat) {
        var sDate = objDate.toISOString();
        return this.stringFormat(sDate, a24Constants.DATE_FORMAT_ISO_FORMAT, sDisplayFormat);
    },
    /**
     * This method will parse a time stamp, and format it to the given timezone
     *
     * @param iTimeStamp - The time stamp in milliseconds.
     * @param sTimezone - The timezone to convert date to
     * @param sDisplayFormat - The format to display the date in
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @return The formatted string
     */
    timeStampTimezoneFormat: function(iTimeStamp, sTimezone, sDisplayFormat) {
        return this._timeToMoment(iTimeStamp).tz(sTimezone).format(sDisplayFormat);
    },
    /**
     * This method will parse a string, and format it to the given timezone
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimezone - The timezone to convert date to
     * @param sDisplayFormat - The format to display the date in
     *
     * @return The formatted string
     */
    stringTimezoneFormat: function(sDate, sParseFormat, sTimezone, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).tz(sTimezone).format(sDisplayFormat);
    },
    /**
     * This method will parse a string to a date object
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     *
     * @return The date object
     */
    stringToDate: function(sDate, sParseFormat) {
        return this._stringToMoment(sDate, sParseFormat).toDate();
    },
    /**
     * This method will parse a string and add time to the given date and return a date object.
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     *
     * @return The date object
     */
    stringAddTimeToDate: function(sDate, sParseFormat, sTimeUnit, iDuration) {
        return this._stringToMoment(sDate, sParseFormat).add(iDuration, sTimeUnit).toDate();
    },
    /**
     * This method will parse a string and add time to the given date and return the formatted date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     * @param sDisplayFormat - The format to display the date in
     *
     * @return The formatted date string
     */
    stringAddTimeToString: function(sDate, sParseFormat, sTimeUnit, iDuration, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).add(iDuration, sTimeUnit).format(sDisplayFormat);
    },
    /**
     * This method will parse a string and subtract time from the given date and return the formatted date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to subtract from time
     * @param sDisplayFormat - The format to display the date in
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 Movember 2015
     *
     * @return The formatted date string
     */
    stringSubtractTimeFromString: function(sDate, sParseFormat, sTimeUnit, iDuration, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).subtract(iDuration, sTimeUnit).format(sDisplayFormat);
    },
    /**
     * This method will change the date to the start of the specified time unit and return the formatted date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. week, month, second, year, etc
     * @param sDisplayFormat - The format to display the date in
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 Movember 2015
     *
     * @return The formatted date string
     */
    stringStartOfString: function(sDate, sParseFormat, sTimeUnit, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).startOf(sTimeUnit).format(sDisplayFormat);
    },
    /**
     * This method will change the date to the end of the specified time unit and return the formatted date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. week, month, second, year, etc
     * @param sDisplayFormat - The format to display the date in
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 Movember 2015
     *
     * @return The formatted date string
     */
    stringEndOfString: function(sDate, sParseFormat, sTimeUnit, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).endOf(sTimeUnit).format(sDisplayFormat);
    },
    /**
     * This method will parse a string and get the time unit specified.
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     *
     * @return The time unit
     */
    stringGetTimeToInt: function(sDate, sParseFormat, sTimeUnit) {
        return this._stringToMoment(sDate, sParseFormat).get(sTimeUnit);
    },
    /**
     * This method will parse a string and set time to the given time unit and return the formatted date string
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     * @param sDisplayFormat - The format to display the date in
     *
     * @return The formatted date string
     */
    stringSetTimeToString: function(sDate, sParseFormat, sTimeUnit, iDuration, sDisplayFormat) {
        return this._stringToMoment(sDate, sParseFormat).set(sTimeUnit, iDuration).format(sDisplayFormat);
    },
    /**
     * This method will parse a string and get the iso weekday.
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     *
     * @returns The weekday as an int value
     */
    stringGetWeekday: function(sDate, sParseFormat) {
        return this._stringToMoment(sDate, sParseFormat).isoWeekday();
    },
    /**
     * This method will add time to the given date and return a date object.
     *
     * @param objDate - The object date
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     *
     * @return The date object
     */
    dateAddTimeToDate: function(objDate, sTimeUnit, iDuration) {
        var sDate = objDate.toISOString();
        return this.stringAddTimeToDate(sDate, a24Constants.DATE_FORMAT_ISO_FORMAT, sTimeUnit, iDuration);
    },
    /**
     * This function will check whether the first date is after the second date
     *
     * @param sDateOne - The first string date
     * @param sParseFormatOne - The first date format to use to parse the string date
     * @param sDateTwo - The second string date
     * @param sParseFormatTwo - The second format to use to parse the string date
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @return Whether the first date is after the second date
     */
    isAfter: function(sDateOne, sParseFormatOne, sDateTwo, sParseFormatTwo) {
        var objMomentDateOne = this._stringToMoment(sDateOne, sParseFormatOne);
        var objMomentDateTwo = this._stringToMoment(sDateTwo, sParseFormatTwo);
        return objMomentDateOne.isAfter(objMomentDateTwo);
    },
    /**
     * This function will check whether the first date is before the second date
     *
     * @param sDateOne - The first string date
     * @param sParseFormatOne - The first date format to use to parse the string date
     * @param sDateTwo - The second string date
     * @param sParseFormatTwo - The second format to use to parse the string date
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @return Whether the first date is before the second date
     */
    isBefore: function(sDateOne, sParseFormatOne, sDateTwo, sParseFormatTwo) {
        var objMomentDateOne = this._stringToMoment(sDateOne, sParseFormatOne);
        var objMomentDateTwo = this._stringToMoment(sDateTwo, sParseFormatTwo);
        return objMomentDateOne.isBefore(objMomentDateTwo);
    },
    /**
     * This method will get the moment of the duration passed in.
     *
     * @param iDuration - The duration
     * @param sTimeUnit - The time unit the duration is in
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 June 2016
     *
     * @returns The moment of the duration
     */
    duration: function(iDuration, sTimeUnit) {
        return moment.duration(iDuration, sTimeUnit);
    },
    /**
     * This function will try and guess the users timezone as best it can
     * See https://momentjs.com/timezone/docs/#/using-timezones/guessing-user-timezone/
     *
     * NOTE: This is one of a few moment function allowed to be used outside of the _objTimeZoneDate object, since it is a
     *       stand alone moment funtion
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 08 Mar 2017
     *
     * @returns The users timezone based on browser, e.g "Europe/London"
     */
    timezoneGuess: function() {
        return moment.tz.guess();
    },
    /**
     * This method will parse a string, and return a from now string format
     *
     * NOTE: This is one of a few moment function allowed to be used outside of the _objTimeZoneDate object, since it is a
     *       stand alone moment funtion
     *
     * @param sDate - The string date
     * @param sParseFormat - The format to use to parse the string date
     *
     * @author Michael Barnard <michael.barnard@a24group.com>
     * @since  19 May 2017
     *
     * @return A from now date string
     */
    getTimeFromNow: function(sDate, sParseFormat) {
        return this._stringToMoment(sDate, sParseFormat).fromNow();
    },

    /**
     * This method will return an array of all possible timezones
     *
     * @author Deon De Wet <deon.dewet@a24group.com>
     * @since  14 Dec 2017
     *
     * @return array of timezones
     */
    getTimezones: function() {
        return moment.tz.names();
    }
};

/**
 * This is the time zone date object that will take into account the timezone set on it at creation of this object.
 *
 * Please see the readme for more details and exactly how to use this object.
 *
 * @author Ruan Naude <ruan.naude@a24group.com>
 * @since 01 October 2014
 */
var _objTimeZoneDateExtend = {
    //This is the date string value of the date object. Always in DD MMMM YYYY HH:mm:ss ZZ format
    _sDate: "",
    //This is the timezone set on creation.
    _sTimeZone: "",
    //This flag will tell us if moment shifted the date to a new offset because of the time and offset not
    //existing in the current locale
    _bMomentShift: false,
    //This object holds the time offset changes details for the set timezone
    _objTimeZoneChanges: null,
    init: function() {
        this._super();

        //Set the date to the current date by default.
        this.set("_sDate", momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));
        this._formatDate();
    },
    /**
     * This function will determine if the date set on this date object is on the daylight saving/offset switch time and
     * return details about the shift.
     *
     * For instance Europe/London has a time switch from 1am to 2am on 30 March 2014.
     * So if i select 30 March 2014 01:30am this function will return the following object:
     *
     * {
     *      isOnDSSwitch: true,
     *      isForwardJump: true,
     *      sBeforeDate: "30 March 2014 00:30:00 +0000",
     *      sAfterDate: "30 March 2014 02:30:00 +0100"
     *  };
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Object with the daylight savings details OR null if the date is not on daylight saving shift.
     */
    getDayLightSavingsDetails: function() {
        //Get the offset changes for the timezone
        if (a24Core.isEmpty(this.get("_objTimeZoneChanges"))) {
            this.set("_objTimeZoneChanges", momentHelper.getTimeZoneChangesDetails(this.get("_sTimeZone")));
        }
        var objDaylightDetails = {
            isOnDSSwitch: false,
            isForwardJump: false,
            sBeforeDate: "",
            sAfterDate: ""
        };
        var sSetDate = this.get("_sDate");

        //Get the timestamp of the date object
        //Multiply by 1000 to get it to milliseconds
        var objDateTimeStamp = momentHelper.getTime(sSetDate, a24Constants.DATE_FORMAT_REST_FORMAT) *
            a24Constants.MILLISECONDS_1_SECOND;

        //Get only the offset changes for the current year.
        var objYearChanges = momentHelper.getTimeZoneChangesFiltered(
            this.get("_objTimeZoneChanges"),
            momentHelper.getFullYear(sSetDate, a24Constants.DATE_FORMAT_REST_FORMAT)
        );
        var iUntilsLength = objYearChanges.untils.length;

        //If there is only one offset for current year then there was no daylight changes for the year the date is in
        if (iUntilsLength > 1) {

            //Loop over the offset changes and check if the timestamp of this date object is within
            //24 hours range of any of the offset changes.
            for (var i = 0; i < iUntilsLength; i++) {
                //For Europe/London (Crib notes to better understand some of calculations below)
                //1am to 2am shift = +0000 to +0100 ------> Daylight saving START
                //2am to 1am shift = +0100 to +0000 ------> Daylight saving END
                //60000 milliseconds = 1min
                //86400000 milliseconds = 24hours

                //Each until is the timestamp on which the offset change happens.
                var iUntil = objYearChanges.untils[i];
                if (!a24Core.isEmpty(iUntil)) { //If the time iUntil is null it means its the last item for the year.

                    //Check if the timestamp of this date object is at least within 24 hours range of the offset change.
                    //This is to avoid unnecessary further calculations.
                    if (
                        objDateTimeStamp > iUntil - a24Constants.MILLISECONDS_1_DAY &&
                        objDateTimeStamp < iUntil + a24Constants.MILLISECONDS_1_DAY
                    ) {
                        //Now we need to check if the timestamp of the date object falls within the offset switch period
                        //To do that we need to know the time duration effect the offset switch will have, e.g
                        //When Europe/London does 1am to 2am shift(+0000 to +0100(DS Start)), that has 1 hour effect.
                        //In the case of a forward jump like above the switch period is: from until to (until + effect)
                        //In the case of a backward jump it would be: from (until - effect) to (until + effect)
                        var sTimeZone = this.get("_sTimeZone");
                        var iOffsetOne = objYearChanges.offsets[i];
                        var iOffsetTwo = objYearChanges.offsets[i + 1];

                        var iOffsetDif = iOffsetOne - iOffsetTwo;
                        var iDayLightSpan = Math.abs(iOffsetDif) * a24Constants.MILLISECONDS_1_MINUTE;

                        var iDSSwitchStart = iUntil - iDayLightSpan;
                        var iDSSwitchEnd = iUntil + iDayLightSpan;

                        //Check if the timestamp of the date object falls within daylight savings switch period.
                        if (objDateTimeStamp >= iDSSwitchStart && objDateTimeStamp <= iDSSwitchEnd) {
                            //positive offset dif means jump forward
                            // e.g 1am to 2am shift = +0000 to +0100 ------> Daylight saving START
                            //and negative is back jump
                            // e.g 2am to 1am shift = +0100 to +0000 ------> Daylight saving END
                            if (iOffsetDif > 0) {
                                //Jump forward
                                //In this case we only care about if the timestamp of the date object falls within
                                //until -> (until + effect)
                                if (objDateTimeStamp >= iUntil && objDateTimeStamp <= iDSSwitchEnd) {
                                    //In the london forward jump example 1:30 +0000 and 2:30 +0100 would have the exact
                                    //same timestamp and both fall within the offset switch range. However 2:30 is a
                                    //valid time that falls outside the DS switch from the USER prospective. Thus we
                                    //use moment to determine the difference between the two. Moment will shift a time
                                    //if the time does not exist for a timezone.
                                    //Thus 1:30 will change to 2:30 through moment and 2:30 will stay 2:30
                                    if (this.get("_bMomentShift") == true) {
                                        objDaylightDetails.isOnDSSwitch = true;
                                        objDaylightDetails.isForwardJump = true;
                                        objDaylightDetails.sBeforeDate = momentHelper.timeStampTimezoneFormat(
                                            objDateTimeStamp - iDayLightSpan,
                                            sTimeZone,
                                            a24Constants.DATE_FORMAT_REST_FORMAT
                                        );
                                        objDaylightDetails.sAfterDate = momentHelper.timeStampTimezoneFormat(
                                            objDateTimeStamp,
                                            sTimeZone,
                                            a24Constants.DATE_FORMAT_REST_FORMAT
                                        );
                                    }
                                }
                            } else {
                                //Jump Back
                                //In this case we only care about if the timestamp of the date object falls within
                                //(until - effect) to (until + effect)
                                //Check if the timestamp falls before or after the change to work out the correct
                                //before and after dates.
                                if (objDateTimeStamp >= iDSSwitchStart && objDateTimeStamp <= iUntil) {
                                    objDaylightDetails.isOnDSSwitch = true;
                                    objDaylightDetails.sBeforeDate = momentHelper.timeStampTimezoneFormat(
                                        objDateTimeStamp,
                                        sTimeZone,
                                        a24Constants.DATE_FORMAT_REST_FORMAT
                                    );
                                    objDaylightDetails.sAfterDate = momentHelper.timeStampTimezoneFormat(
                                        objDateTimeStamp + iDayLightSpan,
                                        sTimeZone,
                                        a24Constants.DATE_FORMAT_REST_FORMAT
                                    );
                                } else if (objDateTimeStamp >= iUntil && objDateTimeStamp < iDSSwitchEnd) {
                                    objDaylightDetails.isOnDSSwitch = true;
                                    objDaylightDetails.sBeforeDate = momentHelper.timeStampTimezoneFormat(
                                        objDateTimeStamp - iDayLightSpan,
                                        sTimeZone,
                                        a24Constants.DATE_FORMAT_REST_FORMAT
                                    );
                                    objDaylightDetails.sAfterDate = momentHelper.timeStampTimezoneFormat(
                                        objDateTimeStamp,
                                        sTimeZone,
                                        a24Constants.DATE_FORMAT_REST_FORMAT
                                    );
                                }
                            }
                        }
                        break;
                    }
                }
            }
        }

        if (objDaylightDetails.isOnDSSwitch) {
            return objDaylightDetails;
        } else {
            return null;
        }
    },
    /**
     * This function will be used to set the date from the rest side.
     * In this case we will assume that the rest gave us the correct offset
     * and thus NOT change it, unless no date is given and we set it to the current date.
     *
     * @param sDate - The date string from rest (MUST always be in a24Constants.DATE_FORMAT_REST_FORMAT)
     * @param bDateOnly - Whether the date from the backend is a date only date, thus has no time on it, if true
     *     then the sDate is allowed to be DATE_FORMAT_REST_FORMAT_DATE_ONLY format
     * @param bMonthYearOnly - Whether the date from the backend is a month year only date, thus has no time or day on
     *     it, if true then the sDate is allowed to be DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY format
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     */
    setDateFromRest: function(sDate, bDateOnly, bMonthYearOnly) {
        //no _formatDate required since we assume the rest will give us a correct and valid date with time offset.
        //unless no date is given we set to the current date and format to the correct offset(timezone)
        this.set("_bMomentShift", false);
        if (a24Core.isEmpty(sDate)) {
            this.set("_sDate", momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));
            this._formatDate();
        } else {
            if (bDateOnly) {
                this.set(
                    "_sDate",
                    momentHelper.stringFormat(
                        sDate,
                        a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY,
                        a24Constants.DATE_FORMAT_REST_FORMAT
                    )
                );
            } else if (bMonthYearOnly) {
                this.set(
                    "_sDate",
                    momentHelper.stringFormat(
                        sDate,
                        a24Constants.DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY,
                        a24Constants.DATE_FORMAT_REST_FORMAT
                    )
                );
            } else {
                this.set("_sDate", sDate);
            }
            this._formatDate(false);
        }
    },
    /**
     * This function will be used to set the date from the rest side.
     * In this case we will NOT assume that the rest gave us the correct offset and CHANGE to the
     * correct offset based on the timezone set on this object.
     * This means that time will be altered IF the offset was changed.
     *
     * @param sDate - The date string from rest (MUST always be in a24Constants.DATE_FORMAT_REST_FORMAT)
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 20 October 2014
     */
    setDateFromRestAllowTimeAlter: function(sDate) {
        this.set("_bMomentShift", false);
        if (a24Core.isEmpty(sDate)) {
            this.set("_sDate", momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));
        } else {
            this.set(
                "_sDate",
                momentHelper.stringTimezoneFormat(
                    sDate,
                    a24Constants.DATE_FORMAT_REST_FORMAT,
                    this.get("_sTimeZone"),
                    a24Constants.DATE_FORMAT_REST_FORMAT
                )
            );
        }
        this._formatDate();
    },
    /**
     * This function will be used to set the date from the browser side.
     * In this case we always ignore the offset and covert it to the correct time offset base on the timezone WITHOUT
     * changing the time.
     *
     * NOTE: Can not set date with date string format containing a timezone other than DATE_FORMAT_REST_FORMAT
     *       So all date formats can be used to set the date except for ones with a timezone in.
     *       Only DATE_FORMAT_REST_FORMAT is allowed, timezone will still be ignored tho, this is just to make things
     *       easier when working from and to rest and browser.
     *
     * @param mDate - The js date object or date string to set the date to.
     * @param sParseFormat - The date format the date string is in or null if js date was used.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     */
    setDateFromBrowser: function(mDate, sParseFormat) {
        if (a24Core.isEmpty(mDate)) {
            this.set("_sDate", momentHelper.getCurrentDateString(a24Constants.DATE_FORMAT_REST_FORMAT));
        } else {
            if (typeof mDate === "string" || mDate instanceof String) {
                if (a24Constants.DATE_FORMAT_REST_FORMAT === sParseFormat) {
                    this.set("_sDate", mDate);
                } else if (sParseFormat.toLowerCase().indexOf("z") !== -1) {
                    console.warn("Can not set a date with a timezone from the browser using a format other than DATE_FORMAT_REST_FORMAT");
                } else {
                    this.set(
                        "_sDate",
                        momentHelper.stringFormat(
                            mDate,
                            sParseFormat,
                            a24Constants.DATE_FORMAT_REST_FORMAT
                        )
                    );
                }
            } else {
                //Format date using moment without a timezone, thus moment wont change times or time zone.
                this.set(
                    "_sDate",
                    momentHelper.dateFormat(
                        mDate,
                        a24Constants.DATE_FORMAT_REST_FORMAT
                    )
                );
            }
        }
        this.set("_bMomentShift", false);
        this._formatDate();
    },
    /**
     * This function will return the date as a date string that can be posted to the back end.
     * This date string will have the correct timezone offset.
     *
     * NOT safe for browser use
     *
     * @param bDateOnly - Whether the date from the backend is a date only date, thus has no time on it, if true
     *     then the sDate is allowed to be DATE_FORMAT_REST_FORMAT_DATE_ONLY format
     * @param bMonthYearOnly - Whether the date from the backend is a month year only date, thus has no time or day on
     *     it, if true then the sDate is allowed to be DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY format
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Date string that can be posted to the back end. (DD MMMM YYYY HH:mm:ss ZZ format)
     */
    getDateStringForRest: function(bDateOnly, bMonthYearOnly) {
        if (bDateOnly) {
            return this._getFormattedDate(a24Constants.DATE_FORMAT_REST_FORMAT_DATE_ONLY);
        } else if (bMonthYearOnly) {
            return this._getFormattedDate(a24Constants.DATE_FORMAT_REST_FORMAT_MONTH_YEAR_ONLY);
        } else {
            return this.get("_sDate");
        }
    },
    /**
     * This function will return the date as a date string that can be used in the browser.
     * This date string will not have the timezone offset on it thus when using this string it will not alter the time.
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Date string that can be used in the browser (DD MMMM YYYY HH:mm:ss format)
     */
    getDateStringForBrowserUse: function() {
        var sDate = this.get("_sDate");
        return a24DateManager._stripTimeZone(sDate);
    },
    /**
     * This function will return the date formatted as DD MMM YYYY
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The date formatted as DD MMM YYYY
     */
    getDateFormat: function() {
        return this._getFormattedDate(a24Constants.DATE_FORMAT);
    },
    /**
     * This function will return the date formatted as DD MMMM YYYY HH:mm:ss
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The date formatted as DD MMM YYYY HH:mm
     */
    getDateTimeFormat: function() {
        return this._getFormattedDate(a24Constants.DATE_TIME_FORMAT);
    },
    /**
     * This function will return the date formatted as DD MMMM YYYY - HH:mm
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The date formatted as DD MMMM YYYY - HH:mm
     */
    getDateTimeDashFormat: function() {
        return this._getFormattedDate(a24Constants.DATE_TIME_DASH_FORMAT);
    },
    /**
     * This function will return the date formatted as HH:mm
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The date formatted as HH:mm
     */
    getTimeFormat: function() {
        return this._getFormattedDate(a24Constants.TIME_FORMAT);
    },
    /**
     * This function will return the date formatted as YYYY
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 23 March 2015
     *
     * @returns The date formatted as YYYY
     */
    getYearFormat: function() {
        return this._getFormattedDate(a24Constants.YEAR_FORMAT);
    },
    /**
     * This function will return the date formatted as MMM
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 23 March 2015
     *
     * @returns The date formatted as MMM
     */
    getMonthFormat: function() {
        return this._getFormattedDate(a24Constants.MONTH_FORMAT);
    },
    /**
     * This function will return the date formatted as DD
     *
     * SAFE for browser use
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 23 March 2015
     *
     * @returns The date formatted as DD
     */
    getDayFormat: function() {
        return this._getFormattedDate(a24Constants.DAY_FORMAT);
    },

    /**
     * This function will return the date formatted as MMM YYYY
     *
     * SAFE for browser use
     *
     * @author Jacques Slabber <jacques.slabber@a24group.com>
     * @since 21 February 2017
     *
     * @returns The date formatted as MMM YYYY
     */
    getMonthYearFormat: function() {
        return this._getFormattedDate(a24Constants.MONTH_YEAR_FORMAT);
    },

    /**
     * This function will add time to the date set on this date object.
     *
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     */
    addTimeToDate: function(sTimeUnit, iDuration) {
        this.setDateFromBrowser(
            momentHelper.stringAddTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                sTimeUnit,
                iDuration,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This function will subtract time from the date set on this date object.
     *
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to subtract from time
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 November 2015
     */
    subtractTimeFromDate: function(sTimeUnit, iDuration) {
        this.setDateFromBrowser(
            momentHelper.stringSubtractTimeFromString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                sTimeUnit,
                iDuration,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This method will change the date to the start of the specified time unit
     *
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 November 2015
     */
    startOf: function(sTimeUnit) {
        this.setDateFromBrowser(
            momentHelper.stringStartOfString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                sTimeUnit,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This method will change the date to the end of the specified time unit
     *
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 November 2015
     */
    endOf: function(sTimeUnit) {
        this.setDateFromBrowser(
            momentHelper.stringEndOfString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                sTimeUnit,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * Method will get a new TimeZoneDate object by means of adding the passed in duration and time unit
     *
     * The date will be based on the current date object you are calling the function on, and not your current date
     *
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     *
     * @author Neil Nienaber <neil.nienaber@a24group.com>
     * @since  05 November 2015
     *
     * @returns TimeZoneDate The new TimeZoneDate object
     */
    getDateFromCurrentDate: function(sTimeUnit, iDuration) {
        var objNewDate = a24DateManager.createCurrentDate();
        var sDate = momentHelper.stringAddTimeToString(
            this._sDate,
            a24Constants.DATE_FORMAT_REST_FORMAT,
            sTimeUnit,
            iDuration,
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
        objNewDate.setDateFromRest(sDate);
        return objNewDate;
    },
    /**
     * This function will set time on the date set on this date object.
     *
     * @param sTimeUnit - The time unit, e.g. month, second, year, etc
     * @param iDuration - The duration to add time
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     */
    setTimeOnDate: function(sTimeUnit, iDuration) {
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                sTimeUnit,
                iDuration,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This function will set time on the date to the start of the day 00:00:00
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 November 2015
     */
    setTimeToStartOfDay: function() {
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                a24Constants.MOMENT_TIME_HOURS,
                0,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                a24Constants.MOMENT_TIME_MINUTES,
                0,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                a24Constants.MOMENT_TIME_SECONDS,
                0,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This function will set time on the date to the start of the day 23:59:59
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 13 November 2015
     */
    setTimeToEndOfDay: function() {
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                a24Constants.MOMENT_TIME_HOURS,
                a24Constants.MAX_END_HOUR,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                a24Constants.MOMENT_TIME_MINUTES,
                a24Constants.MAX_END_MIN,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
        this.setDateFromBrowser(
            momentHelper.stringSetTimeToString(
                this.getDateStringForBrowserUse(),
                a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
                a24Constants.MOMENT_TIME_SECONDS,
                a24Constants.MAX_END_SEC,
                a24Constants.DATE_FORMAT_REST_FORMAT
            ),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This function will get the time on the date object.
     *
     * SAFE for browser use.
     *
     * @param sTimeUnit - The time unit to retrieve e.g. month, second, year, etc
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The time unit (It is expected that these would be int)
     */
    getTimeOnDate: function(sTimeUnit) {
        return momentHelper.stringGetTimeToInt(
            this.getDateStringForBrowserUse(),
            a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
            sTimeUnit
        );
    },
    /**
     * This function will check if the date object is after the passed in date object
     *
     * @param objTimeZoneDate - TimeZoneDate The time zone date object to perform check against
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Whether the date object is after the passed in date object.
     */
    isAfter: function(objTimeZoneDate) {
        return momentHelper.isAfter(
            this.get("_sDate"),
            a24Constants.DATE_FORMAT_REST_FORMAT,
            objTimeZoneDate.get("_sDate"),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This function will check if the date object is after the passed in date object
     *
     * @param objTimeZoneDate - TimeZoneDate The time zone date object to perform check against
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns Whether the date object is after the passed in date object.
     */
    isBefore: function(objTimeZoneDate) {
        return momentHelper.isBefore(
            this.get("_sDate"),
            a24Constants.DATE_FORMAT_REST_FORMAT,
            objTimeZoneDate.get("_sDate"),
            a24Constants.DATE_FORMAT_REST_FORMAT
        );
    },
    /**
     * This function will check what the difference is between the date object and the passed in date object and return
     * the difference in the specified units, the calculation will be:
     *     this - objTimeZoneDate
     *
     * @param objTimeZoneDate - TimeZoneDate The time zone date object to perform difference on
     * @param sTimeUnit - The unit in which to return the difference.
     * @param bFloatPoint - Whether to return a float point value. If false it will round DOWN.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The difference in the specified unit.
     */
    difference: function(objTimeZoneDate, sTimeUnit, bFloatPoint) {
        return momentHelper.stringDiffInUnits(
            this.get("_sDate"),
            a24Constants.DATE_FORMAT_REST_FORMAT,
            objTimeZoneDate.get("_sDate"),
            a24Constants.DATE_FORMAT_REST_FORMAT,
            sTimeUnit,
            bFloatPoint
        );
    },
    /**
     * This function converts the duration to the time unit being specified
     * Example:
     *  - iDuration = 12
     *  - sTimeUnitInput = 'months'
     *  - sTimeUnitOutput = 'years'
     *  - return 1
     *
     * @param iDuration - The Duration of time
     * @param sTimeUnitInput - The time unit the duration is in
     * @param sTimeUnitOutput - The time unit you want it to be returned in
     *
     * @author Junior van Wyk <johannes.vanwyk@a24group.com>
     * @since  16 June 2016
     *
     * @returns The difference in the specified unit.
     */
    duration: function(iDuration, sTimeUnitInput, sTimeUnitOutput) {
        var momentDuration = momentHelper.duration(
            iDuration,
            sTimeUnitInput
        );

        switch (sTimeUnitOutput) {
            case a24Constants.MOMENT_TIME_SECONDS:
                iDuration = momentDuration.asSeconds();
                break;
            case a24Constants.MOMENT_TIME_MINUTES:
                iDuration = momentDuration.asMinutes();
                break;
            case a24Constants.MOMENT_TIME_HOURS:
                iDuration = momentDuration.asHours();
                break;
            case a24Constants.MOMENT_TIME_DAYS:
                iDuration = momentDuration.asDays();
                break;
            case a24Constants.MOMENT_TIME_WEEKS:
                iDuration = momentDuration.asWeeks();
                break;
            case a24Constants.MOMENT_TIME_MONTHS:
                iDuration = momentDuration.asMonths();
                break;
            case a24Constants.MOMENT_TIME_YEARS:
                iDuration = momentDuration.asYears();
                break;
        }
        return iDuration;
    },
    /**
     * This function will format the date set on the date object to be in the correct timezone offset WITHOUT changing
     * the time, UNLESS the time is not valid in the offset for the timezone in which case moment will shift it to a
     * valid offset and change the time (timestamp will still be the same).
     *
     * If moment changed the time we set a flag to indicate this.
     *
     * @param bSetDate - Whether to set the formatted date as the new date. True by default.
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The formatted date with the correct offset.
     */
    _formatDate: function(bSetDate) {
        var sDate = this.get("_sDate");
        var sFormattedDate = momentHelper.duplicateDateAndTimeToTimeZone(sDate, this.get("_sTimeZone"));
        if (a24DateManager._stripTimeZone(sDate) !== a24DateManager._stripTimeZone(sFormattedDate)) {
            this.set("_bMomentShift", true);
        }
        if (a24Core.isEmpty(bSetDate)) {
            this.set("_sDate", sFormattedDate);
        }
        return sFormattedDate;
    },
    /**
     * This function will return a formatted date string, not to be used to get a
     * date format with a timezone, that is what the getDateStringForRest is for
     *
     * @param sFormat - The format to format the date string to
     *
     * @author Ruan Naude <ruan.naude@a24group.com>
     * @since 01 October 2014
     *
     * @returns The formatted date string
     */
    _getFormattedDate: function(sFormat) {
        return momentHelper.stringFormat(
            this.getDateStringForBrowserUse(),
            a24Constants.DATE_FORMAT_REST_FORMAT_WITHOUT_TZ,
            sFormat
        );
    }
};

if (typeof a24DateManager === "undefined") {
    var a24DateManager = {};
}
if (typeof momentHelper === "undefined") {
    var momentHelper = {};
}
if (typeof _objTimeZoneDate === "undefined") {
    var _objTimeZoneDate = {};
}

//Only add the extended values to the global if they do not exist on it already
Object.keys(a24DateManagerExtend).forEach(function(sKey) {
    if (!a24DateManager.hasOwnProperty(sKey)) {
        a24DateManager[sKey] = a24DateManagerExtend[sKey];
    }
});
Object.keys(momentHelperExtend).forEach(function(sKey) {
    if (!momentHelper.hasOwnProperty(sKey)) {
        momentHelper[sKey] = momentHelperExtend[sKey];
    }
});
Object.keys(_objTimeZoneDateExtend).forEach(function(sKey) {
    if (!_objTimeZoneDate.hasOwnProperty(sKey)) {
        _objTimeZoneDate[sKey] = _objTimeZoneDateExtend[sKey];
    }
});

//This is in here so that the parent does not override this when trying to extend on the functionality above
TimeZoneDate = Ember.Object.extend(_objTimeZoneDate);
