define("a24-ember-policy-library/components/candidate-policy", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/candidate-policy"], function (exports, _didRenderChangesMixin, _candidatePolicy) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _candidatePolicy.default,
        classNames: ["candidate-policy"],
        sCandidateId: null,
        objPolicyGroupService: null,
        _bLoading: false,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objBreadcrumb: Ember.inject.service("bread-crumb"),
        _objSnackbarService: Ember.inject.service("snackbar"),
        _objStringsService: Ember.inject.service("a24-strings"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objPolicyRestService: Ember.inject.service("rest-agency-group-policy"),
        _objAgencyGroupCandidatePolicyRestService: Ember.inject.service("rest-agency-group-candidate-policy-type"),
        _objConstantsEmberLibService: Ember.inject.service("a24-constants-ember-lib"),
        _objStaffshiftCoreConstants: Ember.inject.service("a24-constants-staffshift-core"),
        _objRestCandidatePolicyGroupServiceProperty: null,
        _arrItems: null,
        _arrFilterConfig: null,
        _sHeaderPostfix: "",
        _iResultCount: 0,
        _iPage: 1,
        _iPerPage: 25,
        _objQueryParams: null,
        init: function () {
            this._super(...arguments);

            // Call the breadcrumb item change
            this.onBreadCrumbItemChange();
            this.observeObjPolicyGroupService();
        },
        _bIsAgencyGroup: Ember.computed("objPolicyGroupService.sContext", function () {
            return this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP");
        }),
        onBreadCrumbItemChange: Ember.observer("_objBreadcrumb.arrBreadCrumbItems", function () {
            var arrBreadCrumbItems = this.get("_objBreadcrumb.arrBreadCrumbItems");
            if (!a24Core.isEmpty(arrBreadCrumbItems) && !this.get("_bIsAgencyGroup")) {
                this.set("_sHeaderPostfix", " - " + arrBreadCrumbItems[arrBreadCrumbItems.length - 1].sTitle);
            }
        }),
        observeObjPolicyGroupService: Ember.observer("objPolicyGroupService", function () {
            //Only do the service call if the current instance is not busy destroying
            if (!a24Core.isEmpty(this)) {
                this.setupPage();
            }
        }),
        setupPage: function () {

            this.set("_arrFilterConfig", [{
                sTitle: this.get("_objPolicyStringsService").getString("policies"),
                sProperty: "action",
                sFilterType: "dropdown",
                arrMappingData: [{
                    sValue: "acknowledged",
                    sTitle: this.get("_objPolicyStringsService").getString("accepted")
                }, {
                    sValue: "not_acknowledged",
                    sTitle: this.get("_objPolicyStringsService").getString("notAccepted")
                }, {
                    sValue: "read_only",
                    sTitle: this.get("_objPolicyStringsService").getString("readOnly")
                }],
                bHideEmptyCheck: true,
                bMapping: true
            }]);

            this.doServiceCall();
        },
        doServiceCall: function (objQueryParams) {
            if (a24Core.isEmpty(objQueryParams)) {
                objQueryParams = {
                    page: { mValue: this.get("_iPage") },
                    items_per_page: { mValue: this.get("_iPerPage") }
                };
            }

            if (a24Core.isEmpty(objQueryParams.policy_type_title)) {
                objQueryParams.policy_type_title = {
                    mValue: "asc",
                    bSortBy: true
                };
            }

            // Save the current query params for later use.
            this.set("_objQueryParams", Ember.copy(objQueryParams, true));

            // Set loading true
            this.set("_bLoading", true);

            if (this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                this.get("objPolicyGroupService").getPolicyGroupDetails(this, "_objRestCandidatePolicyGroupServiceProperty", this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type, this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_id, (arrData, sStatus, objXHR) => {
                    // Set up _arrItems
                    this.set("_arrItems", this.parseResponseData(arrData));

                    // Set the result count
                    this.set("_iResultCount", a24RestCallHelper.getXResultCount(objXHR));

                    // Set loading false
                    this.set("_bLoading", false);
                }, null, objQueryParams);
            } else {
                this.get("objPolicyGroupService").getPolicyGroupDetails(this, "_objRestCandidatePolicyGroupServiceProperty", this.get("sCandidateId"), (arrData, sStatus, objXHR) => {
                    // Set up _arrItems
                    this.set("_arrItems", this.parseResponseData(arrData));

                    // Set the result count
                    this.set("_iResultCount", a24RestCallHelper.getXResultCount(objXHR));

                    // Set loading false
                    this.set("_bLoading", false);
                }, null, objQueryParams);
            }
        },
        parseResponseData: function (arrData) {
            var arrFinalData = [];
            if (!a24Core.isEmpty(arrData)) {
                for (var i = 0; i < arrData.length; i++) {
                    var objItem = arrData[i];
                    var objFormattedItem = {};

                    if (!a24Core.isEmpty(objItem.latest_policy)) {
                        // Latest Policy is present
                        objFormattedItem.sTitle = objItem.policy_type_title;
                        objFormattedItem.sDescription = objItem.policy_type_description;
                        objFormattedItem.sIcon = "priority_high";
                        objFormattedItem.sColor = "warning-color";
                        objFormattedItem.sPolicyTypeId = objItem.policy_type_id;
                        objFormattedItem.sVersion = objItem.latest_policy.version;
                        if (!a24Core.isEmpty(objItem.acknowledged_policy)) {
                            objFormattedItem.bHasSignedOutdated = true;
                            objFormattedItem.sVersion = objItem.acknowledged_policy.version;
                        }
                    } else {
                        // Latest Policy is not present
                        objFormattedItem.sTitle = objItem.policy_type_title;
                        objFormattedItem.sDescription = objItem.policy_type_description;
                        objFormattedItem.sIcon = "check";
                        objFormattedItem.sColor = "success-color";
                        objFormattedItem.bAccepted = true;
                        objFormattedItem.sPolicyTypeId = objItem.policy_type_id;
                        objFormattedItem.sVersion = objItem.acknowledged_policy.version;
                    }

                    objFormattedItem.funcHeaderClickCallback = this.onHistoricItemClicked(objItem);

                    if (objItem.policy_type_classification === "viewable") {
                        objFormattedItem.sIcon = "check";
                        objFormattedItem.sColor = "grey";
                        objFormattedItem.bAccepted = false;
                        objFormattedItem.bHasSignedOutdated = false;
                        objFormattedItem.bReadOnly = true;
                    }

                    arrFinalData.push(objFormattedItem);
                }
            }

            return arrFinalData;
        },
        onHistoricItemClicked: function (objItem) {
            var objThis = this;
            return function () {
                var sVersion = "";
                if (!a24Core.isEmpty(objItem.latest_policy)) {
                    if (objThis.get("objPolicyGroupService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                        sVersion = objItem.latest_policy.version;
                    } else {
                        sVersion = "latest";
                    }
                } else {
                    sVersion = objItem.acknowledged_policy.version;
                }

                if (objThis.get("objPolicyGroupService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                    var objParams = {
                        "policy-view": [objThis.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type, objThis.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_id, objItem.policy_type_id, sVersion]
                    };
                    objThis.get("_objNavigationService").navigateWithinContext("policy-view", objParams);
                } else if (objThis.get("objPolicyGroupService.sContext") === objThis.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                    objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdf"));

                    if (!a24Core.isEmpty(objItem.acknowledged_policy)) {
                        // This is to download the signed policy. We dont care about the latest policy in this case
                        objThis.get("_objAgencyGroupCandidatePolicyRestService").downloadPdfByGET(objItem.policy_type_id, objThis.get("sCandidateId"), objItem.acknowledged_policy.version, () => {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFinish"));
                        }, () => {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFailed"));
                        });
                    } else {
                        // This is to download just the latest version
                        objThis.get("_objPolicyRestService").downloadPdfByGET(objItem.policy_type_id, sVersion, () => {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFinish"));
                        }, () => {
                            objThis.get("_objSnackbarService").showSnackbarMessage(objThis.get("_objStringsService").getString("downloadPdfFailed"));
                        });
                    }
                }
            };
        },
        buildFilterParams: function (objFilterParams) {
            if (a24Core.isEmpty(objFilterParams.action)) {
                return objFilterParams;
            }

            if (objFilterParams.action.mValue === "read_only") {
                delete objFilterParams.action;
                objFilterParams.policy_type_classification = {
                    mValue: "viewable"
                };
            } else {
                objFilterParams.policy_type_classification = {
                    mValue: "acknowledgeable"
                };
            }

            return objFilterParams;
        },
        actions: {
            cardDatagridPagerEvent: function (objPageQuery) {
                objPageQuery = Ember.copy(objPageQuery, true);

                var objQueryParams = this.get("_objQueryParams");
                objQueryParams.page = objPageQuery.page;
                objQueryParams.items_per_page = objPageQuery.per_page;

                this.doServiceCall(objQueryParams);
            },
            cardDatagridFilterChangeEvent: function (sProperty, objValue) {
                objValue = Ember.copy(objValue, true);
                var objParams = this.buildFilterParams(objValue);
                this.set("_iPage", 1);
                objParams.page = { mValue: 1 };
                objParams.items_per_page = { mValue: this.get("_iPerPage") };
                this.doServiceCall(objParams);
            },
            onOutdatedClick: function (objClickedItem) {
                if (this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_CANDIDATE")) {
                    var objParams = {
                        "policy-view": [this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type, this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_id, objClickedItem.sPolicyTypeId, objClickedItem.sVersion]
                    };
                    this.get("_objNavigationService").navigateWithinContext("policy-view", objParams);
                } else if (this.get("objPolicyGroupService.sContext") === this.get("_objStaffshiftCoreConstants.CONTEXT_AGENCY_GROUP")) {
                    this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdf"));

                    // This is to download the signed policy. We dont care about the latest policy in this case
                    this.get("_objAgencyGroupCandidatePolicyRestService").downloadPdfByGET(objClickedItem.sPolicyTypeId, this.get("sCandidateId"), objClickedItem.sVersion, () => {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFinish"));
                    }, () => {
                        this.get("_objSnackbarService").showSnackbarMessage(this.get("_objStringsService").getString("downloadPdfFailed"));
                    });
                }
            }
        }
    });
});