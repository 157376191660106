define("a24-ember-staffshift-core/services/title-bar-service", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sPageTitle: null, // The page title that needs to display
        sUserNameInitial: null, // The user name initial
        sUserAvatar: null, // The user avatar
        _objUserSession: Ember.inject.service("user-session"),
        _objConstantsSSCore: Ember.inject.service("a24-constants-staffshift-core"),
        init: function () {
            this._super(...arguments);
            // Call methods on init
            this._setUserInitial();
            this._setUserAvatar();
        },
        _setUserInitial: Ember.observer("_objUserSession.objContextDetails", function () {
            if (a24Core.isEmpty(this.get("_objUserSession.objContextDetails"))) {
                return;
            }
            var sName = this.get("_objUserSession").getUserFirstName();
            // If the context has a name we set it using that instead.
            if (!a24Core.isEmpty(this.get("_objUserSession.objContextDetails.sName"))) {
                sName = this.get("_objUserSession.objContextDetails.sName");
            }

            // If sName is set, set the user initial
            if (!a24Core.isEmpty(sName)) {
                this.set("sUserNameInitial", sName.charAt(0).toUpperCase());
            }
        }),
        _setUserAvatar: Ember.observer("_objUserSession.objContextDetails", function () {
            if (a24Core.isEmpty(this.get("_objUserSession.objContextDetails"))) {
                return;
            }

            // If the avatar is set, set the user avatar
            var sAvatarUrl = this.get("_objUserSession").getUserAvatar();

            if (sAvatarUrl === this.get("_objConstantsSSCore").AGENCY_DEFAULT_AVATAR || sAvatarUrl === this.get("_objConstantsSSCore").AGENCY_GROUP_DEFAULT_AVATAR || sAvatarUrl === this.get("_objConstantsSSCore").CANDIDATE_DEFAULT_AVATAR || sAvatarUrl === this.get("_objConstantsSSCore").ORGANISATION_DEFAULT_AVATAR || sAvatarUrl === this.get("_objConstantsSSCore").SYSTEM_ADMIN_DEFAULT_AVATAR) {
                //Reset the icon icon if it is a default one
                sAvatarUrl = null;
            }

            this.set("sUserAvatar", sAvatarUrl);
        }),
        /**
         * This will set the page title
         *
         * @param sPageTitle - The title of the page
         *
         * @author Michael Barnard <micheal.barnard@a24group.com>
         * @since  23 May 2016
         */
        setPageTitle: function (sPageTitle) {
            this.set("sPageTitle", sPageTitle);
        }
    });
});