define("a24-ember-lib/components/input-text", ["exports", "a24-ember-lib/templates/components/input-text", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _inputText, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _inputText.default,
        classNames: ["md-form"],
        sFieldName: "",
        sValue: "",
        _sInputId: null,
        init: function () {
            this._super(...arguments);
            this.set("_sInputId", Ember.guidFor({}));

            var objThis = this;

            Ember.run.scheduleOnce("afterRender", this, function () {
                var objTextInput = $("#" + objThis.get("_sInputId"));

                if (!a24Core.isEmpty(objThis.get("sValue"))) {
                    objTextInput.change();
                }

                objTextInput.on("blur", function () {
                    objThis.resetFloatingLabel(objTextInput);
                });
            });
        },
        resetFloatingLabel: function (objTextInput) {
            //If the input field has no value then the label should NOT have the active class and
            //float at placeholder position
            if (a24Core.isEmpty(objTextInput.val())) {
                this.$("label").removeClass("active");
            }
        }
    });
});