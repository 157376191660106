define("a24-ember-lib/components/collapse-section-component", ["exports", "a24-ember-lib/templates/components/collapse-section-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _collapseSectionComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _collapseSectionComponent.default,
        // id for the component
        sCollapseSectionId: "",
        sCollapseGroupParent: "",
        // whether the component is initially expanded or collapsed
        bExpanded: false,
        didInsertElement: function () {
            this._super(...arguments);
            Ember.run.next(() => {
                this.$().attr("id", this.get("sCollapseSectionId"));
                this.$().addClass("collapse");
                var objCollapseItem = $("#" + this.get("sCollapseSectionId"));
                if (a24Core.isEmpty(this.get("sCollapseGroupParent"))) {
                    objCollapseItem.collapse({
                        toggle: this.get("bExpanded")
                    });
                } else {
                    objCollapseItem.collapse({
                        toggle: this.get("bExpanded"),
                        parent: "#" + this.get("sCollapseGroupParent")
                    });
                }
                objCollapseItem.on("show.bs.collapse", () => {
                    Ember.run(() => {
                        if (!a24Core.isEmpty(this.get("onCollapseExpandStart"))) {
                            this.get("onCollapseExpandStart")(this.get("sCollapseSectionId"));
                        }
                    });
                }).on("shown.bs.collapse", () => {
                    Ember.run(() => {
                        if (!a24Core.isEmpty(this.get("onCollapseExpandEnd"))) {
                            this.get("onCollapseExpandEnd")(this.get("sCollapseSectionId"));
                        }
                    });
                }).on("hide.bs.collapse", () => {
                    Ember.run(() => {
                        if (!a24Core.isEmpty(this.get("onCollapseCollapseStart"))) {
                            this.get("onCollapseCollapseStart")(this.get("sCollapseSectionId"));
                        }
                    });
                }).on("hidden.bs.collapse", () => {
                    Ember.run(() => {
                        this.$().height(0);
                        if (!a24Core.isEmpty(this.get("onCollapseCollapseEnd"))) {
                            this.get("onCollapseCollapseEnd")(this.get("sCollapseSectionId"));
                        }
                    });
                });
            });
        },
        _observeExpandState: Ember.observer("bExpanded", function () {
            this._setExpandedState();
        }),
        _setExpandedState: function () {
            Ember.run.schedule("afterRender", () => {
                if (this.get("bExpanded")) {
                    $("#" + this.get("sCollapseSectionId")).collapse("show");
                } else {
                    $("#" + this.get("sCollapseSectionId")).collapse("hide");
                }
            });
        }
    });
});