define("a24-ember-title-bar/services/title-bar", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        sTitle: null,
        onBack: null,
        bBackDisabled: false,
        sRightComponent: null,
        objRightComponentData: null,
        onRightComponentAction: null,

        setTitleBar(sTitle, onBack, bBackDisabled, sRightComponent, objRightComponentData, onRightComponentAction) {
            // This is needed because the title-bar now acts like a singleton component
            Ember.run.schedule("afterRender", () => {
                if (!a24Core.isEmpty(sTitle)) {
                    this.set("sTitle", sTitle);
                } else {
                    this.set("sTitle", null);
                }
                if (!a24Core.isEmpty(onBack)) {
                    this.set("onBack", onBack);
                } else {
                    this.set("onBack", null);
                }
                if (!a24Core.isEmpty(bBackDisabled)) {
                    this.set("bBackDisabled", bBackDisabled);
                } else {
                    this.set("bBackDisabled", false);
                }
                if (!a24Core.isEmpty(sRightComponent)) {
                    this.set("sRightComponent", sRightComponent);
                } else {
                    this.set("sRightComponent", null);
                }
                if (!a24Core.isEmpty(objRightComponentData)) {
                    this.set("objRightComponentData", objRightComponentData);
                } else {
                    this.set("objRightComponentData", null);
                }
                if (!a24Core.isEmpty(onRightComponentAction)) {
                    this.set("onRightComponentAction", onRightComponentAction);
                } else {
                    this.set("onRightComponentAction", null);
                }
            });
        }
    });
});