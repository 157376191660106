define("a24-ember-policy-library/components/page-policy-type-view", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/page-policy-type-view"], function (exports, _didRenderChangesMixin, _pagePolicyTypeView) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pagePolicyTypeView.default,
        sPolicyTypeRestService: "rest-agency-group-policy-type",
        sPolicyRestService: "rest-agency-group-policy",

        _objBreadCrumbService: Ember.inject.service("bread-crumb"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objPolicyStringsService: Ember.inject.service("a24-strings-policy-library"),
        _objUserSession: Ember.inject.service("user-session"),

        _objPolicyRestService: Ember.computed("sPolicyRestService", function () {
            return Ember.getOwner(this).lookup("service:" + this.get("sPolicyRestService"));
        }),
        _objRestAgencyGroupPolicyProperty: null,

        _arrPolicyVersionData: null,
        _bPolicyVersionsLoading: false,

        init: function () {
            this._super(...arguments);

            this.doPolicyTypeVersionsListServiceCall();
        },
        doPolicyTypeVersionsListServiceCall: function () {
            this.set("_bPolicyVersionsLoading", true);

            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeViewId();
            //sPolicyTypeId read from route params that will be null during transition
            if (a24Core.isEmpty(sPolicyTypeId)) {
                return;
            }

            this.get("_objPolicyRestService").getPoliciesForPolicyType(this, "_objRestAgencyGroupPolicyProperty", this.get("_objUserSession").getUserContextReference().mId, sPolicyTypeId, arrResponse => {
                if (a24Core.isEmpty(arrResponse)) {
                    arrResponse = [];
                }
                this.set("_arrPolicyVersionData", arrResponse);
                this.set("_bPolicyVersionsLoading", false);
            });
        },
        actions: {
            breadcrumbTitleEvent: function (sTitle) {
                // 2 run next loops with an afterRender are used here because the menu code causes the breadcrumb to reset
                // when opening a popup, this code has to execute after that
                Ember.run.next(() => {
                    Ember.run.next(() => {
                        Ember.run.scheduleOnce("afterRender", () => {
                            var sPolicyTypeId = this.get("_objPolicyRestService").getPolicyTypeViewId();

                            //sPolicyTypeId read from route params that will be null during transition
                            if (a24Core.isEmpty(sPolicyTypeId)) {
                                return;
                            }

                            var arrCurrentBreadCrumb = [{
                                sLink: this.get("_objNavigationService").navigateWithinContext("policy-type-list", null, null, true),
                                sTitle: this.get("_objPolicyStringsService").getString("policies")
                            }, {
                                sLink: this.get("_objNavigationService").navigateWithinContext("policy-type-view", {
                                    "policy-type-view": [sPolicyTypeId]
                                }, null, true),
                                sTitle: sTitle
                            }];

                            this.get("_objBreadCrumbService").setCustomBreadItems(arrCurrentBreadCrumb);

                            // Set the default items so that the custom can be applied
                            this.get("_objBreadCrumbService").setBreadCrumbItems();
                        });
                    });
                });
            }
        }
    });
});