define("a24-ember-policy-library/services/rest-user-policy", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        _sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service method will be used to retrieve all policy
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sPolicyTypeId - The id of the policy type
         * @param sVersion - The version of the policy
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  28 May 2018
         */
        getPolicyDetails: function (objCaller, sProperty, sPolicyTypeId, sVersion, funcSuccess, objFailure) {
            let sUserId = this.get("_objUserSession.objAuthData.context.sUserId");

            var sUrl = Ember.get(this, "_sRestApiBaseUrl") + "/v1/user/" + sUserId + "/policy-type/" + sPolicyTypeId + "/version/" + sVersion;

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", sUrl, funcSuccess, objFailure));
        },

        /**
         * This service method will be used to retrieve all policy
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sPolicyTypeId - The id of the policy type
         * @param sVersion - The version of the policy
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objBody - The policy payload
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  28 May 2018
         */
        postPolicyDetailsAction: function (objCaller, sProperty, sPolicyTypeId, sVersion, funcSuccess, objFailure, objBody) {
            let sUserId = this.get("_objUserSession.objAuthData.context.sUserId");

            var sUrl = Ember.get(this, "_sRestApiBaseUrl") + "/v1/user/" + sUserId + "/policy-type/" + sPolicyTypeId + "/version/" + sVersion + "/action";

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", sUrl, funcSuccess, objFailure, objBody));
        },

        /**
         * This service method will return the post request body
         *
         * @param sActionType - The action type
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  28 May 2018
         */
        getConfirmationPostBody: function (sActionType) {
            var objUserDetails = this.get("_objUserSession.objAuthData.identity");
            return {
                "action_type": sActionType,
                "tokens": {
                    "user_full_name": objUserDetails.sFirstName + " " + objUserDetails.sLastName,
                    "user_email": objUserDetails.sEmailAddress
                }
            };
        },

        /**
         * This function download pdf by get for a policy version
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  28 May 2018
         */
        downloadPdfByGET: function (sPolicyTypeId, sVersion, sPDFFileName, funcSuccess, funcFailure) {
            var sUrl = this.get("_sRestApiBaseUrl") + "/v1" + "/user/" + this.get("_objUserSession.objAuthData.context.sUserId") + "/policy-type/" + sPolicyTypeId + "/version/" + sVersion;

            a24RestCallHelper.downloadPdfByGET(sUrl, sPDFFileName, funcSuccess, funcFailure, {
                Authorization: "Bearer " + this.get("_objUserSession.objAuthData.accessToken"),
                Context: "Candidate"
            });
        }
    });
});