define("a24-ember-policy-library/components/page-candidate-policy-group-list", ["exports", "a24-ember-window/mixins/did-render-changes-mixin", "a24-ember-policy-library/templates/components/page-candidate-policy-group-list"], function (exports, _didRenderChangesMixin, _pageCandidatePolicyGroupList) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _pageCandidatePolicyGroupList.default,
        classNames: ["page-candidate-policy-group-list"],
        _sCandidateId: null,
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigationService: Ember.inject.service("navigation"),
        _objUserPolicyGroup: Ember.inject.service("rest-user-policy-group"),
        _objCandidatePolicyGroup: Ember.inject.service("rest-candidate-policy-group"),
        _objRestCandidatePolicyGroupService: null,

        init: function () {
            this._super(...arguments);

            this.observeRouteParams();
        },
        //We have to observe the route param. Since all policy groups are on the same route ember wont reload the component
        //since ember only reloads the needed bits that change during route changes
        observeRouteParams: Ember.observer("_objNavigationService.objRouteParams", function () {
            //Only do the service call if the current instance is not busy destroying and if we have
            //a candidate policy group route
            if (!a24Core.isEmpty(this) && !a24Core.isEmpty(this.get("_objNavigationService.objRouteParams")["candidate.policy-group-list"])) {
                if (this.get("_objNavigationService").get("objRouteParams")["candidate.policy-group-list"].policy_group_type === "staffshift") {
                    this.set("_sCandidateId", this.get("_objUserSession").objAuthData.context.sUserId);
                    this.set("_objRestCandidatePolicyGroupService", this.get("_objUserPolicyGroup"));
                } else {
                    this.set("_sCandidateId", this.get("_objUserSession").getUserContextReference().mId);
                    this.set("_objRestCandidatePolicyGroupService", this.get("_objCandidatePolicyGroup"));
                }
            }
        })
    });
});