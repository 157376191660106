define("a24-ember-form-inputs/services/a24-strings-form-inputs", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,
        init() {
            this._super(...arguments);
            Ember.set(this, "_objStrings", {
                suggestionEmpty: "No Suggestions",
                fileInputPlaceHolder: "Please select a file...",
                telNumberInputCountryError: "The number you have entered is not valid for the selected country"
            });
        }
    });
});