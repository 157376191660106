define("a24-ember-policy-library/services/rest-agency-group-policy-type", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Service.extend({
        _objUserSession: Ember.inject.service("user-session"),
        _objNavigation: Ember.inject.service("navigation"),

        sRestApiBaseUrl: Ember.computed.readOnly("env.sRestApiBaseUrl"),

        env: Ember.computed(function () {
            return Ember.getOwner(this).resolveRegistration("config:environment");
        }),

        /**
         * This service return get current policy id
         *
         * @author Ahmed Onawale<ahmedonawale@gmail.com>
         * @since  15 August 2018
         */
        getPolicyTypeViewId: function () {
            if (!a24Core.isEmpty(this.get("_objNavigation.objRouteParams")["agency-group.policy-type-view"])) {
                return this.get("_objNavigation.objRouteParams")["agency-group.policy-type-view"].policy_type_id;
            }
        },

        /**
         * This service method will be used to retrieve all policy types
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objQueryParams - The query param object
         *
         * @author Ruan Naude<ruan.naude@a24group.com>
         * @since  06 July 2017
         */
        getPolicyTypes: function (objCaller, sProperty, sAgencyGroupId, funcSuccess, objFailure, objQueryParams) {
            var sUrl = Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/policy-type";
            a24RestUrlConstruct.setBaseUrlSwagger(sUrl);
            a24RestUrlConstruct.addQueryParamObject(objQueryParams);

            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", a24RestUrlConstruct.getConstructedUrl(), funcSuccess, objFailure));
        },
        /**
         * This service method will be used to retrieve one policy type
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group
         * @param sPolicyTypeId - The id of the policy type
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  11 July 2017
         */
        getPolicyType: function (objCaller, sProperty, sAgencyGroupId, sPolicyTypeId, funcSuccess, objFailure) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "GET", Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/policy-type/" + sPolicyTypeId, funcSuccess, objFailure));
        },
        /**
         * This service method will post to the policy type route
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Ruan Naude<ruan.naude@a24group.com>
         * @since  07 July 2017
         */
        createPolicyType: function (objCaller, sProperty, sAgencyGroupId, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/policy-type", funcSuccess, objFailure, objPayload));
        },
        /**
         * This service method will post to the policy type route for a specific policy type
         *
         * @param objCaller - The instance of the parent calling this function
         * @param sProperty - The Property name to get the promise object
         * @param sAgencyGroupId - The id of the agency group
         * @param sPolicyTypeId - The id of the policy type
         * @param funcSuccess - The callback function to execute when the service call is successful
         * @param objFailure - The failure object
         * @param objPayload - The new policy type details
         *
         * @author Junior van Wyk <johannes.vanwyk@a24group.com>
         * @since  11 July 2017
         */
        updatePolicyType: function (objCaller, sProperty, sAgencyGroupId, sPolicyTypeId, funcSuccess, objFailure, objPayload) {
            return a24RestCallHelper.doRestServiceCall(objCaller, sProperty, a24RestCallHelper.makeJsonAjaxCall(objCaller, this.get("_objUserSession"), this.get("_objNavigation"), "POST", Ember.get(this, "sRestApiBaseUrl") + "/v1/agency-group/" + sAgencyGroupId + "/policy-type/" + sPolicyTypeId, funcSuccess, objFailure, objPayload));
        }
    });
});