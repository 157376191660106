define("a24-ember-lib/components/collapse-trigger-component", ["exports", "a24-ember-lib/templates/components/collapse-trigger-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _collapseTriggerComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _collapseTriggerComponent.default,
        // the id of the component that will collapse
        sCollapseSectionId: "",
        bDisplayArrow: true,
        bDisplayWaves: true,
        bShouldShowCollapsed: false,
        calcShouldShowCollapsed: function () {
            Ember.run(() => {
                if (!a24Core.isEmpty(this) && !a24Core.isEmpty(this.get("sCollapseSectionId"))) {
                    this.set("bShouldShowCollapsed", !$(`#${this.get("sCollapseSectionId")}`).eq(0).hasClass("show"));
                }
            });
        },
        didInsertElement: function () {
            this._super(...arguments);

            Ember.run.next(() => {
                Ember.run.scheduleOnce("afterRender", () => {
                    if (!a24Core.isEmpty(this.get("sCollapseSectionId"))) {
                        $(`#${this.get("sCollapseSectionId")}`).eq(0).on("shown.bs.collapse", () => {
                            this.calcShouldShowCollapsed();
                        }).on("hidden.bs.collapse", () => {
                            this.calcShouldShowCollapsed();
                        });
                        this.calcShouldShowCollapsed();
                    }
                });
            });
        },
        mouseEnter: function () {
            if (!a24Core.isEmpty(this.get("onAccordionMouseEnter"))) {
                this.get("onAccordionMouseEnter")(this.get("sCollapseSectionId"));
            }
        },
        mouseLeave: function () {
            if (!a24Core.isEmpty(this.get("onAccordionMouseLeave"))) {
                this.get("onAccordionMouseLeave")(this.get("sCollapseSectionId"));
            }
        },
        actions: {
            onTriggerClicked: function (objEvent) {
                var objTarget = $(objEvent.target);
                // If the item IS NOT <a> or class waves-effect perform the click
                // If the item IS <a> or class waves-effect ensure that only this accordian <a> allows expanding
                if (!(objTarget.hasClass("waves-effect") || objTarget.is("a")) || objTarget[0] === this.$("a")[0]) {
                    if (!a24Core.isEmpty(this.get("sCollapseSectionId"))) {
                        if (!a24Core.isEmpty($(`#${this.get("sCollapseSectionId")}`))) {
                            $(`#${this.get("sCollapseSectionId")}`).collapse("toggle");
                        }
                    }
                    if (!a24Core.isEmpty(this.get("onAccordionMouseClick"))) {
                        this.get("onAccordionMouseClick")(this.get("sCollapseSectionId"));
                    }
                }
            }
        }
    });
});