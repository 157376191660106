define("a24-ember-policy-library/services/a24-strings-policy-library", ["exports", "a24-ember-lookup/services/a24-strings-base"], function (exports, _a24StringsBase) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = _a24StringsBase.default.extend({
        _objStrings: null,
        init: function () {
            this._super(...arguments);

            this.set("_objStrings", {

                //===== STRINGS START =====

                policyContent: "Policy content",
                policyAcknowledgement: "Policy acknowledgement",

                createPolicyVersion: "Create policy version",
                creatingPolicyVersion: "Creating policy version...",
                creatingPolicyVersionSuccess: "Successfully created policy version",
                creatingPolicyVersionFailed: "Failed to create policy version",
                createPolicy: "Create policy",
                creatingPolicy: "Creating policy...",
                creatingPolicySuccess: "Successfully created policy",
                creatingPolicyFailed: "Failed to create policy",
                policies: "Policies",
                policyVersions: "Policy versions",
                policiesDescription: "Please view the below policies.<br/>" + "For some of the policies we require you to explicitly acknowledge that it has been read. Please note " + "that all policies will be kept up to date and will require you to read and acknowledge again if " + "appropriate",
                accepted: "Accepted",
                reAccept: "Re-accept",
                view: "View",
                awaitingAcceptance: "Awaiting Acceptance",
                noPoliciesAvailable: "No Policies Available",
                outdatedSignedVersion: "Outdated signed version",
                notAccepted: "Not Accepted",

                updatePolicy: "Update policy",
                updatingPolicyDetails: "Updating policy...",
                updatingPolicySuccess: "Successfully updated policy",
                updatingPolicyFailed: "Failed to update policy",
                policyDetails: "Policy Details",
                updatingPolicyTypeAlreadyInUse: "The data has changed, the classification for the policy you are updating cannot be changed because it already has policy versions linked to it. Please refresh the screen to see the changes.",
                createPolicyVersionClassificationDiffers: "The classification for the policy version differs from the policy type. The policy type may have been updated, please refresh and try agaion.",

                outdatedMessage: "Please note that this is an outdated version and that there is an updated one available for you to acknowledge!",
                updatingPolicy: "Updating policy status...",
                acceptedPolicy: "Successfully accepted policy",
                failurePolicy: "Failed to update policy status",
                classification: "Classification",
                acknowledgeable: "Acknowledgeable",
                viewable: "Viewable",
                readOnly: "Read Only",
                registration: "Registration",
                public: "Public",
                //===== STRINGS END   =====

                //===== TOKEN STRINGS START =====

                validationEffectiveAtBeforePrev: "{0} is not allowed to be before the previous versions Effective at date"

                //===== TOKEN STRINGS END   =====

            });
        }
    });
});