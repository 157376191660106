define("a24-ember-lib/components/round-icon-component", ["exports", "a24-ember-lib/templates/components/round-icon-component", "a24-ember-window/mixins/did-render-changes-mixin"], function (exports, _roundIconComponent, _didRenderChangesMixin) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend(_didRenderChangesMixin.default, {
        layout: _roundIconComponent.default,
        // Default class names
        classNames: ["round-icon-component"],
        // Class names bound to the component
        classNameBindings: ["sBackgroundClass", "sRoundButtonSize"],
        // Background color class, See classNameBindings
        sBackgroundClass: "",
        // Icon color class
        sIconClass: "a24-grey-text-darken-1",
        // Round button size class, See classNameBindings
        sRoundButtonSize: null,
        // Icon size
        sIconSizeClass: null,
        // If the component is clickable
        bClickable: true,
        // If the component is disabled (this will override the clickable state if true)
        bDisabled: false,
        // Pass on the waves to be light
        bLightWaves: false,
        // Properties to display
        sLetter: null,
        sIcon: null,
        sImageUrl: null,
        // the tooltip text
        sToolTipText: null,
        // the tooltip position
        sToolTipPosition: null,
        _objLibConstants: Ember.inject.service("a24-constants-ember-lib"),
        init: function () {
            this._super(...arguments);

            if (a24Core.isEmpty(this.get("sRoundButtonSize"))) {
                this.set("sRoundButtonSize", this.get("_objLibConstants.ROUND_ICON_SIZE_MEDIUM"));
            }

            if (a24Core.isEmpty(this.get("sIconSizeClass"))) {
                this.set("sIconSizeClass", this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_MEDIUM"));
            }

            Ember.run.schedule("afterRender", () => {
                switch (this.get("sRoundButtonSize")) {
                    case this.get("_objLibConstants.ROUND_ICON_SIZE_SMALL"):
                    case this.get("_objLibConstants.ROUND_ICON_SIZE_MEDIUM"):
                    case this.get("_objLibConstants.ROUND_ICON_SIZE_LARGE"):
                    case this.get("_objLibConstants.ROUND_ICON_SIZE_EXTRA_LARGE"):
                        break;
                    default:
                        this.set("sRoundButtonSize", this.get("_objLibConstants.ROUND_ICON_SIZE_MEDIUM"));
                }

                switch (this.get("sIconSizeClass")) {
                    case this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_SMALL"):
                    case this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_MEDIUM"):
                    case this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_LARGE"):
                        break;
                    default:
                        this.set("sIconSizeClass", this.get("_objLibConstants.ROUND_ICON_CONTENT_SIZE_MEDIUM"));
                }
                this.addTooltip();
            });
        },
        willDestroyElement: function () {
            this._super(...arguments);

            this.disposeOfTooltip();
        },
        // Computed, to see if the button is clickable
        _bShowClickableState: Ember.computed("bClickable", "bDisabled", function () {
            return this.get("bClickable") && !this.get("bDisabled");
        }),
        // This will act as the primary click action
        click: function (objEvent) {
            if (this.get("_bShowClickableState")) {
                if (!a24Core.isEmpty(this.get("onRoundIconClick"))) {
                    this.get("onRoundIconClick")(objEvent);
                }
            }
        },
        addTooltip: function () {
            // add a tooltip if tooltip text is set
            var sToolTipText = this.get("sToolTipText");
            if (!a24Core.isEmpty(sToolTipText)) {
                this.$().attr("data-toggle", "tooltip");
                this.$().attr("title", sToolTipText);
                var sToolTipPosition = this.get("sToolTipPosition");
                if (a24Core.isEmpty(sToolTipPosition)) {
                    sToolTipPosition = "bottom";
                }
                $(this.$()).tooltip({
                    placement: sToolTipPosition,
                    container: "body", // Check Overview point 4 on https://mdbootstrap.com/javascript/tooltips/
                    trigger: "hover",
                    delay: {
                        "show": 350, "hide": 0
                    }
                });
            }
        },
        observeTooltip: Ember.observer("sToolTipText", function () {
            this.disposeOfTooltip();
            this.addTooltip();
        }),
        disposeOfTooltip: function () {
            //Destroy the existing tooltip
            try {
                $(this.$()).tooltip("dispose");
            } catch (objErr) {
                //This is temp fix till new bootstrap version fixes the tooltip error when calling dispose
                //while transitioning
            }
        }
    });
});